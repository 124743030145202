export const crossIcon = require("../assets/crossicon.png");
export const checkIcon = require("../assets/image_check.png");
export const checkListIcon = require("../assets/checkList_image.png");
export const schedulingIcon = require("../assets/schedulingIcon.png");
export const bookStatusIcon = require("../assets/bookStatusicon.png");
export const airPortIcon = require("../assets/airport.png");
export const dateIcon = require("../assets/date.png");
export const mailIcon = require("../assets/mail.png");
export const testsIcon = require("../assets/tests.png");
export const timeFrameIcon = require("../assets/timeFrame.png");
export const timeZoneIcon = require("../assets/timeZoneIcon.png");
export const userTypeIcon = require("../assets/userType.png");
export const checkRideIcon = require("../assets/checkRideIcon.png");
export const uploadIcon = require("../assets/uploadIcon.png");
export const successIcon = require("../assets/successIcon.png");
export const cancleIcon = require("../assets/cancleIcon.png");