import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { FormikTouched, FormikErrors } from "formik";
import React from "react";
interface Meta {
  token: string;
  refresh_token: string;
  id: number;
  role_name: string;
  is_activated: boolean; 
  isRegistered: boolean 
}

interface LoginResponse {
  meta: Meta
}

// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string;
  email: string;
  showPassword: boolean;
  loginErrors: boolean;
  // Customizable Area End
}

// Customizable Area Start
export interface Dropdown {
  value: string;
  label: string;
}
// Customizable Area End

interface SS {
  // Customizable Area Start
  id: number;
  // Customizable Area End
}

export default class LoginController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  loginApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      password: "",
      email: "",
      showPassword: false,
      loginErrors: false
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
      );

      if (apiRequestCallId && responseJson) {
        const isLoginApiCall = apiRequestCallId === this.loginApiCallId;
    
        if (isLoginApiCall) {
            localStorage.setItem("accessToken", responseJson.meta?.token);
            localStorage.setItem("role", responseJson.meta?.role_name);    
            if (responseJson.errors) {
                this.loginApiFailureCallBack(responseJson);
            } else {
                this.loginApiSucessCallBack(responseJson);
            }
        } 
    }
  }
    // Customizable Area End
  }

  // Customizable Area Start
  getErrorMessage = (
    touched: FormikTouched<object>,
    errors: FormikErrors<object>,
    value: string
  ) => {
    return (
      touched[value as keyof object] &&
      errors[value as keyof object] && (
        <div
          style={{
            fontSize: "12px",
            fontWeight: 300,
            color: "#f94b4b",
          }}
        >
          {errors[value as keyof object]}
        </div>
      )
    );
  };

  togglePasswordVisibility = (field: 'showPassword') => {

    this.setState((prevState) => ({
      ...prevState,
      [field]: !prevState[field]
    }));
  }

  onValueChange = (name: string, value: string) => {
    this.setState({ ...this.state, [name]: value, loginErrors: false });
  };

  handleLogin = () => {
    const headers = {
      "Content-Type": "application/json",
    };

    const httpBody = {
      data: {
        type: "email_account",
        attributes: {
          password: this.state.password,
          email: this.state.email,
        },
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.loginApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_login/logins"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  navigateScreens = (pathName: string) => {
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), pathName);
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msg);
  };
  
  loginApiSucessCallBack = (response: LoginResponse) => {
    if(response.meta){
      localStorage.setItem("accessToken", response.meta.token);
      localStorage.setItem("isAuthenticated", "true");
      if (response.meta.is_activated === false) {
        if(response.meta.role_name === "dpe"){
        this.navigateScreens('AccountStatus');
      }else{
        this.navigateScreens('ApplicantAccountStatus');
      }
    }else{
      this.navigateScreens('landingPage');
    } 
    }
  };

  loginApiFailureCallBack = (response: { errors: [{ failed_login: string; }] }) => {
    if (response.errors) {
      this.setState({ loginErrors: true });
        
    }
};

  // Customizable Area End
}
