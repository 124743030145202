import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import { Message } from "../../../framework/src/Message";
import { toast } from "react-toastify";
interface Option {
    id: string;
    name: string;
}
interface AircraftTypeItem {
    id: string;
    value: string;
}

  interface ErrorToken {
    token: string;
}

interface ErrorResponse {
    errors: ErrorToken[];
}

interface ExamData {
    exam_cost: string;
    accepted_payment_methods: string;
    instructions: string;
    id: number;
    designation: string;
    dpe_account_id: number;
    created_at: string; 
    updated_at: string; 
    mark_as_deleted: boolean;
  }

  export interface IDesignation {
    id: number;
    shortform: string;
    designation: any [];
    selected: boolean;
    key: string;
  }

  const airplaneData = [
    { id: 1, key: "airplanes", shortform: "DPE-PE-ASEL", designation: [{ id: 101, name: "Private - Airplane - Single Engine Land", selected: false }], selected: false },
    { id: 2, key: "airplanes", shortform: "DPE-CIRE-ASEL", designation: [{ id: 102, name: "Instrument - Airplane - Single Engine Land", selected: false }, { id: 103, name: "Commercial - Airplane Single Engine Land", selected: false }], selected: false },
    { id: 3, key: "airplanes", shortform: "DPE-ATPE-ASEL", designation: [{ id: 104, name: "ATP - Airplane - Single Engine Land", selected: false }], selected: false },
    { id: 4, key: "airplanes", shortform: "DPE-PE-AMEL", designation: [{ id: 105, name: "Private - Airplane - Multi-Engine Land", selected: false }], selected: false },
    { id: 5, key: "airplanes", shortform: "DPE-CIRE-AMEL", designation: [{ id: 106, name: "Instrument - Airplane - Multi-Engine Land", selected: false }, { id: 107, name: "Commercial - Airplane - Multi-Engine Land", selected: false }], selected: false },
    { id: 6, key: "airplanes", shortform: "DPE-ATPE-AMEL", designation: [{ id: 108, name: "ATP - Airplane - Multi-Engine Land", selected: false }], selected: false },
    { id: 7, key: "airplanes", shortform: "DPE-PE-ASES", designation: [{ id: 109, name: "Private - Airplane - Single Engine Sea", selected: false }], selected: false },
    { id: 8, key: "airplanes", shortform: "DPE-CIRE-ASES", designation: [{ id: 110, name: "Instrument - Airplane - Single Engine Sea", selected: false }, { id: 111, name: "Commercial Airplane Single Engine Sea", selected: false }], selected: false },
    { id: 9, key: "airplanes", shortform: "DPE-ATPE-ASES", designation: [{ id: 112, name: "ATP - Airplane - Single Engine Sea", selected: false }], selected: false },
    { id: 10, key: "airplanes", shortform: "DPE-PE-AMES", designation: [{ id: 113, name: "Private - Airplane - Multi-Engine Sea", selected: false }], selected: false },
    { id: 11, key: "airplanes", shortform: "DPE-CIRE-AMES", designation: [{ id: 114, name: "Instrument - Airplane - Multi-Engine Sea", selected: false }, { id: 115, name: "Commercial Airplane Multi-Engine Sea", selected: false }], selected: false },
    { id: 12, key: "airplanes", shortform: "DPE-ATPE-AMES", designation: [{ id: 116, name: "ATP - Airplane - Multi-Engine Sea", selected: false }], selected: false },
    { id: 13, key: "airplanes", shortform: "DPE-FIE-ASE", designation: [{ id: 117, name: "Flight Instructor - Airplane - Single Engine", selected: false }, { id: 118, name: "Flight Instructor - Instrument - Airplane Single Engine", selected: false }], selected: false },
    { id: 14, key: "airplanes", shortform: "DPE-FIE-AME", designation: [{ id: 119, name: "Flight Instructor - Airplane - Multi Engine", selected: false }, { id: 120, name: "Flight Instructor - Instrument Airplane - Multi Engine", selected: false }], selected: false },
    { id: 15, key: "airplanes", shortform: "DPE-FIEI-ASE", designation: [{ id: 121, name: "Flight Instructor - Initial - Airplane - Single Engine", selected: false }, { id: 122, name: "Flight Instructor - Initial - Instrument - Airplane Single Engine", selected: false }], selected: false },
    { id: 16, key: "airplanes", shortform: "DPE-FIEI-AME", designation: [{ id: 123, name: "Flight Instructor - Initial - Airplane - Multi Engine", selected: false }, { id: 124, name: "Flight Instructor - Initial - Instrument - Airplane Multi Engine", selected: false }], selected: false }
  ];
  
  const rotocraftData = [
    { id: 1, key: "rotorcraft", shortform: "DPE-PE-RH", designation: [{ id: 201, name: "Private - Helicopter", selected: false }], selected: false },
    { id: 2, key: "rotorcraft", shortform: "DPE-CE-RH", designation: [{ id: 202, name: "Commercial - Helicopter", selected: false }], selected: false },
    { id: 3, key: "rotorcraft", shortform: "DPE-CIRE-RH", designation: [{ id: 203, name: "Instrument - Helicopter", selected: false }, { id: 204, name: "Commercial - Helicopter", selected: false }], selected: false },
    { id: 4, key: "rotorcraft", shortform: "DPE-ATP-RH", designation: [{ id: 205, name: "ATP - Helicopter", selected: false }], selected: false },
    { id: 5, key: "rotorcraft", shortform: "DPE-FIE-RH", designation: [{ id: 206, name: "Flight Instructor - Helicopter", selected: false }, { id: 207, name: "Flight Instructor - Instrument Helicopter", selected: false }], selected: false },
    { id: 6, key: "rotorcraft", shortform: "DPE-FIEI-RH", designation: [{ id: 208, name: "Flight Instructor - Initial - Helicopter", selected: false }, { id: 209, name: "Flight Instructor - Initial - Instrument Helicopter", selected: false }], selected: false }
  ];
  
  const gliderData = [
    { id: 1, key: "glider", shortform: "DPE-PE-GL", designation: [{ id: 301, name: "Private - Glider", selected: false }, { id: 3034, name: "Flight Instructor - Initial Glider", selected: false }], selected: false },
    { id: 2, key: "glider", shortform: "DPE-CE-GL", designation: [{ id: 302, name: "Commercial - Glider", selected: false }], selected: false },
    { id: 3, key: "glider", shortform: "DPE-FIE-GL", designation: [{ id: 303, name: "Flight Instructor - Glider", selected: false }], selected: false },
    { id: 4, key: "glider", shortform: "DPE-FIEI-GL", designation: [{ id: 304, name: "Flight Instructor - Initial Glider", selected: false }], selected: false }
  ];
  
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
    // Customizable Area Start
    navigation: any;
    id: string;
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    selectedForm: string;
    accordform1Expanded: boolean;
    accordform2Expanded: boolean;
    step1Form: {
        airports: string[];
        options: Option[];
    }
    step2Form: {
        firstAccordionExpanded: boolean
        secondAccordionExpanded: boolean
        airplanes: string[];
        rotorcraft: string[];
        glider: string[];
        aircraftTypes: string[];
        addedAirCraftTypes: AircraftTypeItem[];
        airplanesOptions: IDesignation[];
        rotorcraftOptions: IDesignation[];
        gliderOptions: IDesignation[];
        aircraftTypeOptions: Option[]
    }
    activeStep: number,
    step3Form: {
        designation: string[];
        examCost: string;
        paymentMethods: string;
        instructions: string;
        uploadedFiles: File[];
        designationOption: Option[],
        uploadedData: any
        currentdata:{
            designation: string[];
            examCost: string;
            paymentMethods: string;
            instructions: string;
            uploadedFiles: File[];
        },
        errors: {
            examCost: string,
            paymentMethods: string,
            instructions: string,
        },
        currentIndex: number
    },
    isMobile: boolean;
    // Customizable Area End
}
interface SS {
    // Customizable Area Start
    id: number;
    // Customizable Area End
}

export default class AccountSetUpController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    private mediaQueryListener: MediaQueryList;
    getAirportsApiCallId: string = "";
    getAirplaneApiCallId : string = "";
    getRotorcraftApiCallId : string = "";
    getGliderApiCallId : string = "";
    getAircrafttypeApiCallId : string = "";
    postAircrafttypeApiCallId : string = "";
    postInstructionsApiCallId : string = "";
    CreateDesignationApiCallId : string = "";
    // Customizable Area End
    getDesignationApiCallId: string = "";
    postDesignationApiCallId: string = "";
    postAirportsApiCallId: string = "";
    deleteDesignationApiCallId: string = "";
    postAirportsRotorcraftsGlidersApiCallId: string = "";
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area Start
            // Customizable Area End
        ];
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
      // Customizable Area Start
        this.state = {
            selectedForm: 'form1',
            accordform1Expanded: false,
            accordform2Expanded: false,
            step1Form: {
                airports: [],
                options: []
            },
            step2Form: {
                firstAccordionExpanded: true,
                secondAccordionExpanded: false,
                airplanes: [],
                rotorcraft: [],
                glider: [],
                aircraftTypes: [],
                addedAirCraftTypes: [],
                airplanesOptions: airplaneData,
                rotorcraftOptions: rotocraftData,
                gliderOptions: gliderData,
                aircraftTypeOptions: [],
            },
            activeStep: 0,
            step3Form: {
                designation: [],
                examCost: "",
                paymentMethods: "",
                instructions: "",
                uploadedFiles: [],
                designationOption: [],
                uploadedData: [],
                currentdata : {designation: [],
                    examCost: "",
                    paymentMethods: "",
                    instructions: "",
                    uploadedFiles: [],},
                errors: {
                    examCost: "",
                    paymentMethods: "",
                    instructions: "",
                },
                currentIndex: 0
            },
            isMobile: window?.matchMedia('(max-width: 900px)').matches,
        };
        this.mediaQueryListener = window.matchMedia("(max-width: 900px)");
        // Customizable Area End
    }
       // Customizable Area Start
    async receive(from: string, message: Message) {
        this.handleApiResponse(message)
    }

    async handleApiResponse(message: any) {
        if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) return;
    
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    
        if (!responseJson) return;
    
        if (!responseJson.errors) {
            this.processSuccessResponse(apiRequestCallId, responseJson);
        } else {
            this.responseFailureCell(apiRequestCallId, responseJson);
        }
    }

    processSuccessResponse(apiRequestCallId: any, responseJson: any) {
        if (this.getDesignationApiCallId === apiRequestCallId) {
            this.updateDesignationOptions(responseJson);
        } else if (this.postDesignationApiCallId === apiRequestCallId) {
            this.handlePostDesignation();
        } else if (this.postAirportsApiCallId === apiRequestCallId) {
            this.handlePostAirports();
        }
    
        this.responseSucessCell(apiRequestCallId, responseJson);
    }
    
    updateDesignationOptions(responseJson: any) {
        const options = responseJson.message !== "No designation instructions found for the given ID"
            ? responseJson.map((item: any) => ({
                id: item.id.toString(),
                label: item.designation
            }))
            : [];
    
        this.setState((prev) => ({
            ...this.state,
            step3Form: {
                ...prev.step3Form,
                designationOption: options,
                designation: options.length > 0 ? [...this.state.step3Form.designation, options[0].id] : this.state.step3Form.designation
            }
        }));
    }
    
    handlePostDesignation() {
        const token = localStorage.getItem('accessToken');
        this.getDesignationRequest(token);
        this.handleNext();
    }

    async handlePostAirports() {
        const token = localStorage.getItem('accessToken');
        this.getAirportsRequest(token);
        this.handleNext();
    }

    getlabels = (options: Option[], selectedOptions: string[]) => {
        return selectedOptions
            .map((id: string) => options.find((option: Option) => option.id === id)?.name)
            .filter(Boolean) // Removes undefined values if an ID doesn't match
            .join(', ');
    }
    handleFileSelect = (fileList: FileList | null) => {
        if (!fileList) return;
        const newFiles = Array.from(fileList)
        this.setState(prevState => ({
            ...prevState,
            step3Form: {
                ...prevState.step3Form,
                uploadedFiles: [...prevState.step3Form.uploadedFiles, ...newFiles],
                errors:{
                    ...prevState.step3Form.errors, instructions:""
                }
            }
        }));
    };

    handleNext = () => {
        this.setState(prevState => ({
            activeStep: Math.min(prevState.activeStep + 1, 2)
        }));
    };
    deleteAircraftTest = (id: string) => {
        this.setState((state) => ({
            step2Form: {
                ...state.step2Form,
                addedAirCraftTypes: state.step2Form.addedAirCraftTypes.filter(
                    (item) => item.id !== id
                ),
            },
        }),
            () => {
                if (this.state.step2Form.addedAirCraftTypes.length == 0) {
                    this.setState((state) => ({
                        step2Form: {
                            ...state.step2Form,
                            airplanes: [],
                            rotorcraft: [],
                            glider: [],
                            aircraftTypes: [],
                        },
                    }))
                }
            }
        );
    }
    handleAccordform1Expanded = (name: string) => {
        if (name === "accordform1Expanded") {
            this.setState((state) => ({
                step1Form: {
                    ...state.step1Form,
                    accordform1Expanded: !this.state.accordform1Expanded
                },
            }));
        } else {
            this.setState((state) => ({
                step1Form: {
                    ...state.step1Form,
                    accordform2Expanded: !this.state.accordform2Expanded
                },
            }));
        }
    }

    handleStep1Change = (name: string, value: string[]) => {
        this.setState((state) => ({
            ...state,
            step1Form: {
                ...state.step1Form,
                [name]: value,
            },
        }));
    }

    handleSubmitStep2 = () => {
        const { airplanes, rotorcraft, glider,} = this.state.step2Form;
        const airplanesData = airplanes.map((id) => ({service_subcategory_id: id}))

        const rotocraftsData = rotorcraft.map((id) => ({service_subcategory_id: id}))

        const glidersData = glider.map((id) => ({service_subcategory_id: id}))

        const jsonBody = {
            airplanes: airplanesData,
            rotocrafts: rotocraftsData,
            gliders: glidersData
        }
        this.postDesignationRequest()
        this.postAirplaneRotorcraftGriderApi(jsonBody)

    }

    handleStep2Change = (name: string, value: string[]) => {
    
        this.setState((prevState: any) => {
            const optionKey = `${name}Options`; 
    
            return {
                step2Form: {
                    ...prevState.step2Form,
                    [name]: value, 
                    [optionKey]: prevState.step2Form[optionKey]?.map((item: any) => {
                        return ({
                            ...item,
                            selected: value.includes(item.id),
                            designation: item.designation.map((designation: any) => ({
                                ...designation,
                                selected: value.includes(item.id),
                            })),
                        })
                    }),
                },
            };
        }, this.updateAircraftTypes);
    };
    

    removeDesignation = (parentId: number, childId: string, key: string) => {
        let updatedItems: any[]
        this.setState((prevState: any) => {
            const updatedOptions = prevState.step2Form[`${key}Options`].map((parentItem: any) => {
                if (parentItem.id === parentId) {
                    const updatedDesignations = parentItem.designation.map((designation: any) =>
                        designation.id === childId ? { ...designation, selected: false } : designation
                    );

                    const isParentSelected = updatedDesignations.some((designation: any) => designation.selected);
                    updatedItems = !isParentSelected ? prevState.step2Form[`${key}`].filter((id: any) => id !== parentId): prevState.step2Form[`${key}`]
                    return {
                        ...parentItem,
                        selected: isParentSelected,
                        designation: updatedDesignations
                    };
                }
                return parentItem;
            });
           
            return {
                step2Form: {
                    ...prevState.step2Form,
                    [`${key}Options`]: updatedOptions,
                    [key]: updatedItems
                }
            };
        }, this.updateAircraftTypes);
    };
    

    updateAircraftTypes = () => {
        const { airplanesOptions, rotorcraftOptions, gliderOptions } = this.state.step2Form;
    
        const allSelectedDesignations = [
            ...airplanesOptions,
            ...rotorcraftOptions,
            ...gliderOptions
        ]
        .map(item => 
            item.designation
                .filter((designation: { selected: boolean }) => designation.selected)
                .map((designation: { id: any; name: any }) => ({
                    key: item.key,  
                    parentId: item.id, 
                    id: designation.id, 
                    value: designation.name
                }))
        )
        .reduce((acc, val) => acc.concat(val), []); 
    
        this.setState(state => ({
            ...state,
            step2Form: { 
                ...state.step2Form, 
                addedAirCraftTypes: allSelectedDesignations, 
                secondAccordionExpanded: true 
            }
        }));
    };
    
    
    handleStep3Change = (name: string, value: string[]) => {
        const index = this.state.step3Form.designationOption.findIndex((des) => des.id === value[0])
        const uploadedData = this.state.step3Form.uploadedData
        const examCost = uploadedData[index]?.examCost ?? ""
        const instructions = uploadedData[index]?.instructions ?? ""
        const paymentMethods = uploadedData[index]?.paymentMethods ?? ""
        const uploadedFiles = uploadedData[index]?.uploadedFiles ?? []
        this.setState((state) => ({
            ...state,
            step3Form: {
                ...state.step3Form,
                [name]: value,
                currentIndex: index,
                examCost,
                instructions,
                paymentMethods,
                uploadedFiles
            },
        }));
    }

    handleStep3InputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event?.target
        if  (name == "examCost"){
            const numericValue = value.replace(/[^0-9.,]/g, '');
            const formattedValue = `$ ${numericValue}`;
            const isEmpty = value.trim() === ""
            this.setState((state) => ({
                ...state,
                step3Form: {
                    ...state.step3Form,
                    [name]: formattedValue,
                    errors: {
                        ...state.step3Form.errors,
                        examCost: isEmpty ? "Examcost is required":""
                    }
                },
            }));
        }else if(name == "paymentMethods"){
       const textOnlyValue = value.replace(/[^a-zA-Z\s,]/g, '');
        const isEmpty = value.trim() === ""
            this.setState((state) => ({
                ...state,
                step3Form: {
                    ...state.step3Form,
                    [name]: textOnlyValue,
                    errors: {
                        ...state.step3Form.errors,
                        paymentMethods: isEmpty ? "Payment method is required":""
                    }
                },
            }));
        }else{
            const isEmpty = value.trim() === ""
            this.setState((state) => ({
                ...state,
                step3Form: {
                    ...state.step3Form,
                    [name]: value,
                    errors: {
                        ...state.step3Form.errors,
                        instructions: isEmpty ? "Instructions is required":""
                    }
                },
            }));
        }  
    }

    validateForm = (name: string) => {
        this.setState((state) => {
            const { step3Form } = state;
            const errors = { ...step3Form.errors };
            const hasUploadedFiles = step3Form.uploadedFiles?.length > 0;
            const hasInstructions = step3Form.instructions?.trim().length > 0;
            if (name === "instructions") {
                errors.instructions = hasInstructions || hasUploadedFiles ? "" : "Instructions or a PDF is required";
            }
    
            if (name === "examCost") {
                const cleanedExamCost = step3Form.examCost.replace(/\$/g, "").trim(); 
                errors.examCost = cleanedExamCost ? "" : "Exam cost is required";
            }
    
            if (name === "paymentMethods") {
                errors.paymentMethods = step3Form.paymentMethods.trim() ? "" : "Accepted payment methods are required";
            }
    
            return {
                step3Form: {
                    ...step3Form,
                    errors,
                }
            };
        });
    };

    handleSubmit = () => {
        const currentIndex = this.state.step3Form.designationOption.findIndex(
            (opt) => opt.id === this.state.step3Form.designation[this.state.step3Form.designation.length - 1]
        );
        const nextIndex = currentIndex + 1;
    
        const designation =
            nextIndex < this.state.step3Form.designationOption.length
                ? [this.state.step3Form.designationOption[nextIndex].id]
                : this.state.step3Form.designation;
    
        this.setState((state) => {
            const newEntry = {
                designation: [this.state.step3Form.designationOption[currentIndex].id],
                examCost: state.step3Form.examCost,
                paymentMethods: state.step3Form.paymentMethods,
                instructions: state.step3Form.instructions,
                uploadedFiles: state.step3Form.uploadedFiles,
            };
    
            const isExisting = state.step3Form.uploadedData.some(
                (item: { designation: any }) =>
                    JSON.stringify(item.designation) === JSON.stringify(newEntry.designation)
            );
    
            const updatedUploadedData = isExisting
                ? state.step3Form.uploadedData.map((item: { designation: any }) =>
                      JSON.stringify(item.designation) === JSON.stringify(newEntry.designation) ? newEntry : item
                  )
                : [...state.step3Form.uploadedData, newEntry];
    
            return {
                ...state,
                step3Form: {
                    ...state.step3Form,
                    designation: designation,
                    uploadedData: isExisting
                        ? updatedUploadedData 
                        : [...state.step3Form.uploadedData, newEntry], 
                    examCost: "",
                    paymentMethods: "",
                    instructions: "",
                    uploadedFiles: [],
                    currentdata: {
                        designation: [this.state.step3Form.designationOption[currentIndex].id],
                        examCost: state.step3Form.examCost,
                        paymentMethods: state.step3Form.paymentMethods,
                        instructions: state.step3Form.instructions,
                        uploadedFiles: state.step3Form.uploadedFiles,
                    },
                    currentIndex: currentIndex + 1,
                },
            };
        }, () => {
            this.instructionsApiCall();
            if (this.state.step3Form.designationOption.length - 1 === currentIndex) {
                this.props.navigation.navigate('landingPage');
            }
        });
    };
    

    handlePrevious = () => {
        this.setState(prevState => ({
            activeStep: Math.min(prevState.activeStep - 1, 2)
        }));
    }

    handleAccordionChange = (type: string, isExpanded: boolean) => {
        this.setState((state) => ({
            ...state,
            step2Form: {
                ...state.step2Form,
                [type]: isExpanded,
            },
        }));
    };

    handleScreenSizeChange = (event: MediaQueryListEvent) => {
        this.setState({ isMobile: event.matches });
      };

    async componentDidMount() {
        let token = localStorage.getItem('accessToken');
        this.mediaQueryListener.addEventListener("change", this.handleScreenSizeChange);
        this.getAirportsRequest(token);
        this.getDesignationRequest(token);
        this.getAirplaneApi()
        this.getRotorcraftApi()
        this.getGliderApi()
        this.getAircrafttypeApi()
    }

    async componentWillUnmount() {
        this.mediaQueryListener.removeEventListener("change", this.handleScreenSizeChange);
      };

    getAirportsRequest = (token: any) => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: token,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getAirportsApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getAirportUrl
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    getDesignationRequest = (token: any) => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: token,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getDesignationApiCallId = requestMessage.messageId
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getDesignationUrl
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    postDesignationRequest = async() => {
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
        };
        let token = localStorage.getItem('accessToken');
        const data = {
            token: token,
            designations: this.state.step2Form.addedAirCraftTypes.map((item) => ({ designation: item.value }))
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.postDesignationApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            "bx_block_categories/designation_instructions/create_multiple"
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "POST"
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(data)
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    };

    postAirportsRequest = () => {
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
        };
        let token = localStorage.getItem('accessToken');
        const data = {
            token: token,
            airports: this.state.step1Form.airports.map((item) => ({ service_subcategory_id: item }))
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.postAirportsApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            "bx_block_categories/dpe_airport_settings/create_multiple"
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "POST"
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(data)
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    };

    deleteDesignationRequest = (currentTestId: string) => {
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
        };
        let token = localStorage.getItem('accessToken');
        const data = {
            token: token,
            designations: this.state.step2Form.addedAirCraftTypes.map((item) => ({ designation: item.value }))
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.deleteDesignationApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_categories/designation_instructions/${currentTestId}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "DELETE"
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(data)
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;

    };

    accountSetupApiCall = async (valueData: {
        contentType?: string;
        method?: string;
        endPoint?: string;
        body?: {};
        apiType?: string;
        type?: string;
    }) => {
        const token = await (localStorage.getItem('accessToken'))
        let { contentType, method, endPoint, body, apiType } = valueData;
        let header = {
            "Content-Type": contentType,
            Token: token
        };
        let accountSetupRequestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        accountSetupRequestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        accountSetupRequestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        body &&
        accountSetupRequestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                apiType === "" ? JSON.stringify(body) : body
            );
            accountSetupRequestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        runEngine.sendMessage(accountSetupRequestMessage.id, accountSetupRequestMessage);
        return accountSetupRequestMessage.messageId;
    };

    responseSucessCell = async (apiRequestCallId: string, responseJson: Option & ExamData & Option[]) => {
        if (apiRequestCallId === this.getAirplaneApiCallId) {
            this.getAirplaneSucessCallBack(responseJson);
        }
        if (apiRequestCallId === this.getRotorcraftApiCallId) {
            this.getRotorcraftSucessCallBack(responseJson);
        }
        if (apiRequestCallId === this.getGliderApiCallId) {
            this.getGliderSucessCallBack(responseJson);
        }
        if (apiRequestCallId === this.getAircrafttypeApiCallId) {
            this.getAircrafttypeSucessCallBack(responseJson);
        }
        if (apiRequestCallId === this.postAircrafttypeApiCallId) {
            this.postAircrafttypeSucessCallBack(responseJson);
        }
        if (apiRequestCallId === this.postInstructionsApiCallId) {
            this.instructionsApiSucessCallBack(responseJson);
        }
        if (apiRequestCallId === this.getAirportsApiCallId) {
            this.getAirportsSucessCallBack(responseJson);
        }
    };

    responseFailureCell = async (apiRequestCallId: string, responseJson: ErrorResponse) => {
        if (apiRequestCallId === this.getAirplaneApiCallId) {
            this.getAirplaneFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.getRotorcraftApiCallId) {
            this.getRotorcraftFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.getGliderApiCallId) {
            this.getGliderFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.getAircrafttypeApiCallId) {
            this.getAircrafttypeFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.postAircrafttypeApiCallId) {
            this.postAircrafttypeFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.postInstructionsApiCallId) {
            this.instructionsApiFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.getAirportsApiCallId) {
            this.getAirportsFailureCallBack(responseJson);
        }
    };

    getAirportsSucessCallBack = (response: Option[]) => {
        this.setState({
            ...this.state,
            step1Form: {
                airports: this.state.step1Form.airports,
                options: response
            }
        });
    }

    getAirportsFailureCallBack = (response: ErrorResponse) => {
        toast.error(response.errors[0].token)
    }


    getAirplaneApi = async () => {
        this.getAirplaneApiCallId = await this.accountSetupApiCall({
            contentType: configJSON.contentTypeApiAddDetail,
            method: configJSON.validationApiMethodType,
            endPoint: configJSON.gradeOfAirplaneEndPoint
        })
    };

    postAirplaneRotorcraftGriderApi = async (body: any) => {
        this.postAirportsRotorcraftsGlidersApiCallId = await this.accountSetupApiCall({
            contentType: configJSON.contentTypeApiAddDetail,
            method: configJSON.apiMethodTypeAddDetail,
            endPoint: configJSON.postAirplaneRotocraftGlider,
            body: JSON.stringify(body),
        })
    };


    getAirplaneSucessCallBack = (response: Option) => {
       if (Array.isArray(response) ) {
        this.setState((prevState) => ({
            step2Form: {
                ...prevState.step2Form,
                // airplanesOptions: airplanesOptions, 
            },
        }));
    }
    };

    getAirplaneFailureCallBack = (response: ErrorResponse) => {
        toast.error(response.errors[0].token)
    };

    getRotorcraftApi = async () => {
        this.getRotorcraftApiCallId = await this.accountSetupApiCall({
            contentType: configJSON.contentTypeApiAddDetail,
            method: configJSON.validationApiMethodType,
            endPoint: configJSON.rotorcraftEndPoint
        })
    };

    getRotorcraftSucessCallBack = (response: Option) => {
       if (Array.isArray(response) ) {
        this.setState((prevState) => ({
            step2Form: {
                ...prevState.step2Form,
                // rotorcraftOptions: rotorcraftOptions, 
            },
        }));
    }
    };

    getRotorcraftFailureCallBack = (response: ErrorResponse) => {
        toast.error(response.errors[0].token)
    };

    getGliderApi = async () => {
        this.getGliderApiCallId = await this.accountSetupApiCall({
            contentType: configJSON.contentTypeApiAddDetail,
            method: configJSON.validationApiMethodType,
            endPoint: configJSON.airPortGliderEndPoint
        })
    };

    getGliderSucessCallBack = (response: Option) => {
       if (Array.isArray(response) ) {
        this.setState((prevState) => ({
            step2Form: {
                ...prevState.step2Form,
                // gliderOptions: gliderOptions, 
            },
        }));
    }
    };

    getGliderFailureCallBack = (response: ErrorResponse) => {
        toast.error(response.errors[0].token)
    };

    getAircrafttypeApi = async () => {
        this.getAircrafttypeApiCallId = await this.accountSetupApiCall({
            contentType: configJSON.contentTypeApiAddDetail,
            method: configJSON.validationApiMethodType,
            endPoint: configJSON.aircraftTypeEndPoint
        })
    };

    getAircrafttypeSucessCallBack = (response: Option) => {
       if (Array.isArray(response) ) {
        const aircraftTypeOptions = response.map((aircraft) => ({
            id: aircraft.id.toString(), 
            name: aircraft.name,       
        }));
        this.setState((prevState) => ({
            step2Form: {
                ...prevState.step2Form,
                aircraftTypeOptions: aircraftTypeOptions, 
            },
        }));
    };
}

    getAircrafttypeFailureCallBack = (response: ErrorResponse) => {
        toast.error(response.errors[0].token)
    };

    handleAircraft = async (value: string) => {
        const body = {
            category_name: "Aircraft",
            subcategory_name: value
        }
        this.setState({step2Form: { ...this.state.step2Form,aircraftTypes: [...this.state.step2Form.aircraftTypes, value] }},this.updateAircraftTypes)
        this.postAircrafttypeApiCallId = await this.accountSetupApiCall({
            contentType: configJSON.contentTypeApiAddDetail,
            method: configJSON.apiMethodTypeAddDetail,
            endPoint: configJSON.postAirCraftEndPoint,
            body: JSON.stringify(body),
        });
    };

    postAircrafttypeSucessCallBack = (response: Option) => {
        if(response){
            this.getAircrafttypeApi()
        }
   }
 
     postAircrafttypeFailureCallBack = (response: ErrorResponse) => {
         toast.error(response.errors[0].token)
     };

    instructionsApiCall = async () => {
        const currentdata: {designation: string[], examCost: string, paymentMethods:string,instructions:string,uploadedFiles: File[]} = this.state.step3Form.currentdata

        let formdata = new FormData();
        if( Array.isArray(currentdata.uploadedFiles)){
            currentdata.uploadedFiles.forEach((upload: File) => {
                formdata.append("pdf_files[]", upload);
            });
        }
        formdata.append('designation_instruction[exam_cost]', currentdata.examCost.replace('$', '').trim());
        formdata.append('designation_instruction[accepted_payment_methods]', currentdata.paymentMethods);
        formdata.append('designation_instruction[instructions]', currentdata.instructions);
        this.postInstructionsApiCallId = await this.accountSetupApiCall({
            method: configJSON.putMethod,
            endPoint: `${configJSON.instructionsEndPoint}${currentdata.designation[0]}`,
            body: formdata,
        });
    };

    instructionsApiSucessCallBack = (response: ExamData) => {
        this.state.step3Form.designationOption.some((option) => option.id === String(response.id));
    };

    instructionsApiFailureCallBack = (response: ErrorResponse) => {
     toast.error(response.errors[0].token)
    };


    handleLogout = () => {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("role");
       this.props.navigation.navigate("Login")
    }
    
    // Customizable Area End
}
