// Customizable Area Start
import React from "react";
import Settings2Controller, { Props } from "../Settings2Controller";
import { Box, Typography, styled, } from "@mui/material";
import { email } from "../assets";

export default class SettingsContactUsApplicant extends Settings2Controller {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <SettingsContactUsApplicantContainer>
        <Box
          className="mainContainer"
          data-test-id="details-page">
          <Box className="header">
            <Typography data-test-id="testSettingsApplicantTitle" className={"headerText"} >Contact us</Typography>
          </Box>
        </Box>
        <Box className="contactUsContent">
          <Box className="contactUsInnerContent">
            <Typography className="titleSupport">For support, you can reach us at:</Typography>
            <Box
              className="emailContainer">
              <img src={email} alt="email" className="emailIcon" />
              <Typography className="email" data-test-id="contact-us-applicant-email" >applicantsupport@checkridepro.app</Typography>
            </Box>
          </Box>
        </Box>
      </SettingsContactUsApplicantContainer>
    )
  }
}

const SettingsContactUsApplicantContainer = styled(Box)({
  "& .header": { display: 'flex', alignItems: 'center', gap: '20px' },
  "& .mainContainer": {
    alignItems: "center",
    background: "#ffffff",
    padding: "10px 50px 10px 50px",
    display: "flex",
    height: "60px",
    justifyContent: "space-between"
  },
  "& .contactUsInnerContent": {
    alignItems: "center",
    background: "#ffffff",
    padding: "50px 50px 50px 50px",
  },
  "& .emailContainer": { display: 'flex', alignItems: 'center', },
  "& .headerText": {
    fontSize: "22px",
    fontFamily: "Montserrat",
    fontWeight: 700,
    color: "#0074BF",
  },
  "& .email": {
    fontWeight: "500", color: "#001C55",
    fontSize: "16px",
    fontFamily: "Montserrat",
    marginLeft: "20px"
  },
  "& .contactUsContent": { padding: '50px 50px 50px 50px' },
  "& .emailIcon": { width: "40px", height: "40px" },
  "& .titleSupport": {
    fontSize: "16px",
    fontWeight: "400",
    color: "#0F172A",
    marginBottom: "15px"
  },

})
// Customizable Area End
