import React from 'react';
import {
    Autocomplete,
    Chip,
    TextField,
    Box,
    InputAdornment, Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';

export interface Option {
    id: string;
    name: string;
}

interface MultipleSelectWithChipsProps {
    options: Option[];
    placeholder: string;
    selectedItems: string[];
    onItemsChange: (name: string, items: string[]) => void;
    name: string;
    popUpHandler?: (type: string, itemToDelete: any) => void;
    addAircraft? :(value: string) => void;
}

interface MultipleSelectWithChipsState {
    inputValue: string;
    isOpen: boolean;
}

class MultipleSelectWithChips extends React.Component<MultipleSelectWithChipsProps, MultipleSelectWithChipsState> {
    constructor(props: MultipleSelectWithChipsProps) {
        super(props);
        this.state = {
            inputValue: '',
            isOpen: false
        };
    }

    handleChange = (event: any, value: Option[]) => {
        const selectedIds: string[] = value.map(item => item.id);
        this.props.onItemsChange(this.props.name, selectedIds);
    };

    handleInputChange = (event: any, value: string) => {
        this.setState({ 
            inputValue: value,
            isOpen: value.length > 0 // Only open dropdown when input has content
        });
    };

    addAircraftType = (input: string) => {
        this.props.addAircraft?.(input)
    }
    handleDelete = (itemToDelete: Option) => {
        if (this.props.name === 'airports') {
            const updatedItems = this.props?.selectedItems.filter(item => item !== itemToDelete.id);
            this.props.onItemsChange(this.props.name, updatedItems);
        } else {
            const updatedItems = this.props.selectedItems?.filter(id => id !== itemToDelete.id) || [];
                this.props.onItemsChange(this.props.name, updatedItems);
        }
    }

    clearSelections = () => {
        this.props.onItemsChange(this.props.name, []);
    };

    render() {
        const { inputValue, isOpen } = this.state;
        const { selectedItems, options, placeholder, name } = this.props;
        const selectedOptions = options?.filter((option: any) => {
            return selectedItems?.includes(option.id)
        });

        return (
            <Box sx={{ width: 300 }}>
                <Autocomplete
                    getOptionLabel={(option) => option?.name}
                    value={selectedOptions}
                    onInputChange={this.handleInputChange}
                    clearOnBlur={false}
                    onChange={this.handleChange}
                    inputValue={inputValue}
                    multiple
                    options={options}
                    open={isOpen} // Control dropdown visibility
                    onClose={() => this.setState({ isOpen: false })}
                    noOptionsText={
                        <>
                            <Typography sx={{ 
                                            padding: '5px 10px 5px 10px',
                                            fontFamily: 'Montserrat, sans-serif',
                                            fontWeight: 400,
                                            color:"#94A3B8",
                                            textAlign: "left",
                                            fontSize: "16px",
                                            cursor:"pointer"
                                        }}>No results found for '{inputValue}'</Typography>
                            {inputValue && name === 'aircraftTypes' &&
                                (
                                    <Typography 
                                        sx={{ 
                                            padding: '5px 10px 5px 10px',
                                            fontFamily: 'Montserrat, sans-serif',
                                            fontWeight: 400,
                                            color: "#0F172A",
                                            textAlign: "left",
                                            fontSize: "16px",
                                            cursor:"pointer"
                                        }}
                                        onClick={()=> this.addAircraftType(inputValue)}> 
                                        Add '{inputValue}' as other aircraft type
                                    </Typography>
                                )}
                        </>
                    }
                    renderOption={(props, option) => (
                        <Box
                            component="li"
                            sx={{
                                padding: '10px 3px 10px 3px',
                                '&:hover': {
                                    backgroundColor: '#E0F2F8',
                                    cursor: 'pointer',
                                },
                                fontFamily: 'Montserrat, sans-serif',
                                fontSize: '16px',
                                lineHeight: '24px',
                            }}
                            {...props}
                        > 
                            {option.name} 
                        </Box>
                    )}
                    sx={{
                        width: '360px',
                        height: '50px',
                        padding: '10px 0px 10px 0px',
                        '& .MuiInputBase-root': {
                            border: '1px solid #CBD5E1',
                            '&:hover': {
                                border: '1px solid blue',
                                borderColor: 'darkgray',
                            },
                        },
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            placeholder={placeholder}
                            variant="outlined"
                            InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                                endAdornment: null,
                                sx: {
                                    "& .css-wb57ya-MuiFormControl-root-MuiTextField-root":{
                                    width:"90%"
                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        border: 'none',
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        border: 'none',
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        border: 'none',
                                    },
                                    '&::placeholder': {
                                        color: '#94A3B8',
                                        opacity: 1,
                                    },
                                    borderRadius: '20px',
                                    "@media (max-width: 414px)": {
                                        width:"90%"
                                    }
                                   
                                },
                            }}
                        />
                    )}
                    renderTags={() => null}
                />

                <Box sx={{ mt: 1, mb:2 }}>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                        {selectedOptions.map((item) => (
                            <Chip
                                sx={{
                                    color: '#001C55',
                                    height: '32px',
                                    backgroundColor: '#E0F2F8',
                                    '& .MuiChip-deleteIcon': {
                                        height: '16px',
                                        width: '16px',
                                        color: "#001C55",
                                    },
                                }}
                                key={item.id}
                                label={item.name}
                                onDelete={() => this.handleDelete(item)}
                                deleteIcon={<CloseIcon />}
                            />
                        ))}
                    </Box>
                </Box>
            </Box>
        );
    }
}

export default MultipleSelectWithChips;