export const insertPhoto = require("../assets/insert_photo.png");
export const messageIcon = require("../assets/messageIcon.png");
export const deleteIcon = require("../assets/deleteIcon.png");
export const ftnIcon = require("../assets/ftnIcon.png");
export const mailIcon = require("../assets/mailIcon.png");
export const mobileIcon = require("../assets/mobileIcon.png");
export const muteIcon = require("../assets/muteIcon.png");
export const reportIcon = require("../assets/reportIcon.png");
export const emptyIcon = require("../assets/emptyIcon.png");
export const checkMark = require("../assets/checkMark.png");
