import React from "react";
// Customizable Area Start
import {
    Box,
    Typography,
    Grid,
    Button,
    Dialog,
    DialogTitle,
    Divider,
    styled
} from "@mui/material";

import CustomisableUserProfilesController, { Props } from "../CustomisableUserProfilesController";
import { bgImage } from "../../../email-account-registration/src/assets"
import { logoImage } from "../../../forgot-password/src/assets"
import { processIcon } from "../assets";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
const configJSON = require("../config");
// Customizable Area End

export default class AccountDeleteProcess extends CustomisableUserProfilesController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    renderTermsCondition = () => {
        return (
            <TermContitionComponent
                data-testId="termAndConditionModal"
                aria-labelledby="customized-dialog-title"
                open={this.state.isTermServices}
                onClose={this.handleCloseTermCondition}
                PaperProps={{
                    sx: {
                        width: "600px", height: "640px", borderRadius: "20px",
                        "@media screen and (min-width: 360px) and (max-width: 600px)": {
                            height: "auto",
                        },
                    },
                }}
            >
                <DialogTitle className="deleteUserTitle" sx={{ m: 0, p: 2, gap: 2 }} id="customized-dialog-title">
                    {this.state.conditionValue === 4 ? "Privacy Policy" : "Terms and Conditions"}
                </DialogTitle>
                <IconButton data-testId="closeAvailabilityModal"
                    aria-label="close"
                    sx={(theme) => ({
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme.palette.grey[500],
                    })}
                    onClick={() => this.handleCloseTermCondition()}
                >
                    <CloseIcon data-testId = "closeTermCondition"  />
                </IconButton>
                < Divider />
                <Box style={{ padding: "20px",overflowY: "scroll"}}>
                    <Typography className="privacyPolicyTextMessage">{this.state.termAndCondition}</Typography>
                </Box>
            </TermContitionComponent>
        )
    }

    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            <>
                <Grid container direction={'row'} sx={{
                    width: '100%',
                    height: '100%',
                }}>
                    <Grid item xs={12}>
                        <Grid sx={{
                            height: "90px", width: "100%",
                            backgroundColor: '#daf8ff',
                        }}>
                            <Box sx={webStyle.deleteLogoContainer}>
                                <img src={logoImage} style={{ padding: '7px', height: '50px', width: '118', objectFit: "cover" }} alt="logo" />
                            </Box>
                        </Grid>
                        <Grid sx={webStyle.backgroundContainer}>
                            <Box sx={webStyle.deleteStatusContainer} data-test-id='account-inprogress'>

                                <Box sx={webStyle.processIconContainer}>
                                    <img src={processIcon}
                                        style={{ height: '60px', width: '60px' }} alt="left-arrow" />
                                </Box>
                                <Box sx={webStyle.deleteProcessContainer}>
                                    <Typography variant="h6" sx={webStyle.deleteProcessTitleText}>
                                        Account deletion in progress
                                    </Typography>
                                    <Typography sx={webStyle.statusTextMesssage}>
                                        Your account deletion request is being processed. If you have any outstanding appointments, we will contact you to resolve them before finalizing the deletion.
                                        For more information, please review our
                                        <Typography>
                                            <span style={{ color: "#0074BF", fontWeight: 600, cursor: "pointer" }} data-testId = "termConditionTestId" onClick={() => this.handleOpenTermCondition(3)}>Terms of Service</span>  and
                                            <span style={{ color: "#0074BF", fontWeight: 600, cursor: "pointer" }} data-testId = "privacyPolicyTestId" onClick={() => this.handleOpenTermCondition(4)}> Privacy Policy</span>.
                                        </Typography>
                                    </Typography>
                                </Box>
                                <Button
                                    type="button"
                                    data-testId="userStatusTestId"
                                    sx={webStyle.deleteProcessBtn}
                                    variant="contained"
                                    onClick={this.handleLogoutBtn}
                                >
                                    Log out
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>

                </Grid >
                {this.renderTermsCondition()}
            </>
        )
        // Customizable Area End
    }
}

// Customizable Area Start
const webStyle = {
    appLogo: {
        padding: '7px',
        height: '50px',
        width: '118',
        objectFit: "cover"
    },
    backgroundContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        backgroundColor: '#ffffff',
        backgroundImage: `url(${bgImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    },
    deleteProcessContainer: {
        position: "relative",
        width: "100%",
        maxWidth: "360px",
        mb: 1
    },
    deleteLogoContainer: {
        height: '100%',
        width: '118',
        objectFit: "cover",
        marginLeft: "100px",
        display: "flex",
        alignItems: "center",
        "@media (max-width: 414px)": {
            marginLeft: "20px",
        }
    },
    deleteProcessBtn: {
        height: "50px",
        width: "360px",
        marginTop: "25px",
        marginBottom: "20px",
        border: "none",
        borderRadius: "20px",
        backgroundColor: "#0074BF",
        color: "white",
        cursor: "pointer",
        fontWeight: 700,
        textTransform: "none",
        "@media (max-width: 414px)": {
            width: "260px",
        }
    },
    deleteStatusContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        borderRadius: '26px',
        border: '1px solid rgb(252, 250, 250)',
        backgroundColor: 'rgb(255, 255, 255)',
        padding: '30px',
        "@media (max-width: 414px)": {
            width: "100%",
            padding: '20px ,0px'
        }
    },
    statusTextMesssage: {
        margin: '15px 0px',
        fontFamily: 'Montserrat',
        fontWeight: 400,
        fontSize: "16px",
        color: "#001C55",
        textAlign: 'center',
        "@media (max-width: 414px)": {
            fontSize: "12px"
        }
    },
    processIconContainer: {
        margin: '7px 18px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '120px',
        width: '120px',
        borderRadius: "50%",
        backgroundColor: '#E0F2F8'
    },
    deleteProcessTitleText: {
        fontFamily: 'Montserrat',
        fontWeight: 700,
        fontSize: "22px",
        color: "#001C55",
        textAlign: 'center'

    }
};

const TermContitionComponent = styled(Dialog)({
    "& .deleteUserTitle": {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        fontFamily: "Montserrat",
        fontWeight: 700,
        fontSize: "22px",
        lineHeight: "28px",
    },
    "& .privacyPolicyTextMessage": {
        fontFamily: "Montserrat",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: '24px',
        textAlign: "justify",
        color: "#0F172A"

    }
})
// Customizable Area End