// Customizable Area Start
import React from "react";
import {
  Box,
  TextField,
  Typography,
  styled,
  Button,
  InputAdornment,
  IconButton,
  Snackbar
} from "@mui/material";

import {
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { greenTick,cross } from "./assets";

import SettingChangePasswordController, {
  Props,
} from "./SettingChangePasswordController";

export default class SettingChangePassword extends SettingChangePasswordController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {  togglePasswordVisibility,handleInputChange } = this;
    const {  showPassword,showCurrentPassword, showConfirmPassword,password ,confirmPassword,errors,currentpassword} = this.state;
    return (
      <>
        <Box sx={webStyle.headerContainer}>
          <Typography sx={webStyle.headerText}>Change password</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
        >
          <StyledContainer>
            <Box className="form">
              <Box className="fieldContainer">
                <Typography className="fieldLabel">Current password</Typography>
                <StyledTextField
                 name="password"
                 data-test-id='handleInputChange1'
                 type={showCurrentPassword ? "text" : "password"}
                 value={currentpassword}
                 onChange={(e) => handleInputChange("currentpassword", e.target.value)}
                 fullWidth
                 variant="outlined"
                  placeholder="Enter current password"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                         <IconButton
                              onClick={() => togglePasswordVisibility('showCurrentPassword')}
                              edge="end"
                              data-test-id="pass-icon"
                            >
                              {showCurrentPassword ? <Visibility /> :<VisibilityOff />}
                            </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Box className="fieldContainer">
                <Typography className="fieldLabel">New password</Typography>
                <StyledTextField
                 name="password"
                 data-test-id="handleInputChange2"
                 type={showPassword ? "text" : "password"}
                 value={password}
                 onChange={(e) => handleInputChange("password", e.target.value)}
                 fullWidth
                 variant="outlined"
                  placeholder="Enter a new password"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                          <IconButton
                              onClick={() => togglePasswordVisibility('showPassword')}
                              edge="end"
                              data-testId="pass-icon"
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  error={!!errors.password}
                  helperText={
                    errors.password && (
                      <span style={{ marginTop:"13px",display: "flex", alignItems: "center",fontSize:"13px", color: "#0F172A",fontFamily:"Montserrat" }}>
                        <img
                          src={cross} 
                          alt="Error"
                          style={{ marginRight: "8px", width: "11px", height: "11px" }}
                        />
                        {errors.password}
                      </span>
                    )
                  }
                />
                {password && <Box sx={webStyle.passwordRequirements}>
                        {this.passwordRequirements.map((req, index) => (
                          <Box key={index} sx={webStyle.requirementItem}>
                            <CheckCircleIcon
                              sx={this.getPasswordRequirementIconStyle(req, password)}
                            />
                            <Typography sx={webStyle.requirementText}>
                              {req.text}
                            </Typography>
                          </Box>
                        ))}
                      </Box>}
              </Box>
              <Box className="fieldContainer">
                <Typography className="fieldLabel">
                  Confirm new password
                </Typography>
                <StyledTextField
                  name="confirmPassword"
                  data-test-id='confirmPassword'
                  type={showConfirmPassword ? "text" : "password"}
                  value={confirmPassword}
                  onChange={(e) => handleInputChange("confirmPassword", e.target.value)}
                  fullWidth
                  variant="outlined"
                  placeholder="Confirm your new password"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                       <IconButton
                              onClick={() => togglePasswordVisibility('showConfirmPassword')}
                              edge="end"
                            >
                              {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                      </InputAdornment>
                    ),
                  }}
                    error={!!errors.confirmPassword}
                    
                    helperText={
                      errors.confirmPassword && (
                        <span style={{ display: "flex",marginTop:"13px", alignItems: "center",fontSize:"13px", color: "#0F172A",fontFamily:"Montserrat" }}>
                          <img
                            src={cross} 
                            alt="Error"
                            style={{ marginRight: "8px", width: "11px", height: "11px" }}
                          />
                          {errors.confirmPassword}
                        </span>
                      )
                    }
                  
                />
                 
              </Box>
              <Button onClick={this.createAccount}  
               className ={ this.state.isValid ? "activeCreateAccountButton" : "createAccountButton"}
               disabled={!this.state.isValid}
               >Save changes</Button>
            </Box>
            
          </StyledContainer>
          <Snackbar
                  open={this.state.isAlert}
                  autoHideDuration={3000}
                  anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                  onClose={this.oncloseAlert}
                  data-test-id="alertTestId"
                >
          <div
              style={{
                backgroundColor: "white",
                color: this.state.alertType === "success" ? "#0F172A" : "red",
                padding: "8px 16px 8px 8px",
                fontFamily: 'Montserrat, sans-serif',
                borderRadius: "20px",
                fontSize:"16px",
                boxShadow: "0px 6px 15px -3px #00000026", 

              }}
            >
            <img 
              src={this.state.changePassword === "Your password has been successfully reset." ? greenTick : cross}
              alt={this.state.changePassword === "Your password has been successfully reset." ? "Success" : "Error"}
              style={{
                width: "20px", 
                height: "20px",
                marginRight: "8px", 
                verticalAlign: "middle",
                objectFit:'contain'
              }}
            />
            {this.state.alertMsg}
          </div>

                  </Snackbar>
                
        </Box>
      </>
    );
  }
}


const StyledContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  height: "fit-content",
  alignItems: "center",
  maxWidth: "clamp(400px, 50vw, 860px)",
  background: "#ffffff",
  borderRadius: "20px",
  marginTop: "40px",
  width: "100%",
  padding: "20px 65px 20px 65px",

  "& .form": {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
    maxWidth: "80%",
    minWidth:"400px",
    width: "100%",
    paddingTop:'50px',

    "& .fieldContainer": {
      display: "flex",
      flexDirection: "column",
      gap: "5px",
      "& .fieldLabel": {
        fontFamily: "Montserrat",
        fontWeight: 600,
        fontSize: "16px",
        color: "#334155",
      },
    },
    "& .activeCreateAccountButton": {
      height: "50px",
      width: "100%",
      maxWidth: "360px",
      marginTop: "25px",
      marginBottom: "20px",
      fontSize: "16px",
      fontFamily: "Montserrat",
      border: "none",
      borderRadius: "20px",
      backgroundColor: "#0074BF",
      color: "white",
      cursor: "pointer",
      fontWeight: 700,
      textTransform: "none"
    },
    
    "& .createAccountButton": {
      borderRadius: "20px",
      backgroundColor: "#B2D5EC",
      color: "white",
      fontWeight: 700,
      textTransform: "none",
      height: "50px",
      width: "100%",
      // maxWidth: "360px",
      marginTop: "25px",
      marginBottom: "20px",
      border: "none",
      fontSize: "16px",
      fontFamily: "Montserrat",
    },
    "& .saveButton": {
      background: "#0074BF",
      color: "#ffffff",
      borderRadius: "20px",
      textTransform: "none",
      fontFamily: "Montserrat",
      fontWeight: 700,
      fontSize: "16px",
      height: "50px",
      padding: "0px",
      opacity: "30%",
    },
  },

  "@media (max-width: 900px)": {
    maxWidth: "clamp(300px, 90vw, 800px)",
  },
  "@media (max-width: 600px)": {
    // maxWidth: "100%",
    // height:"100%",
    "& .form": {
      maxWidth: "100%",
      minWidth:"290px",
    }
  }

});

const StyledTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    height: "50px",
    borderRadius: "20px",
    "& fieldset": {
      borderColor: "#CBD5E1",
    },
    "&:hover fieldset": {
      borderColor: "#CBD5E1",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#0074BF",
    },
    "& input": {
      padding: "0 14px",
      height: "100%",
      boxSizing: "border-box",
      fontFamily: "Montserrat",
      fontWeight: 400,
    },
  },
});

const webStyle = {
  headerContainer: {
    display: "flex",
    height: "60px",
    padding: "10px 50px 10px 50px",
    alignItems: "center",
    background: "#ffffff",
  },
  requirementText: {
    fontSize: '14px',
    color: '#333',
  },
  requirementItem: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '5px',
  },
  passwordRequirements: {
    marginTop: '10px',
  },
  headerText: {
    fontFamily: "Montserrat",
    fontWeight: 700,
    fontSize: "22px",
    color: "#0074BF",
  },
};
// Customizable Area End
