import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { toast } from "react-toastify";
import { cross, greenTick } from "./assets";
export interface IAttribute {
  name: string;
  title: string;
  field_type: string;
  is_enable: boolean;
  is_required: boolean;
  value: string | number;
}

export interface IAttributeValues {
  name: string;
  value: string;
}

type FieldType =
  | "string"
  | "file"
  | "textarea"
  | "text"
  | "date"
  | "datetime"
  | "integer"
  | "number"
  | "boolean"
  | "float"
  | "checkbox";
type FieldValue = string | number | boolean | Date | null;
export interface IField {
  name: string;
  title: string;
  field_type: FieldType;
  is_enable: boolean;
  is_required: boolean;
}

interface IProfileDataWeb {
  attributes?: { user_profile_data: { [key: string]: FieldValue } };
}

export interface IProfileData {
  [key: string]: FieldValue;
}

export interface IProfileValues {
  value: string;
}

interface ErrorToken {
  token: string;
}
interface ErrorToken {
  account: string;
}

interface ErrorResponse {
  errors: ErrorToken[];
}

export interface ProfileResponseData {
  user_name: string;
  dms: string;
  full_phone_number: string;
  email: string;
  ftn : string;
}

interface Account {
  user_name: string;
  email: string;
  full_phone_number: string;
  dms?: string;
  id: number;
  country_code: number;
  phone_number: number;
  first_name: string;
  last_name: string;
  activated: boolean;
  device_id?: string | null;
  unique_auth_id: string;
  password_digest: string;
  created_at: string;
  updated_at: string;
  platform?: string | null;
  user_type?: string | null;
  app_language_id?: number | null;
  last_visit_at?: string | null;
  is_blacklisted: boolean;
  suspend_until?: string | null;
  status: string;
  role_id: number;
  gender?: string | null;
  date_of_birth: string;
  age?: number | null;
  ftn?: string | null;
}

interface AccountUpdateResponse {
  message: string;
  account: Account;
}

interface Errors {
  user_name?: string;
  dms?: string;
  ftn?: string;
  full_phone_number?: string;
  email?: string;
}
export interface IAirport {
  id: number;
  service_subcategory_id: number;
  service_subcategory_name: string
}

export interface Option {
  id: string;
  name: string;
}

export interface IDesignations {
  id: number;
  service_subcategory_id: number;
  service_subcategory_name: string
}

type AlertType = 'success' | 'error' | 'warning' | 'info';

interface AircraftTypeItem {
  id: string;
  value: string;
}

interface TermAndConditionResponse {
  data: {
    attributes: {
      description: string
    }
  }
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  designation: {
    exam_cost: string,
    accepted_payment_methods: string,
    instructions: string
  };
  isEdited: boolean;
  pdfFiles: any, // Existing uploaded PDF files
  newPdfFiles: any,
  profile: { [key: string]: FieldValue };
  fields: IField[];
  currentProfile?: { [key: string]: FieldValue };
  profileImageUrl: string;
  saveEnabled?: boolean;
  cancelEnabled?: boolean;

  txtInputValue: string;
  txtSavedValue: string;

  first_name: string;
  lastName: string;
  fullName: string;
  email: string;
  phoneNumber: string;
  city: string;
  address: string;
  attributesValues: IProfileValues[];
  countryOpen: boolean;
  postalCode: string;
  profilePicture: string;
  profileRole: string;
  aboutMe: string;
  gender: string;
  user_name: string;
  dateOfBirth: string;
  currentPassword: string;
  newPassword: string;
  reNewPassword: string;
  instagram: string;
  facebook: string;
  youtube: string;
  qrCode: string;
  profileId: string;
  user_type: string;
  imageModalVisible: boolean;
  userAge: number;
  biography: string;
  video: string;
  attributes: IAttribute[];

  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  enableNewPasswordField: boolean;

  edtEmailEnabled: boolean;
  llDoChangePwdContainerVisible: boolean;
  llChangePwdDummyShowContainerVisible: boolean;
  isDatePickerVisible: boolean;

  edtMobileNoEnabled: boolean;
  countryCodeEnabled: boolean;

  saveButtonDisable: boolean;
  enableField: boolean;
  role:string|null;
  profileResponse : ProfileResponseData;
  designationRes:any;
  aircraftRes:any;
  testSe:any;
  designationRes1:any;
  isEditable: boolean;
  sortName: string;
  errors : Errors;
  airportOptions: Option[];
  aircraftOptions:any;
  settingsAirports: IAirport[];
  settingsAircrafts: any;
  selectedIds: any[];
  selectedIds1: any[];
  inputValue: string;
  inputValue1: string;
  isOpen: boolean;
  isOpen1:boolean;
  isEditAirportSettingsOpen: boolean;
  deleteAirportDialogOpen: boolean;
  deleteAirportDialogOpen1: boolean;
  deleteTestDialogOpen: boolean;

  selectedAirportToDelete: IAirport;
  selectedAircraftToDelete: IAirport;
  isFocused: boolean,
  isEditTestsSettingsOpen: boolean;
  testSettingsDesignations: IDesignations[];
  isTestSpecificationOpen: boolean;
  gradeOfCirtificates: string[];
  category: string[];
  classes: string[];
  gradeOptions: Option[];
  categoryOptions: Option[];
  classOptions: Option[];
  addedAirCraftTypes: AircraftTypeItem[];
  currentSelectedItems: any;
  openItem: boolean;
  checked: boolean;
  alertType: AlertType;
  alertMsg: string;
  isAlert: boolean;
  isSaveTestSettingsEnabled: boolean;
  isDeleteWorning: boolean;
  isOutStandingModal: boolean;
  isTermServices: boolean;
  isPrivacyPolicy: boolean;
  termAndCondition: string;
  conditionValue: number;
  deletedTestsForSettings: IDesignations[];
  isRestoreDialogOpen: boolean;
  anchorEl: any; // Stores the anchor element for the menu
  selectedFile: any;
  isRenaming: any; // Track which file is being renamed
    tempFileName: any;
  step3Form: {
    designation: string[];
    examCost: string;
    paymentMethods: string;
    instructions: string;
    uploadedFiles: File[];
    designationOption: Option[],
    uploadedData: any
    currentdata:{
    designation: string[];
    examCost: string;
    paymentMethods: string;
    instructions: string;
    uploadedFiles: File[];
    }
},
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CustomisableUserProfilesController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  private aircraftApiTimeout: ReturnType<typeof setTimeout> | null = null;

  getCustomizableProfileCallId: string = "";
  getCustomizableProfileFieldsCallId: string = "";
  updateCustomizableProfileCallId: string = "";
  getProfileAccountID: string = "";
  updateProfileID: string = "";
  getAttributesID: string = "";
  token: string = "";
  intervalId: number = 0;

  labelEmail: string = "";

  btnTextCancelPasswordChange: string = "";
  btnTextSaveChanges: string = "";
  labelHeader: string = "";
  btnTextChangePassword: string = "";

  apiCallMessageUpdateProfileRequestId: string = "";
  validationApiCallId: string = "";
  apiChangePhoneValidation: string = "";
  registrationAndLoginType: string = "";
  authToken: string = "";
  uniqueSessionRequesterId: string = "";
  userProfileGetApiCallId: string = "";
  getUserProfileApiCallId: string = "";
  editProfileApiCallId : string = "";
  getAirportsApiCallId : string = "";
  getAircraftsApiCallId : string= "";
  createNewAircraftSettingApiCallId: string = "";
  getSettingInstApiCallId: string = "";
  geteditTesttApiCallId: string = "";
  removeTestApiCallId: string= "";
  getSetCoverApiCallId: string = "";
  getSetFatApiCallId: string ="";
  getSettingInst4ApiCallId: string = "";
  getSettingInst5ApiCallId: string = "";
  getSettingInst6ApiCallId: string = "";
  getSettingjetApiCallId: string = "";
  getAircraftApiCallId : string = "";
  getAllAircraftApiCallId : string = "";
  getAllAirportsApiCallId : string = "";
  postAirportApiCallId : string = "";
  postAircraftApiCallId : string = "";
  deleteAirportApiCallId : string = "";
  deleteAircraftApiCallId : string = "";
  getCertificateApiCallId : string = "";
  getCategoryApiCallId : string = "";
  getClassApiCallId : string = "";
  getAllDesignationsApiCallId : string = "";
  postTestSettingsApiCallId : string = "";
  getTestSpecificationsApiCallId : string = "";
  deleteUserApiCallId: string = "";
  termAndServicesApiCallId: string = "";
  getAllDeletedDesignationsApiCallId : string = "";
  restoreDeletedDesignationsApiCallId : string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      designation: {
        exam_cost: "",
        accepted_payment_methods: "",
        instructions: ""
      },
      isEdited: false,
      pdfFiles: [], // Existing uploaded PDF files
      newPdfFiles: [],
      profile: {},
      profileImageUrl: "",
      fields: [],
      isFocused: false,
      attributes: [],
      attributesValues: [],
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      first_name: "",
      lastName: "",
      fullName: "",
      email: "",
      city: "",
      address: "",
      postalCode: "",
      profilePicture: "",
      profileRole: "jobseeker",
      aboutMe: "",
      gender: "",
      user_name: "",
      dateOfBirth: "",
      biography: "",
      currentPassword: "",
      newPassword: "",
      reNewPassword: "",
      youtube: "",
      instagram: "",
      facebook: "",
      qrCode: "",
      profileId: "",
      countryOpen: false,
      user_type: "",
      imageModalVisible: false,
      userAge: 0,
      phoneNumber: "",
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      enableNewPasswordField: true,
      video: "",
      anchorEl: null, // Stores the anchor element for the menu
      selectedFile: null,
      isRenaming: null, 
    tempFileName: "",
      step3Form: {
        designation: [],
        examCost: "",
        paymentMethods: "",
        instructions: "",
        uploadedFiles: [],
        designationOption: [],
        uploadedData: [],
        currentdata : {designation: [],
            examCost: "",
            paymentMethods: "",
            instructions: "",
            uploadedFiles: [],}
    },
      edtEmailEnabled: true,
      llDoChangePwdContainerVisible: false,
      llChangePwdDummyShowContainerVisible: false,
      isDatePickerVisible: false,

      edtMobileNoEnabled: true,
      countryCodeEnabled: true,
      saveButtonDisable: false,
      role:"",
      designationRes:[],
      aircraftRes:[],
      testSe:[],
      designationRes1:[],
      profileResponse:{
        user_name: "",
        dms: "",
        full_phone_number : "",
        email: "",
        ftn : ""
    },
    isEditable: false,
    sortName: "",
    errors: {},
    airportOptions:[],
    aircraftOptions:[],
    settingsAirports: [],
    settingsAircrafts: [],
    inputValue: "",
    inputValue1: "",
    selectedIds: [],
    selectedIds1: [],
    isOpen: false,
    isOpen1: false,
    isEditAirportSettingsOpen: false,
    deleteAirportDialogOpen: false,
    deleteAirportDialogOpen1: false,
    deleteTestDialogOpen: false,
    selectedAircraftToDelete: { id: 0, service_subcategory_id: 0, service_subcategory_name:""},
    selectedAirportToDelete: { id: 0, service_subcategory_id: 0, service_subcategory_name:""},
    isEditTestsSettingsOpen: false,
    testSettingsDesignations: [],
    isTestSpecificationOpen: false,
    gradeOfCirtificates: [],
    category: [],
    classes: [],
    gradeOptions: [],
    categoryOptions: [],
    classOptions: [],
    addedAirCraftTypes: [],
    currentSelectedItems: null,
    openItem: false,
    checked: false,
    alertType: 'success'  as AlertType,
    alertMsg: '',
    isAlert: false,
    isSaveTestSettingsEnabled: false,
    isDeleteWorning: false,
    isOutStandingModal: false,
    isTermServices: false,
    isPrivacyPolicy: false,
    termAndCondition : "",
    conditionValue: 0, 
    deletedTestsForSettings: [],
    isRestoreDialogOpen: false,
      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    // Customizable Area Start
    this.getRole();
    this.getUserProfileApi()
    this.getAirportsListApi()
    this.getAirlastListApi()
    this.getAllAirportsListApi()
    this.getAllAircrafttsListApi()
    this.checkIsEditViewOpen()
    this.checkIsEditTestViewOpen()
    this.getCertificateApi()
    this.getCategoryApi()
    this.getAirPortClassApi()
    this.getAllDesignationsApi()
    this.getTestSpecificationsApi()
    this.getAllDeletedDesignationsApi()
    this.getSettingInst4()
    this.getSettingInst6()
    this.getSettingInst5()
    // Customizable Area End
  }

  checkIsEditViewOpen(){
    const isEditView = localStorage.getItem("isEditAirportView") || localStorage.getItem("isEditAirportViewForEdit")
    this.setState({isEditAirportSettingsOpen: isEditView === "true"})
  }

  checkIsEditTestViewOpen(){
    const isEditView = localStorage.getItem("isEditTestView") || localStorage.getItem("isEditTestViewForEdit")
    this.setState({isEditTestsSettingsOpen: isEditView === "true"})
  }

  getToken = () => {
    const message: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(message);
  };

  onCustomizableProfileData = (data: IProfileDataWeb | null) => {
    if (data === null) return this.setState({ currentProfile: { ...this.state.profile } });
    if (!data) return;
    if (!data.attributes || !data.attributes.user_profile_data)
      return this.setState({ currentProfile: { ...this.state.profile } });

    const newProfile = data.attributes.user_profile_data;
    this.setState({ profile: newProfile, currentProfile: { ...newProfile } });
  };

  checkSession = (message: Message) => {
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      if (
        !message.getData(getName(MessageEnum.SessionResponseToken)) ||
        message.getData(getName(MessageEnum.SessionResponseToken)) === "null"
      )
        this.token =
          "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6NDY3LCJleHAiOjE2NzkwNDI3NTAsInRva2VuX3R5cGUiOiJsb2dpbiJ9.zi-zzVPBT-4TQm8cQK8uK6qsoDqTIL_rT-LZX1sptxseOUYhWeHtibNbbmdRUJTSz2THXUUuNlBfkIH6QCFGgg";
      else this.token = message.getData(getName(MessageEnum.SessionResponseToken));

      const testToken = window.localStorage.getItem("testToken");
      if (testToken) this.token = testToken;
      runEngine.debugLog("TOKEN1", this.token);
      this.getCustomizableProfile();
      this.getCustomizableProfileFields();
      return true;
    }
  };

  checkProfileAccount = (message: Message) => {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (apiRequestCallId === this.getProfileAccountID) {
      if (!responseJson.data || !responseJson.data.attributes) return;
      const dataResponse = responseJson;
      const userProfile = dataResponse.data.attributes.user_profile_data || {};
      const about_me =
        dataResponse.data.attributes.profile_bio &&
        dataResponse.data.attributes.profile_bio.about_me;
      const qr_code =
        dataResponse.data.attributes.qr_code && dataResponse.data.attributes.qr_code.qr_code;
      this.setState({
        address: dataResponse.data.attributes.address,
        user_name: userProfile.user_name,
        aboutMe: about_me,
        qrCode: qr_code,
        instagram: dataResponse.data.attributes.instagram,
        city: dataResponse.data.attributes.city,
        postalCode: dataResponse.data.attributes.postal_code,
        fullName: dataResponse.data.attributes.name,
        first_name: dataResponse.data.attributes.first_name,
        lastName: dataResponse.data.attributes.last_name,
        profilePicture: userProfile.profile_pic,
        user_type: userProfile.user_type,
        dateOfBirth: dataResponse.data.attributes.dob,
        video: dataResponse.data.attributes.video,
        youtube: dataResponse.data.attributes.youtube,
        facebook: dataResponse.data.attributes.facebook,
        biography: dataResponse.data.attributes.bio,
        attributesValues: Object.keys(dataResponse.data.attributes.user_profile_data).map(
          (item) => {
            return {
              ...this.state.attributes[(item as unknown) as number],
              value: dataResponse.data.attributes.user_profile_data[item]
            };
          }
        )
      });
      this.setState({
        attributes: this.state.attributesValues.map((item, index) => {
          return {
            ...this.state.attributes[index],
            value: item.value
          };
        })
      });
      return true;
    }
  };

  getLabelFromId(id: string, options: Option[]): string {
    const option = options.find(opt => opt.id === id);
    return option ? option.name : '';
  }

  generateCombinations() {
    const { gradeOfCirtificates, category, classes: classType, 
        gradeOptions, classOptions, categoryOptions,
    } = this.state;
    let combinations: { id: string; value: string }[] = [];
    let id = 1;
    const selectedGrades = gradeOfCirtificates?.map(id => this.getLabelFromId(id, gradeOptions));
    const selectedCategories = category.map(id => this.getLabelFromId(id, categoryOptions));
    const selectedClasses = classType.map(id => this.getLabelFromId(id, classOptions));
    selectedGrades.forEach(grade => {
        selectedCategories.forEach(category => {
            selectedClasses.forEach(classType => {
                combinations.push({
                    id: id.toString(),
                    value: `${grade} - ${category} - ${classType}`
                });
                id++;
            });
        });
    });
    this.setState({addedAirCraftTypes: combinations })
    return combinations;
}

  onChangeItems = (name: string, value: string[]) => {
    this.setState((state) => ({
      ...state,
      [name]: value,
      isSaveTestSettingsEnabled: true
    }), () => {
      const {gradeOfCirtificates, category, classes } = this.state;
      if (gradeOfCirtificates.length * category.length *
          classes.length > 0) {
          this.setState(state => ({
              ...state,
               addedAirCraftTypes: this.generateCombinations()
          }));
      }else{
          this.setState(state => ({
              ...state,
               addedAirCraftTypes: this.generateCombinations()
          }))
      }
  });
  };

  handleClickTitle(){
    const { isTestSpecificationOpen } = this.state
    if(isTestSpecificationOpen){
      this.setState({ isTestSpecificationOpen : false})
    }
  }

  handleDeleteItemPopup = (name: string, selectedItems: any) => {
    const isSelected = localStorage.getItem("selected")
    if(isSelected === "false") return
    if(this.state.addedAirCraftTypes.length > 0){
    this.setState((prevState) => ({
        ...prevState,
        openItem: true,
        currentSelectedItems: {
            name,
            selectedItems
        }
    }));
    }else{
        this.setState((prevState) => ({
            ...prevState,
            openItem: false,
            currentSelectedItems: {
                name,
                selectedItems
            }
        }),()=> this.handleConfirmDeleteItem());
    }
  };
  

  handleMenuOpen = (event:any, file:any) => {
    this.setState({ anchorEl: event.currentTarget, selectedFile: file });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null, selectedFile: null });
  };
  handlePreviewFile = () => {
    if (this.state.selectedFile) {
      const fileURL = URL.createObjectURL(this.state.selectedFile.file);
      window.open(fileURL, "_blank"); 
    }
  };
  handleRenameFile = (file:any) => {
    this.setState({ isRenaming: file, tempFileName: file.name, anchorEl: null });
  };

  handleRenameChange = (event:any) => {
    this.setState({ tempFileName: event.target.value });
  };

  handleRenameConfirm = () => {
    this.setState((prevState) => ({
      step3Form: {
        ...prevState.step3Form,
        uploadedFiles: prevState.step3Form.uploadedFiles.map((file) =>
          file === prevState.isRenaming ? { ...file, name: prevState.tempFileName } : file
        ),
      },
      isRenaming: null,
      tempFileName: "",
    }));
  };

  handleRenameCancel = () => {
    this.setState({ isRenaming: null, tempFileName: "" });
  };


  
  handleConfirmDeleteItem = () => {
    if (this.state?.currentSelectedItems) {
        this.onChangeItems(this.state?.currentSelectedItems?.name, this.state?.currentSelectedItems.selectedItems);
    }
    this.handleCloseDialog()
  }

  handleCloseDialog = () => {
    this.setState((prevState) => ({
        ...prevState,
        openItem: false,
        currentSelectedItems: null
    }));
  }

  handleRestoreCloseDialog = () => {
    this.setState((prevState) => ({
        ...prevState,
        isRestoreDialogOpen: false,
        currentSelectedItems: null
    }));
  }

  handleConfirmRestore = () => {
    this.restoreDeletedDesignationsApi(this.state.currentSelectedItems.id)
  }

  navigateToDeletedTests = () => {
    this.props.navigation.navigate("SettingsDeletedTests")
  }
  getNavigationlandingPage = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'SettingsDeletedTests'
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
     
    });
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }
  navigateToSettingsTests = () => {
    this.props.navigation.navigate("SettingsTests")
  }

  navigateToTest = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'SettingsTests'
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
     
    });
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }
  

  handleDeleteFile = () => {
    this.setState((prevState) => ({
      step3Form: {
        ...prevState.step3Form,
        uploadedFiles: prevState.step3Form.uploadedFiles.filter(
          (file) => file !== prevState.selectedFile
        ),
      },
      anchorEl: null, // Close menu after deletion
    }));
  };
  
handleFileSelect = (fileList: FileList | null) => {
  if (!fileList) return;

  const newFiles = Array.from(fileList).map((file) => ({
    name: file.name,
    size: `${(file.size / 1024).toFixed(2)} KB`, // Convert size to KB
    file, // Store actual file object
  }));

  this.setState((prevState) => ({
    step3Form: {
      ...prevState.step3Form, // ✅ Preserve existing step3Form state
      uploadedFiles: [...(prevState.step3Form.uploadedFiles || []), ...newFiles], // ✅ Preserve existing files
    } as typeof prevState.step3Form, // ✅ Ensures correct type inference
  }));
};



  checkOtherResponses = (message: Message) => {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    if (apiRequestCallId === this.getCustomizableProfileCallId) {
      this.onCustomizableProfileData(responseJson.data);
    } else if (apiRequestCallId === this.getCustomizableProfileFieldsCallId) {
      if (!responseJson.data) return;
      const fields = responseJson.data;
      this.setState({ fields });
    } else if (apiRequestCallId === this.updateCustomizableProfileCallId) {
      this.setState({
        currentProfile: { ...this.state.profile },
        saveEnabled: false,
        cancelEnabled: false
      });
    } else if (apiRequestCallId === this.updateProfileID) {
      this.fetchProfileData();
      alert("Profile Updated Successfully");
    } else if (apiRequestCallId === this.getAttributesID) {
      this.fetchProfileData();
      this.setState({
        attributes: responseJson.data.map((item: object) => {
          return {
            ...item,
            value: ""
          };
        })
      });
    }
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (this.checkSession(message)) return;
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) return;

    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    if (!responseJson) return;

    const isSuccess = !(responseJson?.errors || responseJson?.error);
    this.handleResponse(apiRequestCallId, responseJson, isSuccess);
    this.handleResponse1(apiRequestCallId, responseJson, isSuccess);
    // Customizable Area End
  }

  // Customizable Area Start

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    const token = localStorage.getItem("accessToken");
     if (!token) {
      this.userAuth("Login")
     }
    }

    userAuth = (name:string) => {
      const message: Message = new Message(getName(MessageEnum.NavigationMessage))
      message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      name
      );
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
      this.send(message);
      }


      handleNoResultsClick = () => {
        const { inputValue1, settingsAircrafts } = this.state;
      
        if (inputValue1.trim()) {
          const newAircraft = {
            service_subcategory_id: Date.now(), // Unique ID
            service_subcategory_name: inputValue1.trim(), // Ensure clean input
          };
      
          this.setState((prevState) => ({
            settingsAircrafts: [...prevState.settingsAircrafts, newAircraft], // Add to list
            inputValue1: "", // Clear input
            isOpen1: false, // Close dropdown
          }));
      
          // Now, trigger the API call only here when the user explicitly clicks "Add"
          this.createNewAircraft(inputValue1);
        }
      };
      
      
  handleResponse(apiRequestCallId: string, responseJson: any, isSuccess: boolean) {

    if (isSuccess) {
        this.responseSucessCell(apiRequestCallId, responseJson);
        this.handleSuccess(apiRequestCallId, responseJson);
        if (apiRequestCallId === this.getSettingInstApiCallId) {
          this.setState({ designationRes: responseJson }, () => {
          });}

          if (apiRequestCallId === this.geteditTesttApiCallId) {
            this.setState({ testSe: responseJson }, () => {
              toast.success("Updated Successfully!");            
            });}

            if (apiRequestCallId === this.createNewAircraftSettingApiCallId) {
              this.setState({ aircraftRes: responseJson }, () => {
                              window.location.reload();

              });}
              if (apiRequestCallId === this.removeTestApiCallId) {
                if(responseJson){

                  window.location.reload();
                }
  
              }
    } else {
        this.responseFailureCell(apiRequestCallId, responseJson);
        this.handleFailure(apiRequestCallId, responseJson);
  }}

  handleResponse1(apiRequestCallId: string, responseJson: any, isSuccess: boolean) {
    if (isSuccess) {
        this.responseSucessCell(apiRequestCallId, responseJson);
        this.handleSuccess2(apiRequestCallId, responseJson);
    } else {
        this.responseFailureCell(apiRequestCallId, responseJson);
        this.handleFailure1(apiRequestCallId, responseJson);
  }}



  handleSuccess(apiRequestCallId: string, responseJson: any) {
    const successHandlers = {
        [this.getAirportsApiCallId]: () => this.getAirportsSucessCallBack(responseJson),
        [this.getAllAirportsApiCallId]: () => this.getAllAirportsSucessCallBack(responseJson),
        [this.postAirportApiCallId]: () => this.getAirportsListApi(),
        [this.deleteAirportApiCallId]: () => this.getAirportsListApi(),
        [this.getCertificateApiCallId]: () => this.getCertificateSucessCallBack(responseJson),
        [this.getCategoryApiCallId]: () => this.getCategorySucessCallBack(responseJson),
        [this.getClassApiCallId]: () => this.getAirPortClassSucessCallBack(responseJson),
        [this.getAllDesignationsApiCallId]: () => this.getAllDesignationsSucessCallBack(responseJson),
        [this.postTestSettingsApiCallId]: () => this.postTestSettingsSucessCallBack(responseJson),
        [this.getTestSpecificationsApiCallId]: () => this.getTestSpecificationsSucessCallBack(responseJson),
        [this.getAllDeletedDesignationsApiCallId]: () => this.getAllDeletedDesignationsSucessCallBack(responseJson),
        [this.restoreDeletedDesignationsApiCallId]: () => this.restoreDeletedDesignationsSucessCallBack(responseJson)
    };

    successHandlers[apiRequestCallId]?.();
  }


  handleSuccess2(apiRequestCallId: string, responseJson: any) {
    const successHandlers1 = {
        [this.getAircraftsApiCallId]: () => this.getAircraftsSucessCallBack(responseJson),
        [this.getAllAircraftApiCallId]: () => this.getAllAircraftCallBack (responseJson),
        [this.postAircraftApiCallId]: () => this.getAirlastListApi(),
        [this.deleteAircraftApiCallId]: () => this.getAirlastListApi(),
    };

    successHandlers1[apiRequestCallId]?.();
  }

  async getSettingInst(id:any) {
    const token = localStorage.getItem('accessToken')
  
      const header = {
        "Content-Type": configJSON.searchApiContentType,
        token
      };
  
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  
  this.getSettingInstApiCallId = requestMessage.messageId;
  
  const endpoint = `bx_block_categories/designation_instructions/${id}`;
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    endpoint
  );
  
  const httpHeader = JSON.stringify(header);
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    httpHeader
  );

  
  const method = "GET";
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    method
  );
  
  runEngine.sendMessage(requestMessage.id, requestMessage);
    }


    async editTest(id: any) {
      const token = localStorage.getItem("accessToken");
      const { designationRes, pdfFiles, newPdfFiles } = this.state;
    
    
      const formData = new FormData();
    
      // ✅ Append Existing PDF Files
      pdfFiles.forEach((file: File) => {
        formData.append("pdf_files[]", file);
      });
    
      // ✅ Append New PDF Files
      newPdfFiles.forEach((file: File) => {
        formData.append("new_pdf_files[]", file);
      });
    
      // ✅ Append Designation Instruction Fields (Check for null values)
      formData.append("designation_instruction[exam_cost]", designationRes.exam_cost ?? ""); // Ensure it's not null
      formData.append("designation_instruction[accepted_payment_methods]", designationRes.accepted_payment_methods ?? "");
      formData.append("designation_instruction[instructions]", designationRes.instructions ?? "");
    
      console.log("FormData Sent:", [...formData.entries()]); // Debugging
    
      // ✅ Set headers (No `Content-Type` because FormData sets it automatically)
      const headers = {
        token,
      };
    
      const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
      this.geteditTesttApiCallId = requestMessage.messageId;
    
      // ✅ Correct API Endpoint
      const endpoint = `bx_block_categories/designation_instructions/update_designation_instruction/${id}`;
    
      requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
      requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
      requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PUT");
      requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
    
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    
    



    async getSettingInst4() {
      const token = localStorage.getItem('accessToken')
    
        const header = {
          "Content-Type": configJSON.searchApiContentType,
          token
        };
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    this.getSettingInst4ApiCallId = requestMessage.messageId;
    
    const endpoint = `bx_block_categories/designation_instructions`;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );
    
    const httpHeader = JSON.stringify(header);
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      httpHeader
    );
  
    
    const method = "GET";
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    
    runEngine.sendMessage(requestMessage.id, requestMessage);
      }





    async getSettingInst5() {
      const token = localStorage.getItem('accessToken')
    
        const header = {
          "Content-Type": configJSON.searchApiContentType,
          token
        };
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    this.getSettingInst5ApiCallId = requestMessage.messageId;
    
    const endpoint = `bx_block_categories/harachi`;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );
    
    const httpHeader = JSON.stringify(header);
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      httpHeader
    );
  
    
    const method = "GET";
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    
    runEngine.sendMessage(requestMessage.id, requestMessage);
      }

      async getSettingInst6() {
        const token = localStorage.getItem('accessToken')
      
          const header = {
            "Content-Type": configJSON.searchApiContentType,
            token
          };
      
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      
      this.getSettingInst6ApiCallId = requestMessage.messageId;
      
      const endpoint = `bx_block_categories/harachisq`;
      
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        endpoint
      );
      
      const httpHeader = JSON.stringify(header);
      
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        httpHeader
      );
    
      
      const method = "GET";
      
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        method
      );
      
      runEngine.sendMessage(requestMessage.id, requestMessage);
        }

async createNewAircraft(name: string) {
  const token = localStorage.getItem('accessToken')

    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token
    };

const data = {
  category_name: "Aircraft",
  subcategory_name: name,
};


const requestMessage = new Message(
  getName(MessageEnum.RestAPIRequestMessage)
);

this.createNewAircraftSettingApiCallId = requestMessage.messageId;

const endpoint = "bx_block_automatedprioritisation/add_subcategory_by_dpe";

requestMessage.addData(
  getName(MessageEnum.RestAPIResponceEndPointMessage),
  endpoint
);

const httpHeader = JSON.stringify(header);

requestMessage.addData(
  getName(MessageEnum.RestAPIRequestHeaderMessage),
  httpHeader
);

const httpData = JSON.stringify(data);

requestMessage.addData(
  getName(MessageEnum.RestAPIRequestBodyMessage),
  httpData
);

const method = "POST";

requestMessage.addData(
  getName(MessageEnum.RestAPIRequestMethodMessage),
  method
);

runEngine.sendMessage(requestMessage.id, requestMessage);
  }



  handleFailure1(apiRequestCallId: string, responseJson: any) {
    const failureHandlers1 = {
        [this.getAircraftsApiCallId]: () => this.getAircraftFailureCallBack(responseJson),
        [this.getAllAircraftApiCallId]: () => this.getAllAircraftFailureCallBack(responseJson),
        [this.postAircraftApiCallId]: () => this.postAircraftsFailureCallBack(responseJson),
        [this.deleteAircraftApiCallId]: () => this.deleteAircraftsFailureCallBack(responseJson),
    };

    failureHandlers1[apiRequestCallId]?.();
  }


  handleFailure(apiRequestCallId: string, responseJson: any) {
    const failureHandlers = {
        [this.getAirportsApiCallId]: () => this.getAirportsFailureCallBack(responseJson),
        [this.getAllAirportsApiCallId]: () => this.getAllAirportsFailureCallBack(responseJson),
        [this.postAirportApiCallId]: () => this.postAirportsFailureCallBack(responseJson),
        [this.deleteAirportApiCallId]: () => this.deleteAirportsFailureCallBack(responseJson),
        [this.getCertificateApiCallId]: () => this.getCertificateFailureCallBack(responseJson),
        [this.getCategoryApiCallId]: () => this.getCategoryFailureCallBack(responseJson),
        [this.getClassApiCallId]: () => this.getAirPortClassFailureCallBack(responseJson),
        [this.getAllDesignationsApiCallId]: () => this.getAllDesignationsFailureCallBack(responseJson),
        [this.postTestSettingsApiCallId]: () => this.postTestSettingsFailureCallBack(responseJson),
        [this.getTestSpecificationsApiCallId]: () => this.getTestSpecificationsFailureCallBack(responseJson),
        [this.getAllDeletedDesignationsApiCallId]: () => this.getAllDeletedDesignationsFailureCallBack(responseJson),
        [this.restoreDeletedDesignationsApiCallId]: () => this.restoreDeletedDesignationsFailureCallBack(responseJson)
    };

    failureHandlers[apiRequestCallId]?.();
  }
  handleChangeAttributeValue = (value: string, index: number) => {
    const temporaryValue = this.state.attributes;
    temporaryValue[index].value = value;
    this.setState({ attributes: temporaryValue });
  };

  // web events


  changeFormValue = (
    valueKey: string,
    value: string | Date | number | boolean | null,
    fieldType: FieldType
  ) => {
    if (value !== undefined) {
      if (fieldType === "float") value = parseFloat(value as string);
      else if (fieldType === "integer") value = parseInt(value as string);
      else if (fieldType === "date") value = (value as Date).toDateString();
      else if (fieldType === "boolean") value = value === "true";
      else if (fieldType === "checkbox") value = !!value;
    }
    this.setState({ profile: { ...this.state.profile, [valueKey]: value } });
  };

  onKeyPress = (event: React.KeyboardEvent, field: IField) => {
    if (field.field_type === "integer" && (event.key === "." || event.key === ",")) {
      event.preventDefault();
    }
  };

  getCustomizableProfileFields = () => {
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: this.token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getCustomizableProfileFieldsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCustomizableProfileFieldsEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getCustomizableProfile = () => {
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: this.token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getCustomizableProfileCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCustomizableProfileEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  fetchProfileData = async () => {
    const header = {
      "Content-Type": "application/json",
      token: configJSON.temporaryToken
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getProfileAccountID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseURLGetProfileAccount
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  updateProfile = async () => {
    const header = {
      "Content-Type": "application/json",
      token: configJSON.temporaryToken
    };
    const body ={
      dummyData:"It is a dummy data"
    }
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.updateProfileID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateProfileURL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.putMethod);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getRole = ()=>{
    const role = localStorage.getItem("role");
    this.setState({role})
  }

  validateInput = (field: string, value: string) => {
    let error = "";

    switch (field) {
        case "user_name":
            if (!value) {
                error = "Name is required";
            } else if (!/^[A-Za-z\s]+$/.test(value)) {
                error = "Name should contain alphabetic characters";
            }
            break;

        case "full_phone_number":
            if (!/^\d{3}-\d{3}-\d{4}$/.test(value)) {
                error = "Please enter a valid phone number";
            }
            break;

        case "email":
            if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)) {
                error = "Please enter a valid email address";
            }
            break;

        case "dms":
          if (!value) {
            error = "DMS is a required field";
          }else if (this.state.role === "dpe" && !/^\d{9}$/.test(value.trim())) {
                error = "DMS number must be a 9-digit number";
            }
            break;

        case "ftn":
          if(!value){
            error = "FTN is a required field"
          }else if (this.state.role === "applicant" && !/^[AC]\d{7}$/.test(value.trim())) {
                error = "FTN must start with 'A' or 'C' followed by 7 digits";
            }
            break;

        default:
            break;
    }

    return error;
};

handleInputChange1 = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
  const { name, value } = event.target;

  this.setState((prevState) => ({
    designationRes: {
      ...prevState.designationRes,
      [name]: value,
    },
    isEdited: true, // 🚀 Enable Save button when editing starts
  }));
};



  handleInputChange = (field: string, value: string) => {
    const error = this.validateInput(field, value);
    let formattedValue = value;
    if (field === "full_phone_number") {
        const digits = value.replace(/\D/g, "");
        if (digits.length > 6) {
            formattedValue = `${digits.slice(0, 3)}-${digits.slice(3, 6)}-${digits.slice(6, 10)}`;
        } else if (digits.length > 3) {
            formattedValue = `${digits.slice(0, 3)}-${digits.slice(3)}`;
        } else {
            formattedValue = digits;
        }
    }
    this.setState((prevState) => (
      {
        profileResponse: {
            ...prevState.profileResponse,
            [field]: formattedValue,
        },
        errors: {
          ...prevState.errors,
          [field]: error,
      },
        isEditable: true, 
    }));
}


  userProfileApiCall = async (valueData: {
    contentType?: string;
    method?: string;
    endPoint?: string;
    body?: {};
    apiType?: string;
    type?: string;
  }) => {
    const token = await (localStorage.getItem('accessToken'))
    let { contentType, method, endPoint, body, apiType } = valueData;
    let header = {
      "Content-Type": contentType,
      Token: token
    };
    let userProfileRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    userProfileRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    userProfileRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    body &&
      userProfileRequestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        apiType === "" ? JSON.stringify(body) : body
      );
    userProfileRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(userProfileRequestMessage.id, userProfileRequestMessage);
    return userProfileRequestMessage.messageId;
  };

  responseSucessCell = async (apiRequestCallId: string, responseJson: ProfileResponseData & AccountUpdateResponse & TermAndConditionResponse) => {
    if (apiRequestCallId === this.getUserProfileApiCallId) {
      this.getUserProfileSucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.editProfileApiCallId) {
      this.verifyOtpSucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.deleteUserApiCallId) {
      this.deleteUserSucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.termAndServicesApiCallId) {
      this.termAndServicesSucessCallBack(responseJson);
    }
  };

  responseFailureCell = async (apiRequestCallId: string, responseJson: ErrorResponse) => {
    if (apiRequestCallId === this.getUserProfileApiCallId) {
      this.getUserProfileFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.editProfileApiCallId) {
      this.verifyOtpFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.deleteUserApiCallId) {
      this.deleteUserFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.termAndServicesApiCallId) {
      this.termAndServicesFailureCallBack(responseJson);
    }
  };

  getUserProfileApi = async () => {
    this.getUserProfileApiCallId = await this.userProfileApiCall({
      contentType: configJSON.searchApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: configJSON.getuserProfileEndPoint,
    })
  };

  handleRemoveAirport(id: number){
    this.removeAirportApi(id)
  }
  handleRemoveAircraft(id: number){
    this.removeAircraftApi(id)
  }

  handleRemoveTest(id: number){
    this.removeTest(id)
  }


  postAirportApi = async () => {
    const body = { service_subcategory_id: this.state.selectedIds?.[0] || null };

    this.postAirportApiCallId = await this.userProfileApiCall({
      contentType: configJSON.searchApiContentType,
      method: configJSON.httpPostMethod,
      endPoint: configJSON.getAirportsEndPoint,
      body: JSON.stringify(body),
    });
  };

  postAircraftApi = async () => {
    const body = { service_subcategory_id: this.state.selectedIds1?.[0] || null };
  
    this.postAircraftApiCallId = await this.userProfileApiCall({
      contentType: configJSON.searchApiContentType,
      method: configJSON.httpPostMethod,
      endPoint: configJSON.postAircraftsEndPoint,
      body: JSON.stringify(body),
    });
  };
  
  removeAirportApi = async (id: number) => {
    this.deleteAirportApiCallId = await this.userProfileApiCall({
      contentType: configJSON.searchApiContentType,
      method: configJSON.httpDeleteMethod,
      endPoint: `${configJSON.getAirportsEndPoint}/${id}`,
    });
  };
  removeAircraftApi = async (id: number) => {
    this.deleteAircraftApiCallId = await this.userProfileApiCall({
      contentType: configJSON.searchApiContentType,
      method: configJSON.httpDeleteMethod,
      endPoint: `${configJSON.getAircrafshowList}/${id}`,
    });
  };

  async removeTest(id:any) {
    const token = localStorage.getItem('accessToken')
  
      const header = {
        "Content-Type": configJSON.searchApiContentType,
        token
      };
  
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  
  this.removeTestApiCallId = requestMessage.messageId;
  
  const endpoint = `bx_block_categories/designation_instructions/mark_designation_as_deleted/${id}`;
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    endpoint
  );
  
  const httpHeader = JSON.stringify(header);
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    httpHeader
  );

  
  const method = "PATCH";
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    method
  );
  
  runEngine.sendMessage(requestMessage.id, requestMessage);
  this.getNavigationlandingPage()
    }
  getAirportsListApi = async () => {
    this.getAirportsApiCallId = await this.userProfileApiCall({
      contentType: configJSON.searchApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: configJSON.getAirportsEndPoint,
    })
  };


  getAirlastListApi = async () => {
    this.getAircraftsApiCallId = await this.userProfileApiCall({
      contentType: configJSON.searchApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: configJSON.getAircrafshowList,
    })
  };
  getAllAirportsListApi = async () => {
    this.getAllAirportsApiCallId = await this.userProfileApiCall({
      contentType: configJSON.searchApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: configJSON.getAllAirportsEndPoint,
    })
  };


  getAllAircrafttsListApi = async () => {
    this.getAllAircraftApiCallId = await this.userProfileApiCall({
      contentType: configJSON.searchApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: configJSON.getAllAircraftsEndPoint,
    })}
  handleCheckedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({ checked: event.target.checked });
  };

  handleChange = (event: any, value: Option[]) => {
      const selectedIds: string[] = value.map(item => item?.id);
      this.setState({ selectedIds: selectedIds })
      setTimeout(() => {
        this.postAirportApi()
      },1000)
  };


  handleChange1 = (event: any, value: Option[]) => {
    const selectedIds1: string[] = value.map(item => item?.id);
    this.setState({ selectedIds1: selectedIds1 })
    setTimeout(() => {
      this.postAircraftApi()
    },1000)
};

  handleAirportInputChange = (event: any, value: string) => {
    this.setState({ 
        inputValue: value,
        isOpen: value.length > 0
    });
};
handleAircraftInputChange = (event: any, value: string) => {
  this.setState({ 
    inputValue1: value,
    isOpen1: value.length > 0
  });

  // No API call here, just update the state
};



  getUserProfileSucessCallBack = (response: ProfileResponseData) => {
    const profileData = {
      user_name: response.user_name,
      dms: response.dms,
      full_phone_number: this.formatPhoneNumber(response.full_phone_number),
      email: response.email,
      ftn :response.ftn
        }
        this.setState({ profileResponse: profileData, sortName: response.user_name}, ()=> this.getFirstLetter());
  }

  getAirportsSucessCallBack = (response: IAirport[]) => {
      this.setState({ settingsAirports : response, deleteAirportDialogOpen: false});
  }


  getAircraftsSucessCallBack = (response: IAirport[]) => {
    this.setState({ settingsAircrafts : response, deleteAirportDialogOpen1: false});
}
  getAirportsFailureCallBack = (response: ErrorResponse) => {
    toast.error(response.errors[0].token)
  };
  getAircraftFailureCallBack = (response: ErrorResponse) => {
    toast.error(response.errors[0].token)
  };

  getAllAirportsSucessCallBack = (response: Option[]) => {
    this.setState({ airportOptions : response});
  }


  getAllAircraftCallBack = (response: Option[]) => {
    this.setState({ aircraftOptions : response});
  }

  getAllAirportsFailureCallBack = (response: ErrorResponse) => {
    toast.error(response.errors[0].token)
  };



  getAllAircraftFailureCallBack = (response: ErrorResponse) => {
    toast.error(response.errors[0].token)
  };

  postAirportsFailureCallBack = (response: ErrorResponse) => {
    toast.error(response.errors[0].token)
  };
  postAircraftsFailureCallBack = (response: ErrorResponse) => {
    toast.error(response.errors[0].token)
  };

  deleteAirportsFailureCallBack = (response: any) => {
    toast.error(response.error)
  };
  deleteAircraftsFailureCallBack = (response: any) => {
    toast.error(response.error)
  };

  getUserProfileFailureCallBack = (response: ErrorResponse) => {
    toast.error(response.errors[0].token)
  };

  handleBackToCreateAvailability(){
    this.props.navigation.navigate("SchedulingAvailability")
  }

  handleToNext(id: any){
    localStorage.setItem("setId",id)
    this.props.navigation.navigate("TestSetting")

  }
 

  editprofileApi = async () => {
    const body = {
      account: {
        user_name: this.state.profileResponse.user_name,
        email: this.state.profileResponse.email,
        full_phone_number: `+1${this.state.profileResponse.full_phone_number}`,
        dms: this.state.profileResponse.dms,
        ftn: this.state.profileResponse.ftn,
      }
    }

    this.editProfileApiCallId = await this.userProfileApiCall({
      contentType: configJSON.searchApiContentType,
      method: configJSON.httpPutMethod,
      endPoint: configJSON.editProfileEndPoint,
      body: JSON.stringify(body),
    });
  };

  verifyOtpSucessCallBack = (response: AccountUpdateResponse) => {
    if (response) {
      this.getFirstLetter()
      toast.success("Your changes have been saved.")
      this.getUserProfileApi()
    }
  }

  verifyOtpFailureCallBack = (response: ErrorResponse) => {
    if(response.errors[0].token){
      toast.error(response.errors[0].token)
    }else{
      toast.error(response.errors[0].account)
    }
  };

  getFirstLetter = () => {
    const words: string[] = this.state.profileResponse.user_name.split(' ');
    if (words.length === 1) {
      this.setState({sortName: words[0].charAt(0).toUpperCase()}) ;
    }
    const firstInitial: string = words[0].charAt(0).toUpperCase();
    const lastInitial: string = words[words.length - 1].charAt(0).toUpperCase();
    let name = firstInitial + lastInitial;
    this.setState({sortName: name}) ;
  }

  hasErrors = () => {
    return Object.values(this.state.errors).some((error) => error !== "");
};
  
  handleSaveTestSettings(){
    if(this.state.isSaveTestSettingsEnabled){
      return this.postTestSettingsApi()
    }else{
      return undefined
    }
  }

  getCertificateApi = async () => {
    this.getCertificateApiCallId = await this.userProfileApiCall({
        contentType: configJSON.searchApiContentType,
        method: configJSON.httpGetMethod,
        endPoint: configJSON.gradeOfCertificateEndPoint
    })
  };

  getCertificateSucessCallBack = (response: Option) => {
      if (Array.isArray(response) ) {
      const gradeOptions = response.map((item) => ({
          id: item.id.toString(), 
          name: item.name,       
      }));
      this.setState((prevState) => ({
          ...prevState,
          gradeOptions: gradeOptions
      }));
      }
  };

  getCertificateFailureCallBack = (response: ErrorResponse) => {
      toast.error(response.errors[0].token)
  };

  getCategoryApi = async () => {
    this.getCategoryApiCallId = await this.userProfileApiCall({
        contentType: configJSON.searchApiContentType,
        method: configJSON.httpGetMethod,
        endPoint: configJSON.categoryEndPoint
    })
  };

  getCategorySucessCallBack = (response: Option) => {
    if (Array.isArray(response) ) {
    const categoryOptions = response.map((category) => ({
        id: category.id.toString(), 
        name: category.name,       
    }));
    this.setState((prevState) => ({
        ...prevState,
        categoryOptions: categoryOptions, 
    }));
    }
  };

  getCategoryFailureCallBack = (response: ErrorResponse) => {
      toast.error(response.errors[0].token)
  };

  getAirPortClassApi = async () => {
    this.getClassApiCallId = await this.userProfileApiCall({
        contentType: configJSON.searchApiContentType,
        method: configJSON.httpGetMethod,
        endPoint: configJSON.airPortClassEndPoint
    })
  };

  getAirPortClassSucessCallBack = (response: Option) => {
    if (Array.isArray(response) ) {
      const classOptions = response.map((classOptions) => ({
          id: classOptions.id.toString(), 
          name: classOptions.name,       
      }));
      this.setState((prevState) => ({
          ...prevState,
          classOptions: classOptions,
      }));
    }
  };

  getAirPortClassFailureCallBack = (response: ErrorResponse) => {
      toast.error(response.errors[0].token)
  };

  getAllDesignationsFailureCallBack = (response: ErrorResponse) => {
    toast.error(response.errors[0].token)
  };

  getAllDeletedDesignationsFailureCallBack = (response: any) => {
    let err = response.errors?.length > 0 ? response.errors[0]?.token || response.errors[0] : response.message;

    if (response) {
      this.setState({
        ...this.state,
        isAlert: true,
        alertType: "error",
        alertMsg: err,
      })
    }
  };

  restoreDeletedDesignationsFailureCallBack = (response: any) => {
    let err = response.errors?.length > 0 ? response.errors[0]?.token || response.errors[0] : response.message;

    if (response) {
      this.setState({
        ...this.state,
        isAlert: true,
        alertType: "error",
        alertMsg: err,
      })
    }
  };

  getAllDesignationsApi = async () => {
    this.getAllDesignationsApiCallId = await this.userProfileApiCall({
        contentType: configJSON.searchApiContentType,
        method: configJSON.httpGetMethod,
        endPoint: configJSON.getDesignationUrl
    })
  };

  getAllDeletedDesignationsApi = async () => {
    this.getAllDeletedDesignationsApiCallId = await this.userProfileApiCall({
        contentType: configJSON.searchApiContentType,
        method: configJSON.httpGetMethod,
        endPoint: configJSON.getDeletedDesignationUrl
    })
  };

  restoreDeletedDesignationsApi = async (id: number) => {
    this.restoreDeletedDesignationsApiCallId = await this.userProfileApiCall({
        contentType: configJSON.searchApiContentType,
        method: configJSON.httpPatchMethod,
        endPoint: `${configJSON.restoreDeletedDesignationUrl}/${id}`
    })
  };

  getAllDesignationsSucessCallBack = (response: any) => {
    if (Array.isArray(response)) {
      const designations = response.map((des: any) => ({
        id: des.id,
        service_subcategory_id: des.id.toString(), 
        service_subcategory_name: des.designation,       
      }));
      this.setState((prevState) => ({
          ...prevState,
          testSettingsDesignations: designations,
      }));
    }
  };

  getAllDeletedDesignationsSucessCallBack = (response: any) => {
    if (Array.isArray(response)) {
      const designations = response.map((des: any) => ({
        id: des.id,
        service_subcategory_id: des.id.toString(), 
        service_subcategory_name: des.designation,       
      }));
      this.setState((prevState) => ({
          ...prevState,
          deletedTestsForSettings: designations,
      }));
    }
  };

  restoreDeletedDesignationsSucessCallBack = (response: any) => {
    if (response) {
      this.setState({
        ...this.state,
        isAlert: true,
        alertType: "success",
        alertMsg: response.message,
        isRestoreDialogOpen:false
      },() => {
        setTimeout(() => {
          this.getAllDesignationsApi()
          this.props.navigation.navigate("SettingsTests")
        }, 2000)
      });
    }
  };

  postTestSettingsApi = async () => {
    const { classes, gradeOfCirtificates, category} = this.state
    const categoryOptions = category.map((categoryId: any) => ({
      service_subcategory_id: categoryId
    }));
    const classOptions = classes.map((id: any) => ({
      service_subcategory_id : id
    }));
    const cirtificatesOptions = gradeOfCirtificates.map((id: any) => (({
      service_subcategory_id :id
    })));
    const body = {
      grade_of_certificate: cirtificatesOptions,
      category: categoryOptions,
      class: classOptions
    }

    this.postTestSettingsApiCallId = await this.userProfileApiCall({
        contentType: configJSON.searchApiContentType,
        method: configJSON.httpPostMethod,
        endPoint: configJSON.postTestSettingsEndPoint,
        body: JSON.stringify(body),
    })
  };

  postTestSettingsSucessCallBack = (response: any) => {
    if(response && !response.error && !response.errors){
      this.setState({
        ...this.state,
        isAlert: true,
        alertType: "success",
        alertMsg: "Your changes have been saved.",
      },() => {
        setTimeout(() => {
          window.location.reload();
        }, 3000)
      });
    }
  };

  postTestSettingsFailureCallBack = (response: any) => {
    this.setState({
      alertType: "error",
      alertMsg: response.error,
      isAlert: true,
    })
  };

  getTestSpecificationsApi = async () => {
    this.getTestSpecificationsApiCallId = await this.userProfileApiCall({
        contentType: configJSON.searchApiContentType,
        method: configJSON.httpGetMethod,
        endPoint: configJSON.getTestSpecificationsUrl
    })
  };

  getTestSpecificationsSucessCallBack = (response: any) => {
    const { dpe_categories, dpe_classes, grades_of_certificates } = response
    if (Array.isArray(dpe_categories) && Array.isArray(dpe_classes) && Array.isArray(grades_of_certificates)) {
      const categoryOptions = dpe_categories.map((category: any) => (
          category.service_subcategory_id.toString()
      ));
      const classOptions = dpe_classes.map((cls: any) => (
        cls.service_subcategory_id.toString()
      ));
      const cirtificatesOptions = grades_of_certificates.map((cirtificate: any) => (
        cirtificate.service_subcategory_id.toString()
      ));
      this.setState((prevState) => ({
          ...prevState,
          category: categoryOptions, 
          classes: classOptions,
          gradeOfCirtificates: cirtificatesOptions,
      }),() => {
        this.generateCombinations()
      });
    }
  }

  getTestSpecificationsFailureCallBack = (response: ErrorResponse) => {
    toast.error(response.errors[0].token)
  };

  oncloseAlert = () => {
    this.setState({ isAlert: false });
  };

  getAlertIcon() {
    const { alertType } = this.state;
    return (alertType === "success")
      ? greenTick
      : cross;
  }

  formatPhoneNumber = (value: string) => {
    const digits = value.replace(/\D/g, "").slice(-10); // Keep only last 10 digits
    if (digits.length > 6) {
      return `${digits.slice(0, 3)}-${digits.slice(3, 6)}-${digits.slice(6, 10)}`;
    } else if (digits.length > 3) {
      return `${digits.slice(0, 3)}-${digits.slice(3)}`;
    }
    return digits;
  };

  handleDeleteWorningOpenModal = () =>{
    this.setState({isDeleteWorning: true , isOutStandingModal:false})
  };

  handleDeleteWorningCloseModal = () =>{
    this.setState({isDeleteWorning: false})
  };

  handleOutstandingOpenModal = () =>{
    this.setState({isOutStandingModal: true, isDeleteWorning:false})
  };

  handleOutstandingCloseModal = () =>{
    this.setState({isOutStandingModal: false})
  }

  handleDeleteUser = () =>{
    if(this.state.role === "applicant"){
      this.deleteUserApi()
    }else{
      this.handleOutstandingOpenModal()
    }
  } 
  
  navigateScreens = (pathName: string) => {
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), pathName);
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msg);
  };
 
  deleteUserApi = async () => {
    this.deleteUserApiCallId = await this.userProfileApiCall({
      contentType: configJSON.searchApiContentType,
      method: configJSON.httpDeleteMethod,
      endPoint: configJSON.deleteEndPoint,
    })
  };

  deleteUserSucessCallBack = (response: { message: string }) => {
    toast.success(response.message)
    if(this.state.role === "dpe"){
      this.navigateScreens("AccountDeleteProcess")
    }else{
      this.navigateScreens("Login")
    }
    
  };

  deleteUserFailureCallBack = (response: ErrorResponse) => {
    toast.error(response.errors[0].token)
  };


  termAndServicesApi = async (condition: number) => {
    this.termAndServicesApiCallId = await this.userProfileApiCall({
      contentType: configJSON.searchApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: `${configJSON.termAndConditionEndPoint}/${condition}`,
    })
  };

  termAndServicesSucessCallBack = (response: TermAndConditionResponse) => {
    this.setState({termAndCondition: response.data.attributes.description})
    
  };
  termAndServicesFailureCallBack = (response: ErrorResponse) => {
    toast.error(response.errors[0].token)
  };

  handleOpenTermCondition = (condition: number) =>{
    this.setState({isTermServices: true,conditionValue: condition},()=>this.termAndServicesApi(condition))
  }

  handleCloseTermCondition = () =>{
    this.setState({isTermServices: false})
  }

  handleLogoutBtn = () => {
    localStorage.removeItem("role");
    localStorage.removeItem("accessToken");
   this.navigateScreens("Login")
};
  // Customizable Area End
}
