import React from 'react';
import {
    Autocomplete,
    TextField,
    Box,
    Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'; 
import { checked_icon } from '../assets';


// import { cos } from 'react-native-reanimated';
interface Option {
    id: string;
    label: string;
}

interface MultipleSelectWithChipsProps {
    options: Option[];
    placeholder: string;
    selectedItems: string[];
    onItemsChange: (name: string, items: string[]) => void;
    name: string;
    uploadedData: any
}

interface MultipleSelectWithChipsState {
    inputValue: string,
    error: string
}

class MultipleDropDown extends React.Component<MultipleSelectWithChipsProps, MultipleSelectWithChipsState> {
    constructor(props: MultipleSelectWithChipsProps) {
        super(props);
        this.state = {
            inputValue: '',
            error: ''
        };
    }

    handleChange = (event: any, value: Option[]) => {
        const selectedIds: string[] = [value.slice(-1)[0]?.id].filter(Boolean); 
        this.props.onItemsChange(this.props.name, selectedIds); 
        
        this.setState({ error: selectedIds.length === 0 ? "Designation is required" :""});
    };
    handleInputChange = (event: any, value: string) => {
        this.setState({ inputValue: value });
    }

    validateField = () => {
        if (this.props.selectedItems.length === 0) {
            this.setState({ error: "Designation is required" });
        } else {
            this.setState({ error: "" });
        }
    };

    render() {
        const { inputValue, error} = this.state;
        const { selectedItems, options, placeholder, name } = this.props;
        const selectedOptions = options.filter((option: any) => {
            return selectedItems.includes(option.id)
        });

        return (
            <Box >
                <Autocomplete
                   popupIcon={<ExpandMoreIcon sx={{ color: (theme) => theme.palette.grey[500] }} />}
                   clearIcon={null}
                    multiple
                    options={options}
                    getOptionLabel={(option) => option.label}
                    value={selectedOptions}
                    onChange={this.handleChange}
                    disableCloseOnSelect
                    inputValue={inputValue}
                    onBlur={this.validateField} 
                    onInputChange={this.handleInputChange}
                    clearOnBlur={false}
                    noOptionsText={
                        <>
                            <Typography>No results found for '{inputValue}'</Typography>
                            {inputValue && name === 'aircraftTypes' &&
                                (
                                    <Typography
                                        // variant="outlined" 
                                        sx={{
                                            borderTop: '0.01px solid #CBD5E1', padding: '5px 10px 5px 10px',
                                            fontFamily: 'Montserrat, sans-serif',
                                            fontSize: "16px",
                                            fontWeight: 400,
                                            lineHeight: "24px",
                                            textAlign: "left",

                                        }}
                                    >
                                        Add '{inputValue}'
                                    </Typography>
                                )}
                        </>
                    }
                    renderOption={(props, option) => {
                        // const isSelected = selectedItems.includes(option.id);
                        const isInUploadedData = this.props.uploadedData?.some((data: any) =>
                            data.designation?.includes(option.id)
                        );

                        return (
                            <Box
                                component="li"
                                {...props}
                                sx={{
                                    padding: '10px 3px 10px 3px',
                                    display: 'flex',
                                    alignItems: 'centre',
                                    justifyContent: 'space-between',
                                    height: '48px',
                                    width: '100%',
                                }}
                            >
                                <Typography sx={{
                                    fontFamily: 'Montserrat, sans-serif',
                                    fontSize: '16px',
                                    lineHeight: '24px',
                                    padding: '10px 3px 10px 3px',
                                    flexGrow: 1,
                                }}>{option.label}</Typography>

                                {isInUploadedData && (
                                    <img src={checked_icon} alt='checked_icon'/>
                                )}

                            </Box>
                        );
                    }}
                    sx={{
                        '& .MuiInputBase-root': {
                            border: error ? '1px solid red' : '1px solid #CBD5E1',
                            height:"50px",
                            padding:"0px 10px",
                            '&:hover': {
                                border: '2px solid #00AFEF'
                            },
                            '&.Mui-focused': { 
                                border: '2px solid #00AFEF',
                                boxShadow: "none"
                            }
                        },
                        '& .MuiAutocomplete-popupIndicator': {
                            color: "#94A3B8", 
                            },
                        '& .MuiAutocomplete-popupIndicatorOpen': {
                           svg:{
                            color: "#0074BF", 
                           }
                        },
                        "& .Mui-error":{margin:"2px 0px"},
                        width: '100%',
                        height: '50px',
                        padding: '10px 0px 10px 0px',
                        "@media (max-width: 600px)": {
                            width: "100%",
                        }
                    }
                    }
                    renderInput={(params) => {
                        const currentIndex = this.props.options.findIndex(opt => opt.id === this.props.selectedItems[this.props.selectedItems.length - 1]);
                        const nextIndex = currentIndex + 1;
                        return (
                            <Box>
                                <TextField
                                    {...params}
                                    placeholder={selectedOptions.length > 0 ? '' : placeholder} // Use placeholder prop
                                    variant="outlined"
                                    error={!!error}
                                    helperText={error}
                                    InputProps={{
                                        ...params.InputProps,
                                        startAdornment: (
                                            <>
                                                {selectedOptions?.length > 0 ?
                                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                        <Typography sx={{
                                                            fontFamily: 'Montserrat,sans-serif',
                                                            fontSize: '12px',
                                                            fontWeight: 500,
                                                            lineHeight: '24px',
                                                            textAlign: 'left',
                                                            color: '#94A3B8',
                                                            height: '15px',
                                                            width: '15px',
                                                            border: '2px solid #94A3B8',
                                                            borderRadius: '50%',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            justifySelf: 'end !important',
                                                            margin: '0px 4px 0px 0px',
                                                            padding: '2px'
                                                        }}>{nextIndex}</Typography>
                                                        <Typography
                                                            key={selectedOptions[selectedOptions.length - 1].id}
                                                            sx={{
                                                                borderRadius: '12px',
                                                                padding: '4px 8px',
                                                                fontSize: '16px',
                                                                fontFamily: 'Montserrat,sans-serif',
                                                                lineHeight: '24px',

                                                            }}
                                                        >
                                                            {selectedOptions[selectedOptions.length - 1].label}
                                                        </Typography>
                                                    </Box> : null

                                                }

                                            </>

                                        ),
                                        sx: {
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                border: 'none',
                                            },
                                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                                border: 'none',
                                            },
                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                border: 'none',
                                            },
                                            '&::placeholder': {
                                                color: '#94A3B8',
                                                opacity: 1,
                                            },
                                            borderRadius: '20px',
                                        },
                                    }}
                                />
                            </Box>
                        )
                    }}
                />
            </Box>
        );
    }
}
export default MultipleDropDown