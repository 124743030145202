import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { toast } from "react-toastify";
import moment from "moment";
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start

export interface ChatResponse {
  success: boolean;
  message: string;
  data: {
    data: ChatList[];
  };
  error: {
    code: string | null;
    message: string | null;
    details: string | null;
  };
}

export interface ChatList {
  id: string;
  type: string;
  attributes: {
    name: string;
    accounts_chats: AccountChat[];
    messages: ChatMessage;
    chat_user_role: string; 
    unread_time: string;
    unread_count: number;
  };
}

export interface AccountChat {
  id: string;
  type: string;
  attributes: {
    account_id: number;
    muted: boolean;
    unread_count: number;
  };
}

interface ErrorToken {
  token: string;
}

interface ErrorResponse {
  errors: ErrorToken[];
}
export interface IChat {
  id: string;
  muted: boolean;
  unreadCount: number;
  lastMessage: string;
  name: string;
}
interface IChatResponse {
  id: string;
  attributes: {
    name: string;
    accounts_chats: [
      {
        id: string;
        attributes: {
          account_id: number;
          muted: boolean;
          unread_count: number;
        };
      }
    ];
    messages: {
      id: string;
      attributes: { id: number; message: string };
      message: string;
    };
  };
}

interface UserResponse {
  id: number;
  name: string;
  avatar: string;
  lastMessage: string;
  time: string;
};
interface MessageResponse {
  id: number;
  name: string;
  text: string;
  time: string;
  type:string;
}
// chat maessage history
interface ChatMessageResponse {
  success: boolean;
  message: string;
  data: ChatData;
  error: ErrorResponse;
}

interface ChatData {
  data: Chat[];
}

interface Chat {
  id: string;
  type: string;
  attributes: ChatAttributes;
  relationships: ChatRelationships;
}

export interface ChatAttributes {
  id: number;
  name: string;
  chat_user_name: string;
  accounts_chats: AccountChatMessage[];
  messages: ChatMessage[];
}

interface AccountChatMessage {
  id: string;
  type: string;
  attributes: AccountChatAttributes;
}

interface AccountChatAttributes {
  account_id: number;
  muted: boolean;
  unread_count: number;
  unread_time: string;
}

export interface ChatMessage {
  id: string;
  type: string;
  attributes: ChatMessageAttributes;
  currentDate: string;
}

interface ChatMessageAttributes {
  id: number;
  message: string;
  account_id: number;
  chat_id: number;
  created_at: string;
  updated_at: string;
  is_mark_read: boolean;
  read_message: boolean;
  type: string;
}

interface ChatRelationships {
  accounts: AccountRelation[];
}

interface AccountRelation {
  id: string;
  type: string;
}

interface ErrorResponse {
  code: string | null;
  message: string | null
}
// send input message interface
interface ChatMessageAttributes {
  id: number;
  message: string;
  account_id: number;
  chat_id: number;
  created_at: string;
  updated_at: string;
  is_mark_read: boolean;
}

interface ChatMessageData {
  id: string;
  type: string;
  attributes: ChatMessageAttributes;
}

interface ChatSendMessageResponse {
  success: boolean;
  message: string;
  data: {
      data: ChatMessageData;
  };
  error:  null;
}

// Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  accountId: number;
  chatName: string;
  chatList: IChat[];
  isVisibleModal: boolean;
  users: UserResponse[];
  messages: MessageResponse[];
  selectedUser: UserResponse;
  isUserDetails: boolean;
  mobileWidth: boolean;
  screenWidth: number;
  isChatUserList: boolean;
  isChatMessage : boolean;
  isChatUserDetails: boolean;
  chatUserList:ChatList[];
  messageInput: string;
  messageHistory: ChatMessage[],
  chatUserName: string,
  chatUserRole: string,
  chateId: number,
  twilioToken: string
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class ChatController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getChatListApiCallId: string = "";
  createChatRoomApiCallId: string = "";
  getShowChatApiCallId: string = "";
  sendMessageApiCallId: string = "";
  readMessageApiCallId: string = "";
  twilioChatApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      accountId: -1,
      chatName: "",
      chatList: [],
      isVisibleModal: false,
      users : [
        { id: 1, name: "Olivia Johnson", avatar: "https://randomuser.me/api/portraits/women/44.jpg", lastMessage: "Please ensure you have the...", time: "1h" },
        { id: 2, name: "Megan Smith", avatar: "https://randomuser.me/api/portraits/women/45.jpg", lastMessage: "I’ve attached the requeste...", time: "3h" },
        { id: 3, name: "Hellen Williams", avatar: "https://randomuser.me/api/portraits/women/46.jpg", lastMessage: "I’ve just received an e-mail...", time: "3h" },
      ],
      
      messages : [
        { id: 1, name: "Olivia Johnson", text: "I believe you’re all set for the checkride. If you have any last-minute questions, feel free to reach out. See you tomorrow!", time: "2:00 PM", type: "received" },
        { id: 2, name: "You", text: "Will do! Thanks again, see you tomorrow!", time: "2:05 PM", type: "sent" },
        { id: 3, name: "Olivia Johnson", text: "Congrats! You did great today", time: "12:50 PM", type: "received" },
        { id: 4, name: "You", text: "Thank you! I really appreciate your help.", time: "12:55 PM", type: "sent" },
        { id: 5, name: "Olivia Johnson", text: "You're ready for the next step! Best of luck with your future flights.", time: "1:00 PM", type: "received" },
        { id: 6, name: "You", text: "Thank you! It’s been an amazing experience working with you!", time: "1:03 PM", type: "sent" },
      ],
      selectedUser: {
        id: 1,
        name: "",
        avatar: "",
        lastMessage: "",
        time: ""
      },
      isUserDetails: false,
      mobileWidth : false,
      screenWidth: window.innerWidth,
        isChatUserList: true,
        isChatMessage : false,
        isChatUserDetails: false,
       chatUserList :[],
        messageInput: "",
        messageHistory:[],
        chatUserName: "",
        chatUserRole: "",
        chateId: 0,
        twilioToken: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    // Customizable Area Start
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    window.addEventListener("resize", this.handleResize);
    this.getChatListApi()
    // Customizable Area End
  }

  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };

  isStringNullOrBlank = (string: string) => {
    return string === undefined || string === null || string.length === 0;
  };

  showModal = () => {
    this.setState({ isVisibleModal: true });
  };

  hideModal = () => {
    this.setState({ isVisibleModal: false });
  };

  navigateToChatView = (chatId: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "ViewChat");

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
      chatId: chatId,
    });
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(message);
  };

  getChatList = async (token: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getChatListApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getMyChatsApiEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  createChatRoom = (chatName: string) => {
    if (this.isStringNullOrBlank(chatName)) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory,
        ""
      );
    } else {
      const header = {
        "Content-Type": configJSON.apiContentType,
        token: this.state.token,
        "Access-Control-Allow-Origin": "*",
      };
      const bodyData = {
        name: chatName,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.createChatRoomApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.createChatRoomApiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(bodyData)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postApiMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };

  inputRoomNameProps = {
    onChangeText: (chatName: string) => {
      this.setState({ chatName });
    },
  };

  btnAddRoomProps = {
    onPress: () => this.createChatRoom(this.state.chatName),
  };

  btnCloseModalProps = {
    onPress: () => this.hideModal(),
  };

  btnShowAddModalProps = {
    onPress: () => {
      this.showModal();
    },
  };

  handleChatNameChange = (chatName: string) => {
    this.setState({ chatName });
  };

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorResponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if (responseJson && !responseJson.errors) {
      this.responseSucessCell(apiRequestCallId, responseJson)
    }
    else if (responseJson && responseJson.errors) {
      this.responseFailureCell(apiRequestCallId, responseJson)
    }
    if (errorResponse) {
      this.parseApiCatchErrorResponse(errorResponse);
    }
    if (responseJson?.errors) {
      this.parseApiErrorResponse(responseJson);
    }

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      const token: string = message.getData(
        getName(MessageEnum.SessionResponseToken)
      );
      runEngine.debugLog("TOKEN", token);
      const messageData = JSON.parse(
        message.getData(getName(MessageEnum.SessionResponseData))
      );
      const accountId: number = messageData?.meta?.id;
      this.setState({ accountId });
      if (token) {
        this.setState({ token }, () => this.getChatList(token));
      }
    }
    if (
      responseJson?.data &&
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getChatListApiCallId.length > 0 &&
      apiRequestCallId === this.getChatListApiCallId
    ) {
      this.getChatListApiCallId = "";
      const chatList = responseJson.data;
      
    }
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.createChatRoomApiCallId.length > 0 &&
      apiRequestCallId === this.createChatRoomApiCallId &&
      responseJson
    ) {
      this.createChatRoomApiCallId = "";
      this.hideModal();
      this.getChatList(this.state.token);
    }
  }
 
  async componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
    
  }

  handleResize = () => {
    this.setState({ screenWidth: window.innerWidth });
  };

  handleUserDetails = () => {
    this.setState({ isUserDetails: true })
  };

  handleBackToChat = (event:React.SyntheticEvent) => {
    event?.stopPropagation();  
    this.setState(
      (prevState) => ({ isUserDetails: !prevState }),  
    );
  };

  chatDetailsScreen = (ChatId: number) => {
   this.setState({isChatMessage: true, isChatUserDetails:false, isChatUserList:false, chateId: ChatId},()=>this.getShowChattApi(ChatId))
  };

  handleMobileBackToChat = () => {
    this.setState({isChatMessage: false, isChatUserList: true})
  }
   
  handleChatUserDetails = () =>{
    this.setState({isChatUserDetails: true, isChatMessage: false, isChatUserList:false})
  }

  backChatUserDetails = () =>{
    this.setState({isChatUserDetails: false,isChatMessage: true})
  }

  chatApiCall = async (valueData: {
    contentType?: string;
    method?: string;
    endPoint?: string;
    body?: {};
    apiType?: string;
    type?: string;
  }) => {
    const token = await (localStorage.getItem('accessToken'))
    let { contentType, method, endPoint, body, apiType } = valueData;
    let header = {
      "Content-Type": contentType,
      Token: token
    };
    let chatRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    chatRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    chatRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    body &&
      chatRequestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        apiType === "" ? JSON.stringify(body) : body
      );
    chatRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(chatRequestMessage.id, chatRequestMessage);
    return chatRequestMessage.messageId;
  };

  responseSucessCell = async (apiRequestCallId: string, responseJson: ChatResponse & ChatMessageResponse &
    ChatSendMessageResponse) => {
    if (apiRequestCallId === this.getChatListApiCallId) {
      this.getChatListSucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.getShowChatApiCallId) {
      this.getShowChatSucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.sendMessageApiCallId) {
      this.sendMessageSucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.twilioChatApiCallId) {
      this.twilioChatSucessCallBack(responseJson);
    }
  };

  responseFailureCell = async (apiRequestCallId: string, responseJson: ErrorResponse) => {
    if (apiRequestCallId === this.getChatListApiCallId) {
      this.getChatListFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.getShowChatApiCallId) {
      this.getShowChatFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.sendMessageApiCallId) {
      this.sendMessageFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.readMessageApiCallId) {
      this.readeMessageFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.twilioChatApiCallId) {
      this.twilioChatFailureCallBack(responseJson);
    }
  };

  getChatListApi = async () => {
    this.getChatListApiCallId = await this.chatApiCall({
      contentType: configJSON.apiContentType,
      method: configJSON.getApiMethod,
      endPoint: configJSON.chatListEndPoint,
    });
  };

  geapiCall = (chatId: number) => {
    this.twilioChatApi();
    this.getShowChattApi(chatId);
  };

  getChatListSucessCallBack = (response: ChatResponse) => {
    if(response.success){
    this.setState({ chatUserList: response.data.data , chateId:Number(response.data.data[0].id)}, 
    () => this.geapiCall(Number(response.data.data[0].id)))
  }else{
    this.setState({chatUserList: []})
  }
  }

  getChatListFailureCallBack = (response: ErrorResponse) => {
    toast.error(response.errors[0].token)
  }

  getShowChattApi = async (id: number) => {
    this.getShowChatApiCallId = await this.chatApiCall({
      contentType: configJSON.apiContentType,
      method: configJSON.getApiMethod,
      endPoint: `${configJSON.chatMessageDetails}/${id}`,
    });
  };

  getShowChatSucessCallBack = (response: ChatMessageResponse) => {
    response.data.data[0].attributes.messages.forEach((item: ChatMessage) => {
      item.currentDate = this.formatMessageDate(item.attributes.created_at);
    });

    this.setState({
      messageHistory: response.data.data[0].attributes.messages,
      chatUserName: response.data.data[0].attributes.chat_user_name,
      chateId: response.data.data[0].attributes.id
    }, () => this.readeMessageApi(this.state.chateId));
}

  getShowChatFailureCallBack = (response: ErrorResponse) => {
    toast.error(response.errors[0].token)
  }

  changeMessageInput = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ messageInput: event.target.value })
  };

  sendMessageApi = async () => {
    const body = {
      message: {
        message: this.state.messageInput
      }
    }
    this.sendMessageApiCallId = await this.chatApiCall({
      contentType: configJSON.apiContentType,
      method: configJSON.postApiMethod,
      endPoint: `bx_block_chat/chats/${this.state.chateId}/messages`,
      body: JSON.stringify(body),
    });
  };

  sendMessageSucessCallBack = (response: ChatSendMessageResponse) => {
    if (response.message) {
      this.getShowChattApi(response.data.data.attributes.chat_id)
      this.setState({messageInput:""})
    }
  }

  sendMessageFailureCallBack = (response: ErrorResponse) => {
    toast.error(response.errors[0].token)
  }


  readeMessageApi = async (chatId: number) => {
    this.readMessageApiCallId = await this.chatApiCall({
      contentType: configJSON.apiContentType,
      method: configJSON.postApiMethod,
      endPoint: `/bx_block_chat/chats/${chatId}/read_messages`,
    });
  };

  readeMessageFailureCallBack = (response: ErrorResponse) => {
    toast.error(response.errors[0].token)
  }

  twilioChatApi = async () => {
    this.twilioChatApiCallId = await this.chatApiCall({
      contentType: configJSON.apiContentType,
      method: configJSON.getApiMethod,
      endPoint: configJSON.twilioEndPoint,
    });
  };

  twilioChatSucessCallBack = (response: {data:{token: string}}) => {
    if(response.data)(
      this.setState({twilioToken: response.data.token})
    )
  }

  twilioChatFailureCallBack = (response: ErrorResponse) => {
    toast.error(response.errors[0].token)
  }

  formatTimestamp = (isoString: string) => {
    return moment(isoString).format("h:mm A");
  };

  handleChatDetaile = (id: number, name: string, chatUser: string) => {
    this.getShowChattApi(id)
    this.twilioChatApi()
    this.setState({ chatUserRole: chatUser,chatUserName: name})
  }

  handleLimitCharacter = (message: string) =>{
    if(message?.length>= 27){
      return  message.substring(0, 27) + '...'
    }else{
      return message
    }
  }

  formatMessageDate =(timestamp:string)=> {
    const messageDate = new Date(timestamp);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    if (messageDate >= today) {
        return "Today";
    } else if (messageDate >= yesterday) {
        return "Yesterday";
    } else {
        return messageDate.toLocaleDateString('en-US', { month: 'short', day: '2-digit', year: 'numeric' });
    }
}

getFirstLetter = (userName: string) => {
  const words: string[] = userName.split(' ');
  if (words.length === 1) {
    return words[0].charAt(0).toUpperCase()
  }
  const firstInitial: string = words[0].charAt(0).toUpperCase();
  const lastInitial: string = words[words.length - 1].charAt(0).toUpperCase();
  let name = firstInitial + lastInitial;
 return name ;
}

 

  // Customizable Area End
}
