import React from "react";
import { ElementsConsumer ,CardNumberElement,
  CardExpiryElement,
  CardCvcElement,} from "@stripe/react-stripe-js";
import SchedulingController, { Props as BaseProps } from "../../blocks/scheduling/src/SchedulingController"; // Import Props
import  "../../blocks/scheduling/src/calendarStyles.css";
interface CheckoutFormProps extends BaseProps {} 

export class CheckoutForm extends SchedulingController {
  props: CheckoutFormProps; // ✅ Correct way
  constructor(props: CheckoutFormProps) {
    super(props);
    this.props = props;
  }

  handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    
    const { stripe, elements } = this.props;
    if (!stripe || !elements) {
      console.error("Stripe or Elements is not available.");
      return;
    }
  
    const cardElement = elements.getElement(CardNumberElement);
    if (!cardElement) {
      console.error("CardNumberElement is not found.");
      return;
    }
  
  
    try {
      // Step 1: Generate Stripe token
      const { token, error } = await stripe.createToken(cardElement);

      if (error || !token) {
        console.error("Stripe Token Error:", error?.message || "No token generated.");
        return;
      }
      
      await this.submitPayment(token.id, this.state.cardholderName || "");
      
    } catch (err) {
      console.error("Unexpected error:", err);
      alert("Something went wrong. Please try again.");
    }
  };
  


  handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ cardholderName: event.target.value });
  };

  render() {
    return (
      <form onSubmit={this.handleSubmit} className="payment-form">
        {/* Row 1: Card Number & Expiry Date */}
        <div className="input-row">
          <div className="form-group half-width">
            <label style={{padding:'5px',color:'#334155',fontSize:'16px',fontWeight:600,fontFamily:'Montserrat'}}>Card Number</label>
            <div className="input-field">
              <CardNumberElement
                options={{
                  style: {
                    base: {
                      fontSize: "16px",
                      color: "#424770",
                      "::placeholder": { color: "#aab7c4" },
                    },
                    invalid: { color: "#9e2146" },
                  },
                }}
              />
            </div>
          </div>
  
          <div className="form-group half-width">
            <label style={{padding:'5px',color:'#334155',fontSize:'16px',fontWeight:600,fontFamily:'Montserrat'}}>Expiry Date</label>
            <div className="input-field">
              <CardExpiryElement
                options={{
                  style: {
                    base: {
                      fontSize: "16px",
                      color: "#424770",
                      "::placeholder": { color: "#aab7c4" },
                    },
                    invalid: { color: "#9e2146" },
                  },
                }}
              />
            </div>
          </div>
        </div>
  
        {/* Row 2: Cardholder Name & CVV */}
        <div className="input-row">
          <div className="form-group half-width">
            <label style={{padding:'5px',color:'#334155',fontSize:'16px',fontFamily:'Montserrat',fontWeight:600}}>Cardholder Name</label>
            <input
              id="cardholder-name"
              type="text"
              value={this.state.cardholderName}
              onChange={this.handleNameChange}
              required
              className="input-field"
            />
          </div>
  
          <div className="form-group half-width">
            <label style={{padding:'5px',color:'#334155',fontSize:'16px',fontWeight:600,fontFamily:'Montserrat'}}>CVV</label>
            <div className="input-field">
              <CardCvcElement
                options={{
                  style: {
                    base: {
                      fontSize: "16px",
                      color: "#424770",
                      "::placeholder": { color: "#aab7c4" },
                    },
                    invalid: { color: "#9e2146" },
                  },
                }}
              />
            </div>
          </div>
        </div>
  
        <button type="submit" className="pay-button" disabled={!this.props.stripe}>
          Pay now
        </button>
      </form>
    );
  }
  
}



const InjectedCheckoutForm = (props: BaseProps) => (
  <ElementsConsumer>
    {({ stripe, elements }) => (
      <CheckoutForm {...props} stripe={stripe} elements={elements} /> // ✅ Pass all required props
    )}
  </ElementsConsumer>
);


export default InjectedCheckoutForm;
