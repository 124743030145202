import React from "react";
// Customizable Area Start
import {
    Box,
    styled,
    Typography,
    Button
} from "@mui/material";
import ApplicantAccountStatusController, { Props } from "./ApplicantAccountStatusController";
import { bgImage, mailIcon } from "../src/assets";
import { logoImage, successIcon } from "../../forgot-password/src/assets";
import OtpInput from "react-otp-input";
import CircularProgress, {
    circularProgressClasses,
} from '@mui/material/CircularProgress';
import { Theme } from "@mui/material/styles";
const configJSON = require("./config");
// Customizable Area End

export default class ApplicantAccountStatus extends ApplicantAccountStatusController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderLoader = () => {
        return (
            <Box className="loaderontainer">
                <CircularProgress
                    variant="determinate"
                    value={100}
                    size={40}
                    thickness={4}
                    sx={(theme: Theme) => ({
                        color: theme.palette.mode === "dark"
                            ? theme.palette.grey[800]
                            : theme.palette.grey[200],
                    })}
                />
                <CircularProgress
                    variant="indeterminate"
                    disableShrink
                    size={40}
                    thickness={4}
                    sx={() => ({
                        position: "absolute",
                        left: 0,
                        color: "#0074BF",
                        animationDuration: "550ms",
                        [`& .${circularProgressClasses.circle}`]: {
                            strokeLinecap: "round",
                        },
                    })}
                />
            </Box>
        )
    };

    renderWelcomeAboard = () => {
        return (
            <Box className="statusCard">
                <Box className="MailIconContainer">
                    <img src={successIcon.default} style={{ height: '60px', width: '60px' }} alt="left-arrow" />
                </Box>
                <Box className="headingName">
                    <Typography variant="h6" className="headingText">
                        Welcome aboard!
                    </Typography>
                    <Typography variant="h6" className="statusSubHeading">
                        {configJSON.welcomeText}
                    </Typography>
                </Box>
                <Box className="getStartedBtnCard">
                    <Button
                        type="button"
                        className="getStartedBtn"
                        data-testId="getStarted"
                        variant="contained"
                        onClick={() => this.navigateToProfile('landingPage')}
                    >
                        Get Started
                    </Button>
                    <Typography data-testId="logOutBtn" variant="h6" align="center" className="logOutBtn" onClick = {this.applicantLogoutBtn}>
                        Log Out
                    </Typography>
                </Box>
            </Box>
        )
    }
    
    renderOtpInput = () => {
        return (
            <Box className="statusCard" data-test-id='account-inprogress'>
                <Box className="MailIconContainer">
                    <img src={mailIcon} style={{ height: '60px', width: '60px' }} alt="left-arrow" />
                </Box>
                <Box className="headingName" >
                    <Typography variant="h6" className="headingText">
                        Activate your account!
                    </Typography>
                    <Typography className="statusSubHeading" >
                        {configJSON.accountStatusText}
                    </Typography>
                    <OtpInput
                        value={this.state.emailOtp}
                        data-testId = "setOtpTextId"
                        onChange={this.setOtp}
                        numInputs={6}
                        renderInput={(props) => <input {...props} className={this.state.emailOtpError ? "otpInputError" : "otpInput"} />}
                    />
                    {this.state.emailOtpError && (
                        <div className="errorMessageIconContainer">
                            <span className="errorMessageText">
                                The entered OTP is invalid.
                            </span>
                        </div>
                    )}
                    <Box style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                       
                        {this.state.resendOtp ? this.renderLoader() :
                            <button
                                type="button"
                                className="buttonStyle"
                                 data-testId = "resendotp"
                                onClick={this.resendFtnOtpApi}
                            >
                                Resend code
                            </button>
                        }
                    </Box>
                </Box>

            </Box>
        )
    }

    header = () => {
        return (
            <Box>
                <Box className="statusmainContainer">
                    <Box className="logoContainer">
                        <img src={logoImage} className="dpeLogoImage" alt="logo" />
                    </Box>
                </Box>
                <Box className="statusBox">
                    {this.state.welcomeCard ? this.renderWelcomeAboard() : this.renderOtpInput()}

                </Box>
            </Box>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            <StatusContainer>
                {this.header()}
            </StatusContainer>
        )
        // Customizable Area End
    }
}

// Customizable Area Start
const StatusContainer = styled(Box)({
    width: '100%',
    height: '100%',
    "& .statusmainContainer": {
        height: "90px",
        width: "100%",
        backgroundColor: '#daf8ff',
    },
    "& .logoContainer": {
        width: '118',
        objectFit: "cover",
        marginLeft: "100px",
        height: '100%',
        display: "flex",
        alignItems: "center",
        "@media (max-width: 414px)": {
            marginLeft: "20px",
        }
    },
    "& .dpeLogoImage": {
        padding: '7px',
        height: '50px',
        width: '118',
        objectFit: "cover"
    },
    "& .statusBox": {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        backgroundColor: '#ffffff',
        backgroundImage: `url(${bgImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    },
    "& .statusCard": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        borderRadius: '26px',
        border: '1px solid rgb(252, 250, 250)',
        backgroundColor: 'rgb(255, 255, 255)',
        padding: '30px',
        "@media (max-width: 414px)": {
            width: "80%",
            padding: '20px ,0px'
        }
    },
    "& .headingName": {
        position: "relative",
        width: "100%",
        maxWidth: "360px",
        mb: 1
    },
    "& .statusSubHeading": {
        margin: '15px 0px',
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: 400,
        fontSize: "16px",
        color: "#001C55",
        textAlign: 'center',
        "@media (max-width: 414px)": {
            fontSize: "12px"
        }
    },
    "& .buttonStyle": {
        height: "50px",
        width: "360px",
        marginTop: "25px",
        marginBottom: "20px",
        borderRadius: "20px",
        border: "1px solid #0074BF",
        color: "#0074BF",
        background: "white",
        cursor: "pointer",
        fontSize: "16px",
        fontFamily: "Montserrat",
        fontWeight: 700,
        textTransform: "none",
        "@media (max-width: 414px)": {
            width: "260px",
        }
    },
    "& .getStartedBtn": {
        height: "50px",
        width: "360px",
        marginTop: "25px",
        marginBottom: "20px",
        borderRadius: "20px",
        border: "1px solid #0074BF",
        color: "white",
        background: "#0074BF",
        cursor: "pointer",
        fontSize: "16px",
        fontFamily: "Montserrat",
        fontWeight: 700,
        textTransform: "none",
        "@media (max-width: 414px)": {
            width: "260px",
        }
    },

    "& .MailIconContainer": {
        margin: '7px 18px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '120px',
        width: '120px',
        borderRadius: "50%",
        backgroundColor: '#E0F2F8'
    },
    "& .headingText": {
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: 700, fontSize: "22px", color: "#001C55",
        textAlign: 'center'
    },
    "& .otpInput": {
        width: "48px !important",
        height: "50px",
        borderRadius: "20px",
        border: "1px solid #CBD5E1",
        background: "#FFF",
        margin: "24px 5px 0px 5px",
        "&:focus": {
            borderColor: "#0074BF !important",
            outline: "none"
        },
        "@media (max-width: 414px)": {
            width: "38px!important",
            height: "40px",
            borderRadius: "15px",
        }
    },
    "& .otpInputError": {
        width: "48px !important",
        height: "50px",
        borderRadius: "20px",
        border: "1px solid #DC2626",
        background: "#FFF",
        margin: "24px 5px 0px 5px",
        outline: "none",
        "@media (max-width: 414px)": {
            width: "38px!important",
            height: "40px",
            borderRadius: "15px",
        }
    },
    "& .logOutBtn": {
        fontFamily: 'Montserrat, sans-serif',
        fontSize: "16px",
        display: "inline",
        fontWeight: 700,
        color: "#0074BF",
        textDecoration: "none",
        marginLeft: "7px",
        cursor: "pointer"
    },
    "& .errorMessageIconContainer": {
        display: "flex",
        justifyContent: "center",
        marginTop: "10px"
    },
    "& .errorMessageText": {
        color: "#DC2626",
        fontFamily: 'Montserrat, sans-serif',
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "30px",
    },
    "& .loaderontainer": {
        position: "relative",
        display: "inline-flex",
        marginTop: "25px",
        marginBottom: "20px"
    },
    "& .getStartedBtnCard": {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column"
    }
})
// Customizable Area End
