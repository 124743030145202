// Customizable Area Start
import React from "react";
import {
    Box,
    Typography,
    styled,
    TextField,
    Autocomplete,
    InputAdornment,
    Dialog,
    DialogContent,
    IconButton,
    DialogTitle,
    Button
  } from "@mui/material";
import CustomisableUserProfilesController, { Option, Props } from "../CustomisableUserProfilesController";
import { back_button, button_close, delete_icon, flighted, searchIcon } from "../assets";
import { Link } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";

export default class SettingsAircraft extends CustomisableUserProfilesController {
  constructor(props: Props) {
    super(props);
  }

  renderAirportsList(){
    const { settingsAircrafts } = this.state;
    if(settingsAircrafts.length <= 0) return;
    return (
      <>
        {settingsAircrafts.map((aircraft: any) => (
          <Box key={aircraft.service_subcategory_id}>
            <Box className="airportContainer">
              <Box className="airportIconContainer">
                <img src={flighted} alt="" />
                <Typography className="airportName">
                  {aircraft.service_subcategory_name}
                </Typography>
              </Box>
              <Box data-test-id="delete-icon" onClick={() => this.setState({selectedAircraftToDelete: aircraft, deleteAirportDialogOpen1: true})} className="pointer">
                <img src={button_close} alt="" />
              </Box>
            </Box>
            <Box className="border" />
          </Box>
        ))}
      </>
    );
  }
  

  render() {
    const { aircraftOptions, settingsAircrafts, inputValue1, isOpen1, selectedAircraftToDelete, deleteAirportDialogOpen1, isEditAirportSettingsOpen} = this.state
    const selectedOptions = aircraftOptions?.filter((option: any) => {
      return settingsAircrafts?.includes(option.id)
    });
    return(
        <CustomSettingsAirportsContainer>
            <Box sx={webStyle.headerContainer} data-test-id = "test-details-page">
                <Box sx={webStyle.pageHeader}>
                   {isEditAirportSettingsOpen && 
                      <Link to={"/calendar"}> 
                        <img data-test-id = "handleBackToCreateAvailability" 
                            onClick={() => this.handleBackToCreateAvailability()} 
                            src={back_button} 
                            style={webStyle.leftArrow} />
                      </Link>}
                   <Typography sx={webStyle.headerText}>Aircraft types</Typography>
                </Box>
            </Box>

            <Box style={{padding:'50px 50px 50px 50px'}} >
                    <Box sx={webStyle.contentContainer} >
                        <Typography className="airportsText">Aircraft type</Typography>
                        <Autocomplete
  getOptionLabel={(option: Option) => option?.name}
  value={selectedOptions}
  onInputChange={this.handleAircraftInputChange}
  clearOnBlur={false}
  onChange={this.handleChange1}
  inputValue={inputValue1}
  multiple
  options={aircraftOptions}
  open={isOpen1}
  onClose={() => this.setState({ isOpen1: false })}
  noOptionsText={
    inputValue1 ? (
      <Box sx={{ padding: "10px" }}>
        <Typography
          sx={{
            color: "#94A3B8",
            fontFamily: "Montserrat",
            fontWeight: 400,
            paddingBottom: "8px",
          }}
        >
          No results found for '{inputValue1}'
        </Typography>
        <Box sx={{ borderBottom: "1px solid #D1D5DB", marginBottom: "8px" }} />
        <Typography
          className="clickableText"
          onClick={this.handleNoResultsClick}
          sx={{
            color: "#0F172A",
            fontFamily: "Montserrat",
            fontWeight: 400,
            cursor: "pointer",
          }}
        >
          Add '{inputValue1}' as other aircraft type
        </Typography>
      </Box>
    ) : "No options"
  }
  renderOption={(props, option) => (
    <Box component="li" sx={webStyle.option} {...props}> 
      {option.name} 
    </Box>
  )}
  sx={webStyle.autoCompleteStyle}
  componentsProps={{
    paper: {
      sx: {
        backgroundColor: "#fff",
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
        padding: "10px",
      },
    },
  }}
  renderInput={(params) => (
    <TextField
      {...params}
      placeholder={"Add new aircraft type"}
      variant="outlined"
      onFocus={() => this.setState({ isFocused: true })}
      onBlur={() => this.setState({ isFocused: false })}
      InputProps={{
        ...params.InputProps,
        startAdornment: (
          <InputAdornment position="start">
            <img src={searchIcon} alt="search" />
          </InputAdornment>
        ),
        endAdornment: null,
        sx: {
          ...webStyle.searchInput,
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: this.state.isFocused ? 'blue' : 'gray',
            },
            '&:hover fieldset': {
              borderColor: 'blue',
            },
            '&.Mui-focused fieldset': {
              borderColor: 'blue',
            },
          },
        },
      }}
    />
  )}
  renderTags={() => null}
/>

                        <Typography className="airportsTitle">Aircraft Types You Operate</Typography>
                        <Box className="border" />

                        {this.renderAirportsList()}
                    </Box>
            </Box>

            <Dialog open={deleteAirportDialogOpen1}
            data-test-id="deleteAirportDialogOpen7"
              onClose={() => { 
              this.setState({ deleteAirportDialogOpen1: false })
              }} maxWidth="sm" fullWidth PaperProps={{style: { borderRadius: 16, width: "600px", overlay:"none"}}} >
              <CustomDeleteTitle>
                <img className="deleteIcon" src={delete_icon} alt="delete" />
                Delete aircraft type?
                <IconButton
                  data-test-id="deleteAirportDialogOpen9"
                  aria-label="close"
                  onClick={() => this.setState({ deleteAirportDialogOpen1: false })}
                  sx={{ position: 'absolute', right: 8, top: 18, }}
                >
                  <CloseIcon sx={webStyle.icons} />
                </IconButton>
              </CustomDeleteTitle>
              <DialogContent sx={webStyle.deleteDialogContent} >
                <Typography sx={webStyle.deleteText}>
                  Are you sure you want to remove the <span style={webStyle.airportName}>{`${selectedAircraftToDelete.service_subcategory_name}`}</span> aircraft type? You can re-add it anytime.
                </Typography>
                <Box sx={webStyle.border} />
                <Box sx={webStyle.actionsContainer}>
                    <CustomButton
                      data-test-id="deleteAirportDialogOpen6"
                      variant="outlined"
                      sx={webStyle.deleteCancelButton}
                      onClick={() => this.setState({deleteAirportDialogOpen1: false})}>
                        Cancel
                    </CustomButton>
                    <CustomButton
                      data-test-id="delete-aircraft"
                      variant="contained"
                      color="primary"
                      sx={webStyle.deleteButton}
                      onClick={() => this.handleRemoveAircraft(selectedAircraftToDelete.id)}
                      >
              Yes, delete aircraft type
                    </CustomButton>
                </Box>
              </DialogContent>
             </Dialog>
        </CustomSettingsAirportsContainer>
    )
  }
}

const CustomSettingsAirportsContainer = styled(Box)({
    "& .airportsText":{
        fontFamily: "Montserrat",
        fontWeight: 600,
        fontSize: "16px",
        color: "#334155",
    },
    "& .airportName":{
        fontFamily: "Montserrat",
        fontWeight: 500,
        fontSize: "16px",
        color: "#0F172A",
        marginLeft:"20px"
    },
    "& .airportsTitle":{
        fontFamily: "Montserrat",
        fontWeight: 600,
        fontSize: "14px",
        color: "#94A3B8",
        margin:"20px 0px 10px 25px",
        textTransform:"uppercase"
    },
    "& .airportIconContainer":{
      display:"flex",
      justifyContent:"space-between",
    },
    "& .airportContainer":{
      margin:"20px 25px 20px 25px",
      display:"flex",
      justifyContent:"space-between"
    },
    "& .border":{
      borderBottom:"1px solid #E2E8F0",
    },
    "& .pointer":{
      cursor:"pointer"
    },
})

const webStyle = {
    pageHeader: {
        display:'flex',
        alignItems:'center',
        gap:'20px'
    },
    headerContainer: {
        display: "flex",
        height: "60px",
        padding: "10px 50px 10px 50px",
        alignItems: "center",
        background: "#ffffff",
        justifyContent:"space-between"
    },
    headerText: {
        fontFamily: "Montserrat",
        fontWeight: 700,
        fontSize: "22px",
        color: "#0074BF",
    },
    airportName:{
      fontFamily: "Montserrat",
      fontWeight: 500,
      fontSize: "16px",
      color: "#0F172A",
    },
    contentContainer: {
        padding: "20px 50px 50px 50px",
        alignItems: "center",
        background: "#ffffff",
        justifyContent:"space-between",
        cursor:'pointer',
        borderRadius:'20px'
    },
    option: {
      padding: '10px 3px 10px 3px',
      '&:hover': {
          backgroundColor: '#E0F2F8',
          cursor: 'pointer',
      },
      fontFamily: 'Montserrat, sans-serif',
      fontSize: '16px',
      lineHeight: '24px',
    },
    autoCompleteStyle:{
      height: '50px',
      padding: '5px 0px 10px 0px',
      '& .MuiInputBase-root': {
          border: '1px solid #CBD5E1',
          '&:hover': {
              border: '1px solid blue',
              borderColor: 'darkgray',
          },
      },
    },
    searchInput: {
      "& .css-wb57ya-MuiFormControl-root-MuiTextField-root": {
          width: "90%",
      },
      '& .MuiOutlinedInput-notchedOutline': {
          borderColor: '#CBD5E1', // Set default border color
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: '#0074BF', // Change border on hover
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: '#0074BF', // Keep border blue when focused
      },
      '&::placeholder': {
          color: '#94A3B8',
          opacity: 1,
      },
      borderRadius: '20px',
      "@media (max-width: 414px)": {
          width: "90%",
      }
  },
    leftArrow:{ 
      marginRight: "10px", 
      height: '24px', 
      width: '24px', 
      color: "#0074BF" 
    },
    deleteDialogContent:{ padding: "0px"},
    icons:{color:"#94A3B8", cursor: "pointer"},
    deleteText: {
      margin:"25px 45px",
      color:"#0F172A",
      fontFamily: "Montserrat",
      fontWeight: 400,
      fontSize: "16px",
    },
    actionsContainer:{
      display:"flex",
      justifyContent:"space-between",
      padding: "20px 25px 25px 25px"
    },
    border:{
      borderBottom:"1px solid #E2E8F0",
    },
    deleteCancelButton: {
      padding: "0px",
      color:"#0074BF",
      height: "50px",
      fontFamily: "Montserrat",
      fontWeight: 700,
      textTransform: "none",
      fontSize: "16px",
      borderRadius: "20px",
      width:"265px",
      border:"2px solid #0074BF"
    },
    deleteButton: {
      height: "50px",
      fontSize: "16px",
      padding: "0px 15px",
      fontWeight: 700,
      fontFamily: "Montserrat",
      textTransform: "none",
      borderRadius: "20px",
      color: "#ffffff",
      background: "#0074BF",
      width:"265px",
    },
}

const CustomDeleteTitle = styled(DialogTitle)({
    border:"1px solid #E2E8F0",
    fontSize: 22,
    fontWeight: "700",
    textAlign: 'left',
    color: "#001C55",
    fontFamily: "Montserrat",
    display: "flex",
    alignItems: "center",
    margin:"0px",
    padding:"16px 40px",
    "& .deleteIcon":{
      marginRight:"20px"
    }
})

const CustomButton = styled(Button)({
    textTransform: "capitalize",
    fontWeight: 700,
    fontSize: 16,
    width: 258,
    borderRadius: 20
})
// Customizable Area End
