import React from "react";
import {
  Typography,
  IconButton,
  Box,
  Dialog, DialogTitle, DialogContent, DialogContentText
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import Loader from "../../../../components/src/Loader.web";

  const textStyles = {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    textAlign: 'justify',
    color: '#0F172A',
    fontFamily: "Montserrat",
  };

  interface TermsAndConditionsModalProps {
    open: boolean;
    onClose: () => void;
    description: string;
    loader: boolean;
  }
  
  const TermsAndConditionsModal: React.FC<TermsAndConditionsModalProps> = ({ open, onClose, description ,loader}) => {
    return (
      <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth
        PaperProps={{
          style: { borderRadius: 16 }
        }}>
        <DialogTitle sx={{
          borderBottom: "1px solid #E2E8F0",
          fontSize: '22px',
          fontWeight: 700,
          lineHeight: '32px',
          textAlign: 'left',
          color: "#001C55",
          fontFamily: "Montserrat",
        }}>
          Terms and Conditions
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon sx={{ width: "18px", height: "18px", color: "#0074BF" }} />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ marginTop: 4 }}>
          <DialogContentText>
            <Typography paragraph sx={textStyles}>
            {description}
            </Typography>
          </DialogContentText>
          {loader && 
          <Box style={{display:"flex",justifyContent: "center"}}>
          <Loader loading={loader}/>
          </Box>
          }
          
        </DialogContent>
      </Dialog>
    );
  };
  export default TermsAndConditionsModal;
