import React from "react";
import {
    Typography,
    IconButton,
    Dialog, DialogTitle, DialogContent, DialogContentText,Box
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import Loader from "../../../../components/src/Loader.web";

const textStylesMessage = {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    textAlign: 'justify',
    color: '#0F172A',
    fontFamily: "Montserrat",
};

const loaderContainer = {
    display:"flex",
    justifyContent: "center"
};

const closeIcon= {
     width: "18px", 
     height: "18px", 
     color: "#0074BF" 
    }
interface PrivacyPolicyModalProps {
    open: boolean;
    onClose: () => void;
    description: string;
    loader: boolean;
}

const PrivacyPolicyModal: React.FC<PrivacyPolicyModalProps> = ({ open, onClose, description ,loader}) => {
    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth PaperProps={{
            style: { borderRadius: 16 }
        }} >
            <DialogTitle sx={{
                borderBottom: "1px solid #E2E8F0",
                fontSize: '22px',
                fontWeight: 700,
                lineHeight: '32px',
                textAlign: 'left',
                color: "#001C55",
                fontFamily: "Montserrat",
                
            }}>
                Privacy Policy
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{ position: 'absolute', right: 8, top: 8 }}
                >
            <CloseIcon sx={closeIcon} />
            </IconButton>
            </DialogTitle>
            <DialogContent sx={{ marginTop: 4 }}>
                <DialogContentText>
                    <Typography paragraph sx={textStylesMessage}>
                        {description}
                      </Typography>
                </DialogContentText>
                {loader &&
                <Box sx={loaderContainer} >
                <Loader loading={loader}/>
                </Box> }
                
            </DialogContent>
        </Dialog>
    );
};

export default PrivacyPolicyModal;
