import React from 'react';
// Customizable Area Start
import { Box,TextField, IconButton, InputLabel, Typography,styled } from '@mui/material';
import MultipleDropDown from "./MultipleDropDown";
import { Link } from 'react-router-dom';
import { leftArrow, pdfIcon } from "../assets";

import { MoreVert } from '@mui/icons-material';
import UploadPdfButton from './UploadPdfButton'

interface AccountSetupStep3Props {
  formValues: any;
  onChangeItems: (name: string, value: string[]) => void;
  onInputHandleChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  handlePrevious: () => void
  onSubmit: (event: React.MouseEvent<HTMLButtonElement>) => void; // Define the type for onSubmit
  handleFileSelect: any;
  validateForm: (name: string) => void,
  errors: { 
    examCost: string,
    paymentMethods: string,
    instructions: string,}
}
interface AccountSetupStep3States {
  open: boolean,
  checked: boolean

}
class AccountSetupStep3 extends React.Component<AccountSetupStep3Props, AccountSetupStep3States> {
  constructor(props: AccountSetupStep3Props) {
    super(props);
    this.state = {
      open: false,
      checked: false
    };
  }

  enableButton() {
    const { formValues } = this.props;
    
    const hasInstructions = formValues.instructions?.trim().length > 0;
    const hasUploadedFiles = formValues.uploadedFiles.length > 0;
    const examCost = formValues.examCost.replace('$', '').trim();
    const hasPaymentMethods = formValues.paymentMethods.trim().length > 0;
  
    return !((hasInstructions || hasUploadedFiles) && examCost.length > 0 && hasPaymentMethods);
  }
  
  formatFileSize = (sizeInBytes: number): string => {
    return `${(sizeInBytes / 1024).toFixed(2)} KB`;
  };
  render() {
    const { formValues, onSubmit, handlePrevious, onInputHandleChange, handleFileSelect, onChangeItems, validateForm, errors } = this.props;
     return (
      <Box sx={webStyle.formWrapper}>
        <Box sx={webStyle.inputWrapper}>
          <Typography variant="h6" sx={webStyle.timeInstructions}>
            <img src={leftArrow} style={webStyle.backIcon} onClick={ handlePrevious} />
            Time for instructions!
          </Typography>
          <Typography variant="h6" sx={webStyle.timeInstructionText}>
            Add the final details your applicants need for each designation:
          </Typography>
        </Box>
        <Box sx={{ ...webStyle.inputWrapper }}>
          <InputLabel style={webStyle.label}>Designation</InputLabel>
          <MultipleDropDown 
                uploadedData={formValues.uploadedData} 
                options={formValues.designationOption} 
                name='designation' placeholder="Enter aircraft type" selectedItems={formValues.designation} onItemsChange={onChangeItems} />
        </Box>
        <Box sx={webStyle.examCostContainer}>
          <Box sx={webStyle.examCost}>
            <InputLabel style={webStyle.label}>Exam cost</InputLabel>
            <TextField
              data-test-id={"exam cost"}
              name="examCost"
              id="examCost"
              value={formValues.examCost}
              helperText={errors.examCost}
              onChange={onInputHandleChange}
              onBlur={() => validateForm("examCost")}
              error={!!errors.examCost}
              size="medium"
              fullWidth={true}
              variant="outlined"
              type="text"
              placeholder="e.g., $1,000.00"
              sx={{
                backgroundColor: "#F1F5F9",
                color: "#94A3B8",
                mb: "3px",
              }}
              InputProps={{
                style: {
                  borderRadius: '20px',
                  fontWeight: 400,
                }
              }}
            />
          </Box>
          <Box sx={webStyle.paymentMethod}>
            <InputLabel style={webStyle.label}>Accepted payment methods</InputLabel>
            <TextField
              id="paymentMethods"
              data-test-id={"paymentMethods"}
              value={formValues.paymentMethods}
              onChange={onInputHandleChange}
              onBlur={() => validateForm("paymentMethods")}
              helperText={errors.paymentMethods}
              error={!!errors.paymentMethods}
              size="medium"
              name="paymentMethods"
              variant="outlined"
              placeholder="e.g., Credit Card, PayPal, Cash"
              fullWidth={true}
              type={"text"}
              sx={{
                backgroundColor: "#F1F5F9",
                color: "#94A3B8",
                mb: "3px",
              }}
              InputProps={{
                style: {
                  borderRadius: '20px',
                  fontWeight: 400
                }
              }}
            />
          </Box>
        </Box>
        <Box sx={webStyle.inputWrapperInstruction}>
          <InputLabel style={webStyle.label}>Instructions</InputLabel>
          <TextField
            data-test-id={"instructions"}
            name="instructions"
            rows={7}
            multiline
            value={formValues.instructions}
            onBlur={() => validateForm("instructions")}
            error={!!errors.instructions}
            helperText={errors.instructions}
            onChange={onInputHandleChange}
            size="medium"
            fullWidth={true}
            variant="outlined"
            type="text"
            placeholder="Enter detailed instructions or guidelines for the designation here"
            sx={{backgroundColor: "#F1F5F9", mb: "3px", color: "#94A3B8",}}
            InputProps={{
              style: { fontWeight: 400,borderRadius: '20px'}
            }}
          />
        </Box>
        <Box sx={webStyle.uploadFileBox}>
          <Box sx={webStyle.fileList} >
            {formValues.uploadedFiles.length > 0 
              && formValues.uploadedFiles.map((file: any, index: any) =>  (
                <Box sx={webStyle.fileItem} key={index}>
                  <Box sx={webStyle.fileIcon}>
                    <img src={pdfIcon.default} width={'30px'} height={'30px'} />
                  </Box>
                  <Box sx={webStyle.fileInfo}><Typography sx={webStyle.fileName}>{file.name}</Typography>
                    <Typography sx={webStyle.fileSize}>{this.formatFileSize(file.size)}</Typography>
                  </Box>
                  <IconButton size="small"><MoreVert sx={webStyle.uploadIcon} /></IconButton>
                </Box>
              )
            )}
          </Box>
          <Box sx={webStyle.uploadBtnBox}>
            <UploadPdfButton data-test-id="upload-pdf-button" onFileSelect={(event) => handleFileSelect(event)} />
          </Box>
        </Box>
        <Box sx={webStyle.containerBox}>
          <BoxContainer>
          <button
            type="button"
            onClick={onSubmit}
            className = {!this.enableButton()? "activeButtonStyle" : "buttonStyle"}
            data-test-id="LoginBtn"
            disabled= {this.enableButton()}
          >
            { (formValues?.designationOption?.length === (formValues?.currentIndex + 1) && (formValues?.designationOption?.length === formValues?.uploadedData?.length + 1))? "Finish setup": "Continue"}
          </button>
          </BoxContainer>
        </Box>
        <Typography sx={webStyle.finishLaterText} variant="h6" align="center" >
          <Link style={webStyle.linkStyle} to="/Profile" >I'll finish this later</Link>
        </Typography>
      </Box>
    );
  }
}

const webStyle = {
  formWrapper: {
    justifyContent: 'flex-start',
    display: 'flex',
    border: '1px solid rgb(252, 250, 250)',
    backgroundColor: '#F1F5F9',
    flexDirection: 'column',
    borderRadius: '26px',
    padding: '60px',
   
    "@media (max-width: 660px)": {
      width: "96%",
      padding: '20px ,0px',
    },
    "@media (max-width: 600px)": {
      width: "90%",
      padding: '20px ,0px',
    },
  },
  timeInstructions:{
    fontFamily: 'Montserrat, sans-serif',display: 'flex',
    alignItems: 'center', fontSize: "22px", color: "#001C55",
    fontWeight: 700,
    "@media (max-width: 414px)": {
      fontSize: "18px",
    }
  },
  backIcon: { padding: '7px 7px 7px 0px', height: '21px', width: '24px', cursor:"pointer" },
  uploadIcon:{ color: '#94A3B8', fontSize: 20 },
  containerBox: { width: '100%', display: 'flex', justifyContent: 'center' },
  finishLaterText: {
    fontFamily: 'Montserrat, sans-serif',
    fontWeight: 400,
    fontSize: "16px",
    color: "#0F172A",
    display: "inline"
  },
  linkStyle: {
    fontFamily: 'Montserrat, sans-serif',
    display: "inline",
    fontWeight: 700,
    fontSize: "16px",
    color: "#0074BF",
    textDecoration: "none",
    marginLeft: "7px"
  },
  timeInstructionText: {
    margin: '15px 0px',
    fontFamily: 'Montserrat, sans-serif',
    fontWeight: 400,
    fontSize: "16px",
    color: "#0F172A",
    "@media (max-width: 414px)": {
      fontSize: "14px",
    }
  },
  backIconStyle :{ 
    padding: '7px', 
    height: '21px', 
    width: '24px' ,
    "@media (max-width: 414px)": {
      padding: '7px 7px 7px 0px',
    }
  },
  examCostContainer :{
    width: "100%",
    display: 'flex', 
    justifyContent: 'space-between',
    "@media (max-width: 600px)": {
     display: "block"
    }
  },
  examCost :{
    width: '300px',
    "& .Mui-error":{margin:"3px 0px"},
    "@media (max-width: 600px)": {
      width: "100%",
    }
  },
  paymentMethod :{
    width: '300px',
    marginLeft: '20px',
    "& .Mui-error":{margin:"3px 0px"},
    "@media (max-width: 600px)": {
      width: "100%",
      marginLeft: '0px',
    }
  },
  inputWrapper: {
    position: "relative",
    width: "100%",
    marginBottom:"10px",
    "& .Mui-error":{margin:"3px 0px"},
  },
  inputWrapperInstruction: {
    position: "relative",
    width: "100%",
    marginBottom:"10px",
    marginTop:"15px",
    "& .Mui-error":{margin:"3px 0px"},
  },
  label: {
    fontWeight: 600,
    marginTop: "3px",
    fontSize: "16px",
    color: "#334155",
  },
  container: {
    gap: 2, display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
  },
  fileList: {
    width: '470px',
    gap: 1, display: 'flex',
    flexDirection: 'row',
    flexWrap:"wrap",
    alignItems:"baseline",
    "@media (max-width: 414px)": { display: "block"}
  },
  fileItem: {
    borderRadius: '20px',
    backgroundColor: '#F8FAFC',
    flexDirection: 'row', display: 'flex',
    alignItems: 'center',
    border: '1px solid #F1F5F9',
    padding: '8px',
    width: '210px',
    "@media (max-width: 414px)": { width: "100%" }
  },
  fileIcon: {
    borderRadius: '8px',
    marginRight: '15px',
    width: '30px',
    backgroundColor: '#E0F2F8',
    height: '30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

  },
  fileInfo: {flex: 1},
  fileName: {
    fontWeight: 500,fontSize: '14px',
    color: '#334155',
  },
  fileSize: {
    color: '#64748B',fontSize: '12px',
  },
  uploadButton: {
    backgroundColor: '#E0F2FE',
    color: '#0EA5E9',
    cursor: 'pointer',fontWeight: 500,
    padding: '8px 16px',borderRadius: '8px',
    fontSize: '14px',
    
    '&:hover': {
      backgroundColor: '#BAE6FD',
    },
  },
  uploadFileBox: {
    display: 'flex',
    "@media (max-width: 414px)": { display: "block"}
  },
  uploadBtnBox : { justifySelf: 'end',
    "@media (max-width: 414px)": {
      display: "flex",
      justifySelf: "center",
      width: "100%",
    } 
  }
};

const BoxContainer = styled(Box)({
   "& .activeButtonStyle": {
    border: "none",
    borderRadius: "20px",
    backgroundColor: "#0074BF",
    height: "50px",
    marginTop: "25px",
    marginBottom: "20px",
    color: "white",
    textTransform: "none",
    cursor: "pointer",
    fontWeight: 700,
    fontSize:"16px",
    width: '360px',
    "@media (max-width: 414px)": { width: "300px",}
  },
   "& .buttonStyle": {
    height: "50px",
    fontWeight: 700,
    border: "none",
    borderRadius: "20px",
    width: '360px', fontSize:"16px",
    textTransform: "none",
    color: "white",
    backgroundColor: "#B2D5EC",
    marginTop: "25px",
    marginBottom: "20px",
    "@media (max-width: 414px)": { width: "300px"}
  },
})

export default AccountSetupStep3;

// Customizable Area End
