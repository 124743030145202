import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { toast } from "react-toastify";

interface ResendOtp {
    message: string
}

interface ErrorToken {
    token: string;
}
interface ErrorToken {
    otp: string;
}

interface ErrorResponse {
    errors: ErrorToken[];
}
interface OtpMessage {
    otp: string;
}

interface OtpResponse {
    messages: OtpMessage[];
}
// Customizable Area End

const configJSON = require("./config");
export interface Props {
    // Customizable Area Start
    navigation: any;
    id: string;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    emailOtp: string;
    resendOtp: boolean;
    welcomeCard: boolean;
    emailOtpError: boolean;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    // Customizable Area End
}

export default class ApplicantAccountStatusController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    ResendFtnOtpApiCallId: string = "";
    verifyOtpApiCallId: string = "";
    // Customizable Area End    

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.NavigationPropsMessage),
        ];
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        this.state = {
            emailOtp: "",
            resendOtp: false,
            welcomeCard: false,
            emailOtpError: false
        };

        // Customizable Area End
    }

    // Customizable Area Start
    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson && !responseJson.errors) {
                this.responseSucessCell(apiRequestCallId, responseJson)
            }
            else if (responseJson && responseJson.errors) {
                this.responseFailureCell(apiRequestCallId, responseJson)
            }
        }
    }

    setOtp = (value: string) => {
        if (value.length == 6) {
            this.setState({
                emailOtp: value,
                emailOtpError: false
            }, () => this.verifyOtpApi());
        } else {
            this.setState({
                emailOtp: value,
                emailOtpError: false
            });
        }
    };

    ftnAccountStatusApiCall = async (valueData: {
        contentType?: string;
        method?: string;
        endPoint?: string;
        body?: {};
        apiType?: string;
        type?: string;
    }) => {
        const token = await (localStorage.getItem('accessToken'))
        let { contentType, method, endPoint, body, apiType } = valueData;
        let header = {
            "Content-Type": contentType,
            Token: token
        };
        let ftnAccountStatusRequestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        ftnAccountStatusRequestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        ftnAccountStatusRequestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        body &&
            ftnAccountStatusRequestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                apiType === "" ? JSON.stringify(body) : body
            );
        ftnAccountStatusRequestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        runEngine.sendMessage(ftnAccountStatusRequestMessage.id, ftnAccountStatusRequestMessage);
        return ftnAccountStatusRequestMessage.messageId;
    };

    responseSucessCell = async (apiRequestCallId: string, responseJson: ResendOtp & OtpResponse) => {
        if (apiRequestCallId === this.ResendFtnOtpApiCallId) {
            this.ResendFtnOtpSucessCallBack(responseJson);
        }
        if (apiRequestCallId === this.verifyOtpApiCallId) {
            this.verifyOtpSucessCallBack(responseJson);
        }
    };

    responseFailureCell = async (apiRequestCallId: string, responseJson: ErrorResponse) => {
        if (apiRequestCallId === this.ResendFtnOtpApiCallId) {
            this.ResendFtnOtpFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.verifyOtpApiCallId) {
            this.verifyOtpFailureCallBack(responseJson);
        }
    };

    resendFtnOtpApi = async () => {
        this.setState({resendOtp: true })
        let token = localStorage.getItem('accessToken');
        const body = {
            data: {
                attributes: {
                    token: token
                }
            }
        }
        this.ResendFtnOtpApiCallId = await this.ftnAccountStatusApiCall({
            contentType: configJSON.contentTypeApiAddDetail,
            method: configJSON.apiMethodTypeAddDetail,
            endPoint: configJSON.resendOtpEndPoint,
            body: JSON.stringify(body),
        });
    };

    ResendFtnOtpSucessCallBack = (response: ResendOtp) => {
        if(response){
            this.setState({ resendOtp: false , emailOtp: "",emailOtpError: false})
        }
    }

    ResendFtnOtpFailureCallBack = (response: ErrorResponse) => {
        toast.error(response.errors[0].token)
        this.setState({resendOtp: false})
    };

    verifyOtpApi = async () => {
        this.setState({resendOtp: true})
        let token = localStorage.getItem('accessToken');
        const body = {
            token: token,
            otp_code: this.state.emailOtp
        }

        this.verifyOtpApiCallId = await this.ftnAccountStatusApiCall({
            contentType: configJSON.contentTypeApiAddDetail,
            method: configJSON.apiMethodTypeAddDetail,
            endPoint: configJSON.verifyOtpEndPoint,
            body: JSON.stringify(body),
        });
    };

    verifyOtpSucessCallBack = (response: OtpResponse) => {
        if (response) {
            this.setState({ welcomeCard: true, emailOtpError: false, resendOtp: false })
        }
    }

    verifyOtpFailureCallBack = (response: ErrorResponse) => {
        if (response.errors[0].token) {
            toast.error(response.errors[0].token)
        } else {
            this.setState({ emailOtpError: true,resendOtp: false })
        }
    };

    navigateToProfile = (pathName: string) => {
        const msgName: Message = new Message(getName(MessageEnum.NavigationMessage));
        msgName.addData(getName(MessageEnum.NavigationTargetMessage), pathName);
        msgName.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
        msgName.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
        this.send(msgName);
      };

    applicantLogoutBtn = () => {
        localStorage.removeItem("role");
        localStorage.removeItem("accessToken");
       this.navigateToProfile("Login")
    };

    async componentDidMount() {
        super.componentDidMount();
        if (localStorage.getItem("isAuthenticated") === "true") {
            window.history.pushState(null, "", window.location.href);
            window.addEventListener("popstate", this.handleBack);
        }
      }

      async componentWillUnmount() {
        window.removeEventListener("popstate", this.handleBack);
      }
    
      handleBack = () => {
        window.history.pushState(null, "", window.location.href);
      };
    
    // Customizable Area End
}