export const searchIcon = require("../assets/search.png");
export const flightTakeOffIcon = require("../assets/take_off_icon.png");
export const button_close = require("../assets/button_close.png");
export const back_button = require("../assets/back_button.png");
export const delete_icon = require("../assets/delete_icon.png");
export const flighted = require("../assets/flighted.png");
export const add_icon = require("../assets/button_spec.png");
export const right_arrow = require("../assets/right_arrow.png");
export const delete_tests = require("../assets/delete_tests.png");
export const group_left = require("../assets/group_left.png");
export const warning = require("../assets/warning.png");
export const greenTick = require("../assets/greenTick.png");
export const cross = require("../assets/cross1.png");
export const cross_icon = require("../assets/cross_icon.png");
export const checkedBox = require("../assets/checked.png");
export const unCheckedBox = require("../assets/unchecked.png");
export const deleteIcon = require("../assets/deleteIcon.png");
export const calenderIcon = require("../assets/calenderIcon.png");
export const processIcon = require("../assets/processIcon.png");
export const deletedTestsIcon = require("../assets/deleted_tests.png");
export const flightTicketIcon = require("../assets/flightTicket.png");
export const button_restore = require("../assets/button_restore.png");
export const restoreIcon = require("../assets/restore.png");
export const deletered = require("../assets/deletered.png");
export const oneEdit = require("../assets/oneEdit.png");
export const editOne = require("../assets/editOne.png");
export const preveiw = require("../assets/preveiw.png");
export const renameTick = require("../assets/renameTick.png");

export const renameCross = require("../assets/renameCross.png");




