import React from "react";
// Customizable Area Start
import {
    Grid,
    Box,
    styled
} from "@mui/material";
import { loginLogo } from "./../../email-account-login/src/assets";
import { bgImage } from "./../../email-account-registration/src/assets";
import AccountSetupStep1 from "./AccountSetupStep1"
import AccountSetupStep2 from "./components/AccountSetupStep2"
import AccountSetupStep3 from "./components/AccountSetupStep3"
import AccountSetUpController, { Props } from "./AccountSetUpWebController";
import HorizontalStepper from "./components/HorizontalStepper";
import LogOutButton from './components/LogOutButton'

export default class AccountSetUp extends AccountSetUpController {
    constructor(props: Props) {
        super(props);

    }

    render() {
        return (
            <>
                <div className="Toastify"></div>
                <CustomBoxContainer>
                    <Grid container direction={'row'} className="mainGridContainer">
                        <Grid item xs={12} className="gridContainer">
                            <Grid sx={{
                                height: "90px", width: "100%",
                                backgroundColor: '#daf8ff', padding: '0px 15px',
                                position:"fixed",
                                display: 'flex', justifyContent: 'space-between', alignItems: 'center', whiteSpace: 'nowrap', zIndex:"1000"
                            }}>
                                <Box sx={webStyle.topHeader}>
                                <img src={loginLogo}  style={{ padding: '7px', height: '50px', width: '118',objectFit: "cover" }} alt="Login Logo" />
                                </Box>
                                <LogOutButton data-testId = "logoutButton" handleLogout = {this.handleLogout}/>
                            </Grid>
                            <Grid  sx={
                                {
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: 'auto',
                                    backgroundColor: '#ffffff',
                                    backgroundImage: `url(${bgImage})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat',
                                    marginTop:"90px",
                                    paddingBottom:"110px"
                                }
                            }>
                                <HorizontalStepper
                                    activeStep={this.state.activeStep}
                                    totalSteps={3}
                                >
                                    <AccountSetupStep1
                                        data-testId ='AccountSetupStep1'
                                        formValues={this.state?.step1Form}
                                        options={this.state?.step1Form.options}
                                        onSubmit={this.postAirportsRequest}
                                        onChangeItems={this.handleStep1Change}
                                    />
                                    <AccountSetupStep2
                                    data-testId ='AccountSetupStep2'
                                        formValues={this.state.step2Form}
                                        onSubmit={this.handleSubmitStep2}
                                        onChangeItems={this.handleStep2Change}
                                        handleAccordionChange={this.handleAccordionChange}
                                        handlePrevious={this.handlePrevious}
                                        deleteDesignationRequest={this.deleteDesignationRequest}
                                        getlabels={this.getlabels}
                                        deleteAircraftTest={this.deleteAircraftTest} 
                                        isMobile = {this.state.isMobile}
                                        addAircraft = {this.handleAircraft}  
                                        removeDesignation={this.removeDesignation}
                                        />
                                    
                                    <AccountSetupStep3
                                        data-testId = 'AccountSetupStep3'
                                        formValues={this.state.step3Form}
                                        onSubmit={this.handleSubmit}
                                        handleFileSelect={this.handleFileSelect}
                                        onChangeItems={this.handleStep3Change}
                                        handlePrevious={this.handlePrevious}
                                        onInputHandleChange={this.handleStep3InputChange}
                                        validateForm={this.validateForm}
                                        errors={this.state.step3Form.errors}
                                    />
                                </HorizontalStepper>
                            </Grid>
                        </Grid>
                    </Grid >
                </CustomBoxContainer>
            </>
        );
    }
}

const CustomBoxContainer = styled(Box)({
    "& .mainGridContainer": {
        width: '100%',
        height: '100%',
        display: 'flex',
            flexDirection: "column",
            overflowY: "hidden"
    },
    "& .gridContainer": {
        height: '100vh',
        display: 'flex',
        flexDirection: "column",
    }
})

const webStyle = {
    topHeader: {
        height: '100%',
        width: '118',
        objectFit: "cover",
        marginLeft: "100px",
        display: "flex",
        alignItems: "center",
        "@media (max-width: 600px)": {
            marginLeft: "0px",
        }
    },
    inputWrapper: {
        position: "relative",
        width: "100%",
        maxWidth: "360px",
        mb: 1
    },
    recoveryButton: {
        height: "50px",
        width: "360px",
        marginTop: "25px",
        marginBottom: "20px",
        padding: '10px 20px',
        border: "2px solid #0074BF",
        backgroundColor: "#ffffff",
        color: "#0074BF",
        borderRadius: '20px',
        cursor: "pointer",
        fontWeight: 700,
        fontSize: "16px",
        textTransform: "none"
    },
    mainWrapper: {
        paddingBottom: "30px",
        display: "flex",
        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
        flexDirection: "column",
        alignItems: "center",
        background: "#fff",
        justifyContent: "center",
        height: "100vh",
    },
    buttonStyle: {
        height: "50px",
        width: "360px",
        marginTop: "25px",
        marginBottom: "20px",
        border: "none",
        borderRadius: "20px",
        backgroundColor: "#0074BF",
        color: "white",
        cursor: "pointer",
        fontWeight: 700,
        textTransform: "none"
    },
    label: {
        fontSize: "16px",
        fontWeight: 600,
        marginTop: "3px",
        color: "#334155"
    },
    formWrapper: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        borderRadius: '26px',
        border: '1px solid rgb(252, 250, 250)',
        backgroundColor: 'rgb(255, 255, 255)',
        padding: '30px'
    },
};
// Customizable Area End
