export const plusIcon = require("../assets/plusIcon.png");
export const calendar1 = require("../assets/calendar1.png");
export const left = require("../assets/left.png");
export const right = require("../assets/right.png");
export const calendar2 = require("../assets/calender2.png");
export const est = require("../assets/est.png");
export const day = require("../assets/day.png");
export const day3 = require("../assets/day3.png");
export const month = require("../assets/month.png");
export const schedule = require("../assets/schedule.png");
export const week = require("../assets/week.png");
export const upArrow = require("../assets/up.png");
export const downArrow = require("../assets/down.png");
export const checkedBox = require("../assets/checked.png");
export const unCheckedBox = require("../assets/unchecked.png");
export const darkCal = require("../assets/darkCal.png").default;
export const greenTick = require("../assets/greenTick.png");
export const cross = require("../assets/cross1.png");
export const leftArrow = require("../assets/leftArrow.png");
export const openForBooking = require("../assets/open_for_booking.png");
export const booked = require("../assets/booked.png");
export const expired = require("../assets/expired.png");
export const completed = require("../assets/completed.png");
export const missed = require("../assets/missed.png");
export const canceled = require("../assets/canceled.png");
export const deleteIcon = require("../assets/delete.png");
export const add = require("../assets/add.png");
export const remove = require("../assets/remove.png");
export const filter = require("../assets/filter.png");
export const airplane = require("../assets/airplane.png");
export const take_off_airplane = require("../assets/take_off.png");
export const button_edit = require("../assets/button_edit.png");
export const newImg = require("../assets/newImg.png");
export const air1 = require("../assets/air1.png");
export const air2 = require("../assets/air2.png");
export const air3 = require("../assets/air3.png");
export const avatar = require("../assets/avatar.png");
export const calender2 = require("../assets/calender2.png");
export const dpe_icon = require("../assets/dpe_icon.png"); 
export const radio_selected = require("../assets/radio_selected.png");
export const radio_unselected = require("../assets/radio_unselected.png");
export const payment = require("../assets/payment.png");
export const visacard = require("../assets/visacard.png");
export const addIcon = require("../assets/add_icon.png");
export const button_close = require("../assets/button_close.png");
export const card = require("../assets/card.png");
export const pdf_icon = require("../assets/pdf2.png");
export const money = require("../assets/money.png");
export const payment_success = require("../assets/payment_success.png");
export const payment_failed = require("../assets/payment_failed.png");
export const query = require("../assets/query.png");
export const crossButton = require("../assets/crossButton.png");
export const heighlightCal = require("../assets/heighlightCal.png");
export const greenCross = require("../assets/greenCross.png");
export const filtered = require("../assets/filtered.png");
export const homeIcon = require("../assets/homeIcon.png");
export const blueCal = require("../assets/blueCal.png");
export const messageIcon = require("../assets/messageIcon.png");



