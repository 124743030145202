// Customizable Area Start
import React from "react";
import {
    Box,
    Typography,
    styled,
    TextField,
    Autocomplete,
    InputAdornment,
    Dialog,
    DialogContent,
    IconButton,
    DialogTitle,
    Button
  } from "@mui/material";
import CustomisableUserProfilesController, { Option, Props } from "../CustomisableUserProfilesController";
import { back_button, button_close, delete_icon, flightTakeOffIcon, searchIcon } from "../assets";
import { Link } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";

export default class SettingsAirports extends CustomisableUserProfilesController {
  constructor(props: Props) {
    super(props);
  }

  renderAirportsList(){
    const { settingsAirports } = this.state
    if(settingsAirports.length <= 0) return
    return (
      <>
        {settingsAirports.map((airport) => (
          <Box key={airport.service_subcategory_id}>
            <Box className="airportContainer">
              <Box className="airportIconContainer">
                <img src={flightTakeOffIcon} alt="" />
                <Typography className="airportName">{airport.service_subcategory_name}</Typography>
              </Box>
              <Box data-test-id="delete-icon" onClick={() => this.setState({selectedAirportToDelete: airport, deleteAirportDialogOpen: true})} className="pointer"><img src={button_close} alt="" /></Box>
            </Box>
            <Box className="border" />
          </Box>
        ))}
      </>
    )
  }

  render() {
    const { airportOptions, settingsAirports, inputValue, isOpen, selectedAirportToDelete, deleteAirportDialogOpen, isEditAirportSettingsOpen} = this.state
    const selectedOptions = airportOptions?.filter((option: any) => {
      return settingsAirports?.includes(option.id)
    });
    return(
        <CustomSettingsAirportsContainer>
            <Box sx={webStyle.headerContainer} data-test-id = "test-details-page">
                <Box sx={webStyle.pageHeader}>
                   {isEditAirportSettingsOpen && 
                      <Link to={"/calendar"}> 
                        <img data-test-id = "back-to-create-availability" 
                            onClick={() => this.handleBackToCreateAvailability()} 
                            src={back_button} 
                            style={webStyle.leftArrow} />
                      </Link>}
                   <Typography sx={webStyle.headerText}>Airports settings</Typography>
                </Box>
            </Box>

            <Box style={{padding:'50px 50px 50px 50px'}} >
                    <Box sx={webStyle.contentContainer} >
                        <Typography className="airportsText">Airports</Typography>
                        <Autocomplete
                            getOptionLabel={(option: Option) => option?.name}
                            value={selectedOptions}
                            onInputChange={this.handleAirportInputChange}
                            clearOnBlur={false}
                            onChange={this.handleChange}
                            inputValue={inputValue}
                            multiple
                            options={airportOptions}
                            open={isOpen}
                            onClose={() => this.setState({ isOpen: false })}
                            noOptionsText={<Typography>No results found for '{inputValue}'</Typography>}
                            renderOption={(props, option) => (
                                <Box
                                    component="li"
                                    sx={webStyle.option}
                                    {...props}
                                > 
                                    {option.name} 
                                </Box>
                            )}
                            sx={webStyle.autoCompleteStyle}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder={"Add new airports to your list"}
                                    variant="outlined"
                                    InputProps={{
                                        ...params.InputProps,
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <img src={searchIcon} alt="search" />
                                            </InputAdornment>
                                        ),
                                        endAdornment: null,
                                        sx: webStyle.searchInput,
                                    }}
                                />
                            )}
                            renderTags={() => null}
                        />
                        <Typography className="airportsTitle">Airports You Serve</Typography>
                        <Box className="border" />

                        {this.renderAirportsList()}
                    </Box>
            </Box>

            <Dialog open={deleteAirportDialogOpen}
              onClose={() => { 
              this.setState({ deleteAirportDialogOpen: false })
              }} maxWidth="sm" fullWidth PaperProps={{style: { borderRadius: 16, width: "600px", overlay:"none"}}} >
              <CustomDeleteTitle>
                <img className="deleteIcon" src={delete_icon} alt="delete" />
                    Delete airport?
                <IconButton
                  data-test-id="close-icon-delete"
                  aria-label="close"
                  onClick={() => this.setState({ deleteAirportDialogOpen: false })}
                  sx={{ position: 'absolute', right: 8, top: 18, }}
                >
                  <CloseIcon sx={webStyle.icons} />
                </IconButton>
              </CustomDeleteTitle>
              <DialogContent sx={webStyle.deleteDialogContent} >
                <Typography sx={webStyle.deleteText}>
                  Are you sure you want to remove the <span style={webStyle.airportName}>{`${selectedAirportToDelete.service_subcategory_name}`}</span> airport? Any tests already booked will remain unaffected and you can re-add it anytime.
                </Typography>
                <Box sx={webStyle.border} />
                <Box sx={webStyle.actionsContainer}>
                    <CustomButton
                      data-test-id="cancel-delete"
                      variant="outlined"
                      sx={webStyle.deleteCancelButton}
                      onClick={() => this.setState({deleteAirportDialogOpen: false})}>
                        Cancel
                    </CustomButton>
                    <CustomButton
                      data-test-id="delete-airport"
                      variant="contained"
                      color="primary"
                      sx={webStyle.deleteButton}
                      onClick={() => this.handleRemoveAirport(selectedAirportToDelete.id)}
                      >
                        Yes, delete airport
                    </CustomButton>
                </Box>
              </DialogContent>
             </Dialog>
        </CustomSettingsAirportsContainer>
    )
  }
}

const CustomSettingsAirportsContainer = styled(Box)({
    "& .airportsText":{
        fontFamily: "Montserrat",
        fontWeight: 600,
        fontSize: "16px",
        color: "#334155",
    },
    "& .airportName":{
        fontFamily: "Montserrat",
        fontWeight: 500,
        fontSize: "16px",
        color: "#0F172A",
        marginLeft:"20px"
    },
    "& .airportsTitle":{
        fontFamily: "Montserrat",
        fontWeight: 600,
        fontSize: "14px",
        color: "#94A3B8",
        margin:"20px 0px 10px 25px",
        textTransform:"uppercase"
    },
    "& .airportIconContainer":{
      display:"flex",
      justifyContent:"space-between",
    },
    "& .airportContainer":{
      margin:"20px 25px 20px 25px",
      display:"flex",
      justifyContent:"space-between"
    },
    "& .border":{
      borderBottom:"1px solid #E2E8F0",
    },
    "& .pointer":{
      cursor:"pointer"
    },
})

const webStyle = {
    pageHeader: {
        display:'flex',
        alignItems:'center',
        gap:'20px'
    },
    headerContainer: {
        display: "flex",
        height: "60px",
        padding: "10px 50px 10px 50px",
        alignItems: "center",
        background: "#ffffff",
        justifyContent:"space-between"
    },
    headerText: {
        fontFamily: "Montserrat",
        fontWeight: 700,
        fontSize: "22px",
        color: "#0074BF",
    },
    airportName:{
      fontFamily: "Montserrat",
      fontWeight: 500,
      fontSize: "16px",
      color: "#0F172A",
    },
    contentContainer: {
        padding: "20px 50px 50px 50px",
        alignItems: "center",
        background: "#ffffff",
        justifyContent:"space-between"
    },
    option: {
      padding: '10px 3px 10px 3px',
      '&:hover': {
          backgroundColor: '#E0F2F8',
          cursor: 'pointer',
      },
      fontFamily: 'Montserrat, sans-serif',
      fontSize: '16px',
      lineHeight: '24px',
    },
    autoCompleteStyle:{
      height: '50px',
      padding: '5px 0px 10px 0px',
      '& .MuiInputBase-root': {
          border: '1px solid #CBD5E1',
          '&:hover': {
              border: '1px solid blue',
              borderColor: 'darkgray',
          },
      },
    },
    searchInput: {
      "& .css-wb57ya-MuiFormControl-root-MuiTextField-root":{
      width:"90%"
      },
      '& .MuiOutlinedInput-notchedOutline': {
          border: 'none',
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
          border: 'none',
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          border: 'none',
      },
      '&::placeholder': {
          color: '#94A3B8',
          opacity: 1,
      },
      borderRadius: '20px',
      "@media (max-width: 414px)": {
          width:"90%"
      }
    },
    leftArrow:{ 
      marginRight: "10px", 
      height: '24px', 
      width: '24px', 
      color: "#0074BF" 
    },
    deleteDialogContent:{ padding: "0px"},
    icons:{color:"#94A3B8", cursor: "pointer"},
    deleteText: {
      margin:"25px 45px",
      color:"#0F172A",
      fontFamily: "Montserrat",
      fontWeight: 400,
      fontSize: "16px",
    },
    actionsContainer:{
      display:"flex",
      justifyContent:"space-between",
      padding: "20px 25px 25px 25px"
    },
    border:{
      borderBottom:"1px solid #E2E8F0",
    },
    deleteCancelButton: {
      padding: "0px",
      color:"#0074BF",
      height: "50px",
      fontFamily: "Montserrat",
      fontWeight: 700,
      textTransform: "none",
      fontSize: "16px",
      borderRadius: "20px",
      width:"265px",
      border:"2px solid #0074BF"
    },
    deleteButton: {
      height: "50px",
      fontSize: "16px",
      padding: "0px 15px",
      fontWeight: 700,
      fontFamily: "Montserrat",
      textTransform: "none",
      borderRadius: "20px",
      color: "#ffffff",
      background: "#0074BF",
      width:"265px",
    },
}

const CustomDeleteTitle = styled(DialogTitle)({
    border:"1px solid #E2E8F0",
    fontSize: 22,
    fontWeight: "700",
    textAlign: 'left',
    color: "#001C55",
    fontFamily: "Montserrat",
    display: "flex",
    alignItems: "center",
    margin:"0px",
    padding:"16px 40px",
    "& .deleteIcon":{
      marginRight:"20px"
    }
})

const CustomButton = styled(Button)({
    textTransform: "capitalize",
    fontWeight: 700,
    fontSize: 16,
    width: 258,
    borderRadius: 20
})
// Customizable Area End
