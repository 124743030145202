import React from 'react';
import {
  Autocomplete,
  Chip,
  TextField,
  Box,
  Checkbox,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'; // Import ExpandMoreIcon

interface Option {
  id: string;
  name: string;
}
interface AircraftTypeItem {
  id: string;
  value: string;
}

interface MultipleSelectWithChipsProps {
  options: Option[];
  label?: string;
  placeholder?: string;
  selectedItems?: string[];
  onItemsChange: (name: string, items: string[]) => void;
  name: string;
  popUpHandler: (type: string, itemToDelete: any) => void;
  addedAirCraftTypes : AircraftTypeItem[];
}

interface MultipleSelectWithChipsState {
  selectedItems: Option[];
}

class MultipleSelectWithCheckBox extends React.Component<MultipleSelectWithChipsProps, MultipleSelectWithChipsState> {
  constructor(props: MultipleSelectWithChipsProps) {
    super(props);
    this.state = {
      selectedItems: [],
    };
  }

  handleChange = (event: any, value: Option[]) => {
    // event.preventDefault()
    const selectedIds: string[] = value.map(item => item.id);
    this.props.popUpHandler(this.props.name, selectedIds)
  };

  handleDelete = (itemToDelete: Option) => {
    const updatedItems = this.props.selectedItems?.filter(id => id !== itemToDelete.id) || [];
    this.props.popUpHandler(this.props.name, updatedItems)
  };
  
  getSelectedOptions = () => {
    const { options, selectedItems } = this.props;
    return options.filter(option => selectedItems?.includes(option.id)) || [];
  };
  
  handleCheckboxClick = (event:any, option: Option, selected: boolean) => {
    if (selected) {
      localStorage.setItem("selected","true")
      const updatedItems = this.props.selectedItems?.filter(id => id !== option.id) || [];
      if(this.props.addedAirCraftTypes.length > 0 ){
        this.props.popUpHandler(this.props.name, updatedItems)
      }else{
        this.props.onItemsChange(this.props.name, updatedItems);
      }
    } else {
      localStorage.setItem("selected","false")
      const updatedItems = [...(this.props.selectedItems || []), option.id];
      this.props.onItemsChange(this.props.name, updatedItems);
    }
  };
  render() {
    const { options, placeholder } = this.props;
    const selectedOptions = this.getSelectedOptions();
    return (
      <Box>
        <Autocomplete
         popupIcon={<ExpandMoreIcon style={{color: "#94A3B8"}} />}
         clearIcon={null}
         data-testId = "changeInputChackbox"
          multiple
          disableCloseOnSelect
          placeholder={placeholder} 
          options={options}
          getOptionLabel={(option) => option.name}
          value={selectedOptions}
          onChange={this.handleChange}
          sx={{
            '& .MuiInputBase-root': {
              border: '1px solid #CBD5E1',
              borderRadius: '25px',
              paddingRight: '30px !important',
              '&:hover': {
                border: '1px solid #0074BF',
              },
            },
            width: '100%',
            height: '50px',
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={this.props.placeholder}
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                
              }}
              sx={{
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
                '&::placeholder': {
                  color: '#94A3B8',
                  opacity: 1,
                },

                '& .MuiOutlinedInput-root': {
                  paddingRight: 0,
                },
                "@media (max-width: 414px)": {
                  width: "90%"
                }
              }}
            />
          )}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                checked={selected}
                onClick={(event) => this.handleCheckboxClick(event, option, selected)}
              />
              <Typography>{option.name}</Typography>
            </li>
          )}
          renderTags={() => null}
        />

        <Box sx={{ marginTop:"22px" }}>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: "10px" }}>
            {selectedOptions.map((value) => (
              <Chip
                sx={{
                  color: '#001C55',
                  height: '32px',
                  backgroundColor: 'E0F2F8',
                  '& .MuiChip-deleteIcon': {
                    height: '16px',
                    width: '16px',
                    color: "#001C55"
                  },
                }}
                key={value.id}
                label={value.name}
                onDelete={() => this.handleDelete(value)}
                deleteIcon={<CloseIcon />}
              />
            ))}
          </Box>
        </Box>
      </Box>
    );
  }
}

export default MultipleSelectWithCheckBox;
