import React from "react";
// Customizable Area Start
import {
  Box,
  Typography,
  InputLabel,
  Button,
  TextField,
  Grid,
  Checkbox,
  IconButton,
  Dialog, DialogTitle, DialogContent, DialogContentText
} from "@mui/material";
import { Link } from 'react-router-dom';
import { Visibility, VisibilityOff } from "@mui/icons-material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import SignupController, { Props } from "./SignupController.web";
import { loginLogo, loginImage } from "./assets";


import {styled} from "@mui/material";
import { leftArrow,dpeImage } from "./assets";
import PrivacyPolicyModal from "./components/PrivacyPolicyModal";
import TermsAndConditionsModal from "./components/TermsAndConditionsModal";
// Customizable Area End

export default class Signup extends SignupController {
  constructor(props: Props) {
    super(props);
     // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { handleInputChange, togglePasswordVisibility, handleSubmit, setPrivacyPolicyOpen, setTermsOpen } = this;
    const { name, dms,ftn, phone, email, password, confirmPassword, consent, showPassword, showConfirmPassword, privacyPolicyOpen, termsOpen, errors } = this.state;
    return (
      <>
        <div className="Toastify"></div>
       <SignUpContainer > 
        <Grid container  style={{ width: "100%",
    height: "100%",}}>
          <Grid item xs={12} sm={12} md={6} sx={{
            height: "100%",
          }}>       
                <img src={loginLogo} style={{ width: "150px", height: "61px", padding: "32px 0px 0px 40px" }} alt="Login Logo" />
                <Box className="SignUpFormContainer">
                <form onSubmit={handleSubmit} data-testid="signup-form">
                  <Box sx={webStyle.formWrapper} >
                    <Box className= "formHeader">
                      <span onClick={this.goBack} data-testId="backBtn">
                        <img src={leftArrow} style={{ margin: '7px 0px', height: '19px', width: '19px' }} alt="left-arrow" />
                      </span>
                      <Typography variant="h6" sx={{
                        fontFamily: 'Montserrat, sans-serif',
                        fontWeight: 700, fontSize: "22px", color: "#001C55",
                      }}
                      >
                        Start Scheduling Now!
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={webStyle.formWrapper}>


                    <Box sx={webStyle.inputWrapper}>
                      <InputLabel style={webStyle.label}>Name</InputLabel>
                      <TextField
                        name="name"
                        value={name}
                        onChange={(e) => handleInputChange("name", e.target.value)}
                        fullWidth
                        variant="outlined"
                        placeholder="Enter your full name"
                        sx={webStyle.textFieldStyle}
                        InputProps={{
                          style: webStyle.inputStyle
                        }}
                        data-testId="name"
                        error={!!errors.name}
                        helperText={errors.name}
                      />
                    </Box>

                    <Box sx={webStyle.inputWrapper}>
                      <InputLabel style={webStyle.label}>
                        {this.role == 'dpe' ? 'DMS Number' : 'FTN'}
                      </InputLabel>
                      <TextField
                        name="dms"
                        value={this.role == 'dpe' ? dms :ftn}
                        data-testId="dms"
                        onChange={(e) => handleInputChange(this.role == 'dpe'? "dms": "ftn", e.target.value)}
                        fullWidth
                        variant="outlined"
                        placeholder={this.role === "dpe" ? "Enter your DMS Number" : "Enter your Flight Training Number"}
                        sx={webStyle.textFieldStyle}
                        InputProps={{
                          style: webStyle.inputStyle
                        }}
                        error={this.role == 'dpe'?!!errors.dms:!!errors.ftn}
                        helperText={this.role == 'dpe'?errors.dms:errors.ftn}
                      />
                    </Box>

                    <Box sx={webStyle.inputWrapper}>
                      <InputLabel style={webStyle.label}>Phone Number</InputLabel>
                      <TextField
                        name="phone"
                        value={phone}
                        onChange={(e) => handleInputChange("phone", e.target.value)}
                        fullWidth
                        variant="outlined"
                        type="tel"
                        data-testId="phone"
                        placeholder="555-123-4567"
                        sx={webStyle.textFieldStyle}
                        InputProps={{
                          style: webStyle.inputStyle,
                        }}
                        inputProps={{
                          maxLength: 12
                        }}
                        error={!!errors.phone}
                        helperText={errors.phone}
                      />
                    </Box>

                    <Box sx={webStyle.inputWrapper}>
                      <InputLabel style={webStyle.label}>Email</InputLabel>
                      <TextField
                        name="email"
                        value={email}
                        onChange={(e) => handleInputChange("email", e.target.value)}
                        fullWidth
                        data-testId="email"
                        variant="outlined"
                        type="email"
                        placeholder="john.doe@example.com"
                        sx={webStyle.textFieldStyle}
                        InputProps={{
                          style: webStyle.inputStyle
                        }}
                        error={!!errors.email}
                        helperText={errors.email}
                      />
                    </Box>

                    <Box sx={webStyle.inputWrapper}>
                      <InputLabel style={webStyle.label}>Password</InputLabel>
                      <TextField
                        name="password"
                        type={showPassword ? "text" : "password"}
                        value={password}
                        onChange={(e) => handleInputChange("password", e.target.value)}
                        fullWidth
                        variant="outlined"
                        data-testId="password"
                        placeholder="Enter a secure password"
                        sx={webStyle.textFieldStyle}
                        InputProps={{
                          style: webStyle.inputStyle,
                          endAdornment: (
                            <IconButton
                              onClick={() => togglePasswordVisibility('showPassword')}
                              edge="end"
                              data-testId="pass-icon"
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          ),
                        }}
                        error={!!errors.password}
                        helperText={errors.password}
                      />
                      {password && <Box sx={webStyle.passwordRequirements}>
                        {this.passwordRequirements.map((req, index) => (
                          <Box key={index} sx={webStyle.requirementItem}>
                            <CheckCircleIcon
                              sx={this.getPasswordRequirementIconStyle(req, password)}
                            />
                            <Typography sx={webStyle.requirementText}>
                              {req.text}
                            </Typography>
                          </Box>
                        ))}
                      </Box>}
                    </Box>

                    <Box sx={webStyle.inputWrapper}>
                      <InputLabel style={webStyle.label}>Confirm Password</InputLabel>
                      <TextField
                        name="confirmPassword"
                        type={showConfirmPassword ? "text" : "password"}
                        value={confirmPassword}
                        onChange={(e) => handleInputChange("confirmPassword", e.target.value)}
                        fullWidth
                        variant="outlined"
                        data-testId="confirmPass"
                        placeholder="Confirm your password"
                        sx={webStyle.textFieldStyle}
                        InputProps={{
                          style: webStyle.inputStyle,
                          endAdornment: (
                            <IconButton
                              onClick={() => togglePasswordVisibility('showConfirmPassword')}
                              edge="end"
                            >
                              {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          ),
                        }}
                        error={!!errors.confirmPassword}
                        helperText={errors.confirmPassword}
                      />
                    </Box>

                    <Box sx={webStyle.inputWrapper}>
                      <Box sx={webStyle.consentContainer}>
                        <Checkbox
                          name="consent"
                          checked={consent}
                          data-testId="consent"
                          onChange={(e) => handleInputChange("consent", e.target.checked)}
                          sx={webStyle.checkbox}
                        />
                        <Typography sx={webStyle.consentText}>
                          I hereby consent to the
                          <span
                            onClick={() => this.handleOpenTermCondition(4)}
                            style={{
                              ...webStyle.consentButton,
                              color: '#0F172A',
                              margin:"0px 3px",
                              cursor: "pointer"
                            }}
                          >
                            Privacy Policy
                          </span>
                          and
                          <Typography
                            onClick={() => this.handleOpenTermCondition(3)}
                            sx={{
                              ...webStyle.consentButton,
                              color: '#0F172A',
                              cursor: "pointer"
                            }}
                          >
                            Terms and Conditions
                          </Typography>
                        </Typography>
                      </Box>
                      {errors.consent && (
                        <Typography color="error" variant="caption">
                          {errors.consent}
                        </Typography>
                      )}
                    </Box>

                    <button
                      type="submit"
                      className ={ this.state.isValid ? "activeCreateAccountButton" : "createAccountButton"}
                      disabled={!this.state.isValid}
                    >
                      Create account
                    </button>

                    <Typography variant="body2" align="center"
                      sx={{
                        fontWeight: 400, fontSize: "16px"
                      }}
                    >
                      Already have an account?
                      <Link to="/Login" style={{
                        fontFamily: 'Montserrat, sans-serif', display: "inline",
                        fontWeight: 700, fontSize: "16px", color: "#0074BF", textDecoration: "none",
                        marginLeft: "7px"
                      }}>
                        Login
                      </Link >
                    </Typography>
                  </Box>
                </form>
              </Box>
          </Grid>
          <Grid item xs={12} sm= {12} md={6} padding={0} className="logoFramrDisplay">
            <img src={this.role == 'dpe'? dpeImage : loginImage} style={{ height: "100%", width: "100%" }} alt="Login" />
          </Grid>
          
        <PrivacyPolicyModal
          open={privacyPolicyOpen}
          onClose={() => setPrivacyPolicyOpen(false)}
          description= {this.state.termAndCondition}
          loader = {this.state.loader}
        />

        <TermsAndConditionsModal
          open={termsOpen}
          onClose={() => setTermsOpen(false)}
          description= {this.state.termAndCondition}
          loader= {this.state.loader}
        />
        </Grid>
        </SignUpContainer>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  inputWrapper: {
    position: "relative",
    width: "100%",
    maxWidth: "360px",
    mb: 1
  },
  formWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  label: {
    fontSize: "16px",
    fontWeight: 600,
    marginTop: "2px",
    color: "#334155"
  },
  textFieldStyle: {
    "&.Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderWidth: "1px",
      },
    },
    backgroundColor: "#ffffff",
    color: "#94A3B8",
    mb: "3px",
  },
  inputStyle: {
    borderRadius: '20px',
    fontWeight: 400,
    width: "100%"
  },
  buttonStyle: {
    height: "50px",
    width: "100%",
    maxWidth: "360px",
    marginTop: "25px",
    marginBottom: "20px",
    border: "none",
    borderRadius: "20px",
    backgroundColor: "#0074BF",
    color: "white",
    cursor: "pointer",
    fontWeight: 700,
    textTransform: "none"
  },
  passwordRequirements: {
    marginTop: '10px',
  },
  requirementItem: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '5px',
  },
  checkIcon: {
    color: '#34D399',
    fontSize: '16px',
    marginRight: '8px',
  },
  uncheckIcon: {
    color: '#9E9E9E',
    fontSize: '16px',
    marginRight: '8px',
  },
  requirementText: {
    fontSize: '14px',
    color: '#333',
  },
  consentContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    borderRadius: '4px',
  },
  checkbox: {
    '&.Mui-checked': {
      color: '#3B82F6',
    },
    padding: '0',
    marginRight: '8px',
  },
  consentText: {
    fontSize: '14px',
    color: '#0F172A',
    display: 'inline',
    lineHeight: "20px",
    fontWeight:400,
  },
  consentButton: {
    color: '#0F172A',
    padding: '0 2px',
    minWidth: 'auto',
    fontWeight:500,
    fontSize:"14px",
    lineHeight:"22px",
    textDecoration: 'underline',
    '&:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'underline',
    },
  },
};

const SignUpContainer = styled(Box)({
  height: "auto",
  "& .SignUpFormContainer": {
    padding: "0px 30px",
    overflow: "auto",
    height: "auto",
    overflowX: "hidden",
    "@media(max-width:1242)": {
      padding: "0px 30px",
    },
    "@media(max-width:960px)": {
      padding: "0px 30px",
      height: "auto",
      width: "100%",
      boxSizing: "border-box",
    },
    "@media(min-width: 360px) and (max-width: 414px)": {
      padding: "0px 30px",
      height: "auto",
    },
  },
  "& .logoFramrDisplay": {
    "@media (max-width: 899px)": {
      display: "none"
    }
  },
  "& .formHeader": {
    marginTop: "40px",
    marginLeft: "-172px",
    display: 'flex',
    marginBottom: '20px',
    gap: "55px",
    alignItems: 'center',
    "@media(min-width: 360px) and (max-width: 414px)": {
      marginLeft: "0px",
      gap: "18px",
    },
    "@media(min-width: 415px) and (max-width: 600px)": {
      marginLeft: "30px",
      gap: "18px",
    },
    "@media(min-width: 900px) and (max-width: 1180px)": {
      marginLeft: "30px",
      gap: "18px",
    },
  },
  "& .activeCreateAccountButton": {
    height: "50px",
    width: "100%",
    maxWidth: "360px",
    marginTop: "25px",
    marginBottom: "20px",
    fontSize: "16px",
    fontFamily: "Montserrat",
    border: "none",
    borderRadius: "20px",
    backgroundColor: "#0074BF",
    color: "white",
    cursor: "pointer",
    fontWeight: 700,
    textTransform: "none"
  },
  
  "& .createAccountButton": {
    borderRadius: "20px",
    backgroundColor: "#B2D5EC",
    color: "white",
    fontWeight: 700,
    textTransform: "none",
    height: "50px",
    width: "100%",
    maxWidth: "360px",
    marginTop: "25px",
    marginBottom: "20px",
    border: "none",
    fontSize: "16px",
    fontFamily: "Montserrat",
  },
})


// Customizable Area End