// Customizable Area Start
import React from "react";
import {
    Box,
    Typography,
    styled,
    DialogTitle,
    Button,
    Dialog,
    IconButton,
    DialogContent,
    BoxProps,
    Portal,
    Snackbar
  } from "@mui/material";
import CustomisableUserProfilesController, { Props } from "../CustomisableUserProfilesController";
import { group_left, deletedTestsIcon, flightTicketIcon, restoreIcon, button_restore} from "../assets";
import CloseIcon from "@mui/icons-material/Close";

interface CustomBoxSnackbarProps extends BoxProps {
  alertType?: 'success' | 'error';
}

export default class SettingsDeletedTests extends CustomisableUserProfilesController {
  constructor(props: Props) {
    super(props);
  }

    renderDesignationsList(){
      const { deletedTestsForSettings } = this.state
      return (
        <>
          {deletedTestsForSettings.map((designation) => (
            <Box key={designation.service_subcategory_id}>
              <Box className="border" />
              <Box className="airportContainer">
                <Box className="airportIconContainer">
                  <img src={flightTicketIcon} alt="" />
                  <Typography className="airportName">{designation.service_subcategory_name}</Typography>
                </Box>
                <Box data-test-id="restore-icon" onClick={() => this.setState({currentSelectedItems: designation, isRestoreDialogOpen: true})} className="pointer"><img src={button_restore} alt="" /></Box>
              </Box>
              <Box className="border" />
            </Box>
          ))}
        </>
      )
    }

  render() {
    const { deletedTestsForSettings, isRestoreDialogOpen } = this.state
    return(
        <CustomSettingsDeletedTestsContainer>
            <Portal>
              <Snackbar anchorOrigin={{ horizontal: 'right', vertical: 'top' }} onClose={this.oncloseAlert} open={this.state.isAlert}
                 autoHideDuration={300000} data-test-id="alertTestId"
              >
                <CustomBoxSnackbar 
                    alertType={this.state.alertType as any}
                >
                    <img   alt={"icon"} src={this.getAlertIcon()} className="snackbarImage"/>{this.state.alertMsg}
                </CustomBoxSnackbar>
              </Snackbar>
            </Portal>
            <Box className="headerContainer" data-test-id = "test-details-page">
                <Box className="pageHeader">
                   <Typography 
                      data-test-id="testSettingsTitle" 
                      onClick={() => this.navigateToSettingsTests()} 
                      className={"testSettingsTitle"} 
                      >Tests settings</Typography>
                   <Box className="designationContainer">
                       <img className="group_left" src={group_left} alt="group_left"/>
                      <Typography className="title">Deleted tests</Typography>
                    </Box>
                </Box>
            </Box>

            {deletedTestsForSettings.length <= 0 ? (<Box className="outerContainer">
                <Box className="contentContainer">
                    <Box className="restoreIconContainer">
                      <img src={deletedTestsIcon} alt="restored-test-icon"/>
                      <Typography className="noTests">You haven't deleted any tests</Typography>
                    </Box>
                </Box>
            </Box>): (
              <Box className="outerContainer">
                <Box className="designationsContentContainer">
                  {this.renderDesignationsList()}
                </Box>
              </Box>
            )}

          <CustomDialog open={isRestoreDialogOpen}
               onClick={this.handleRestoreCloseDialog} 
               maxWidth="sm" 
               fullWidth 
               PaperProps={{style: { borderRadius: 16, width: "600px", overlay:"none"}}} >
              <CustomRestoreTitle>
                <img className="restoreIcon" src={restoreIcon} alt="restoreIcon" />
                  Restore test?
                <IconButton
                  data-test-id="close-icon-restore"
                  aria-label="restore"
                  onClick={this.handleRestoreCloseDialog}
                  sx={{ position: 'absolute', right: 8, top: 18, }}
                >
                  <CloseIcon className="icons" />
                </IconButton>
              </CustomRestoreTitle>
              <DialogContent className="restoreDialogContent" >
                <Typography className="restoreText">
                  Are you sure you want to restore this test? It will be reactivated and available. 
                </Typography>

                <Box className="border" />
                <Box className="actionsContainer">
                    <CustomButton
                      data-test-id="restoreCancelButton"
                      variant="outlined"
                      className="restoreCancelButton"
                      onClick={() => this.handleRestoreCloseDialog()}>
                        Cancel
                    </CustomButton>
                    <CustomButton
                      data-test-id="restore-designation"
                      variant="contained"
                      color="primary"
                      className="restoreButton"
                      onClick={() => this.handleConfirmRestore()}
                      > Yes, restore test</CustomButton>
                </Box>
              </DialogContent></CustomDialog>
        </CustomSettingsDeletedTestsContainer>
    )
  }
}

const CustomButton = styled(Button)({
  textTransform: "capitalize",width: 258,borderRadius: 20,
  fontWeight: 700,
  fontSize: 16,
})

const CustomDialog = styled(Dialog)({
  "& .restoreIcon":{marginRight:"20px"
  },
  "&. icons":{color:"#94A3B8", 
    cursor: "pointer"},
  
  "& .restoreDialogContent":{ 
    padding: "0px"
  },
  "& .actionsContainer":{
    justifyContent:"space-between",
    display:"flex",
    padding: "20px 25px 25px 25px"
  },
  "& .border":{ borderBottom:"1px solid #E2E8F0", },
  "& .restoreText": {
    margin:"25px 45px 25px 45px",
    color:"#0F172A",
    fontWeight: 400,
    fontSize: "16px",
    fontFamily: "Montserrat",
  },
  "& .restoreButton": {
    height: "50px",
    color: "#ffffff",
    background: "#0074BF",
    width:"265px",
    fontWeight: 700,
    fontFamily: "Montserrat",
    textTransform: "none",
    fontSize: "16px",
    padding: "0px 15px",
    borderRadius: "20px",
  },
  "& .restoreCancelButton": {
    height: "50px",
    fontSize: "16px",
    borderRadius: "20px",
    fontFamily: "Montserrat",
    width:"265px",
    border:"2px solid #0074BF",
    fontWeight: 700,
    textTransform: "none",
    padding: "0px",
    color:"#0074BF",
  },
})

const CustomRestoreTitle = styled(DialogTitle)({
  border:"1px solid #E2E8F0",
  textAlign: 'left',
  color: "#001C55",fontSize: 22,
  fontWeight: "700",
  fontFamily: "Montserrat",
  display: "flex", alignItems: "center",
  margin:"0px",
  padding:"16px 40px",
  "& .restoreIcon":{ marginRight:"20px" }
})

const CustomBoxSnackbar = styled(Box)<CustomBoxSnackbarProps>(
  ({ alertType }) => ({
  backgroundColor: "#fff",
  borderRadius: "20px",
  fontSize:"16px",
  fontFamily: 'Montserrat, sans-serif',
  color: alertType === "success" ? "#0F172A" : "red",
  padding: "8px 16px 8px 8px",
  boxShadow: "0px 6px 15px -3px #00000026", 
  "& .snackbarImage":{
    marginRight: "8px", 
    width: "20px", 
    objectFit:'contain',
    height: "20px",
    verticalAlign: "middle",
  },
}));

const CustomSettingsDeletedTestsContainer = styled(Box)({
    "& .noTests":{
        fontFamily: "Montserrat",
        fontWeight: 400,
        fontSize: "16px",
        color: "#94A3B8",
    },
    "& .designationsContentContainer":{
      padding: "50px",
      alignItems: "center",
      background: "#ffffff",
      justifyContent:"space-between"
    },
    "& .restoreIconContainer":{
      display:"flex",
      justifyContent:"center",
      alignItems:"center",
      flexDirection:"column",
      img : {
        marginBottom: "20px"
      }
    },
    "& .airportContainer":{
      margin:"15px",
      display:"flex",
      justifyContent:"space-between",
      alignItems:"center"
    },
    "& .airportIconContainer":{
      display:"flex",
      justifyContent:"space-between",
      img:{
        width:"24px",
        height:"24px"
      }
    },
    "& .airportName":{
      fontFamily: "Montserrat",
      fontWeight: 500,
      fontSize: "16px",
      color: "#94A3B8",
      marginLeft:"20px"
    },
    "& .testSettingsTitle":{ 
      color:"#0074BF",
      fontFamily: "Montserrat",
      fontWeight: 500,
      fontSize: "20px",
      cursor:"pointer"
    },
    "& .border":{
      borderBottom:"1px solid #E2E8F0",
    },
    "& .pointer":{
      cursor:"pointer"
    },
    "& .outerContainer":{padding:'50px 50px 50px 50px'},
    "& .title":{
        fontFamily: "Montserrat", fontSize: "22px",
        color:"#0074BF",
        fontWeight: 700,
       
    },
    "& .restoreIcon":{
       marginRight:"20px", height:"26px",  width:"26px",},
    "& .headerContainer":{
        display: "flex",padding: "10px 50px 10px 50px",
        alignItems: "center",
        height: "60px",
        background: "#ffffff",justifyContent:"space-between"
    },
    "& .designationContainer":{
      alignItems:"center",
      display:"flex",
      justifyContent:"center",
    },
    "& .group_left":{marginRight:"20px"},
    "& .pageHeader": {
      gap:'20px', display:'flex',
      alignItems:'center',
  },
  "& .headerText": { fontFamily: "Montserrat",
    fontSize: "22px", color: "#0074BF",
    fontWeight: 700,

  },
  "& .contentContainer": {      background: "#ffffff",
      justifyContent:"center",
      display:"flex",
      minHeight:"550px",padding: "50px 50px 50px 50px",
      alignItems: "center",
  },
})
// Customizable Area End
