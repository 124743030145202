import React from "react";
// Customizable Area Start
import {
  Box,
  FormControlLabel,
  RadioGroup,
  Radio,
  Checkbox,
  TextField,
  FormControl,
  FormLabel,
  Typography,
  styled,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  Divider
} from "@mui/material";
import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";
import "@mui/styles";
const DatePicker = require("react-datepicker");
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import "react-datepicker/dist/react-datepicker.css";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { deleteIcon,calenderIcon } from "../assets";
export const configJSON = require("./../../config.json");
// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

import CustomisableUserProfilesController, {
  Props,
  IField,
} from "../CustomisableUserProfilesController";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

// Customizable Area End

export default class UserProfile extends CustomisableUserProfilesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const { profileResponse, isEditable, role ,errors } = this.state;
    return (
      <>
          <StyledProfileHeader>
            <Typography
              sx={{
                fontFamily: "Montserrat",
                fontWeight: 700,
                fontSize: "22px",
                color: "#0074BF",
              }}
            >
              Profile
            </Typography>
          </StyledProfileHeader>
          <Box 
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <StyledContainer>
              <Box className="upperSection">
                <Box className="profileNameContainer">
                  <Typography sx={webStyle.profileNameLogo}> {this.state.sortName} </Typography>
                </Box>
                {this.state.role === "dpe" ? <Typography className="roleName"> DESIGNATED PILOT EXAMINER </Typography>
                : 
                <>
                <Typography className="userName"> {profileResponse.user_name} </Typography>
                <span className="roleName" style={{margin:"15px",top:"110px"}}>•</span>
                <Typography className="roleName"> APPLICANT </Typography>
                </>
                }
              </Box>
              <Box className="profileFormContainer">
                <Grid container rowGap={2} columnSpacing={2}>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    sx={{
                      display: "flex",
                      gap: "5px",
                      flexDirection: "column",
                    }}
                  >
                  <Box sx={webStyle.profileContainer}>
                  <Box sx={webStyle.profileIcon}>
                      <BadgeOutlinedIcon />
                    </Box>
                    <Box sx={webStyle.profileTextContainer}>
                      <Typography sx={webStyle.formFieldLabel} gutterBottom>
                        {role === "dpe" ? "DMS number" : "FTN"}
                      </Typography>
                      <Typography>{role === "dpe" ? profileResponse.dms : profileResponse.ftn}</Typography>
                    </Box>
                  </Box>      
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    sx={{
                      display: "flex",
                      gap: "5px",
                      flexDirection: "column",
                    }}
                  >
                   <Box sx={webStyle.profileContainer}>
                   <Box sx={webStyle.profileIcon}>
                      <PhoneIphoneIcon />
                    </Box>
                    <Box sx={webStyle.profileTextContainer}>
                      <Typography sx={webStyle.formFieldLabel} gutterBottom> Phone number</Typography>
                      <Typography>{this.state.profileResponse.full_phone_number}</Typography>
                    </Box>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    sx={{
                      display: "flex",
                      gap: "5px",
                      flexDirection: "column",
                    }}
                  >
                  <Box sx={webStyle.profileContainer}>
                    <Box sx={webStyle.profileIcon}>
                      <MailOutlineIcon />
                    </Box>
                    <Box sx={webStyle.profileTextContainer}>
                      <Typography sx={webStyle.formFieldLabel} gutterBottom>Email</Typography>
                      <Typography>{this.state.profileResponse.email}</Typography>
                    </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </StyledContainer>
          </Box>
      </>
      // Customizable Area End
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const StyledProfileHeader = styled(Box)({
  height: "60px",
  display: "flex",
  padding: "10px 50px 10px 50px",
  justifyContent: "space-between",
  alignItems: "center",
  background: "#ffffff",
  "@media (max-width: 831px)": {
    "box-shadow": " 0px 4px 4px 0px #0000000D",
    zIndex: 2,
  },
});
const StyledContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  height: "fit-content",
//   maxWidth: "1414px",
  background: "#FFFFFF",
  borderRadius: "20px",
  marginTop: "40px",
  width: "95%",

  "& .upperSection": {
    position: "relative",
    display: "flex",
    borderRadius: "20px  20px 50px 0px",
    height: "110px",
    background: "linear-gradient(180deg, #FFFFFF 0%, #E0F2F8 91%)",

    "& .profileNameContainer": {
      display: "flex",
      height: "120px",
      width: "120px",
      borderRadius: "50%",
      border: "4px solid #E0F2F8",
      justifyContent: "center",
      alignItems: "center",
      top: "50px",
      left: "50px",
      position: "absolute",
      background: "#ffffff",
    },
    "& .roleName": {
      position: "relative",
      top: 125,
      left: 200,
      fontSize: "14px",
      fontWeight: 600,
      color: "grey",
      textAlign: "center",
      height: "fit-content",
      fontFamily: "Montserrat",
    },
    "& .userName": {
        position: "relative",
        top: 120,
        left: 200,
        fontSize: "20px",
        fontWeight: 600,
        color: "#001C55",
        textAlign: "center",
        height: "fit-content",
        fontFamily: "Montserrat",
      },
  },
  "& .profileFormContainer": {
    display: "flex",
    padding: "40px",
    marginTop: "100px",
    flexDirection: "column",
    gap: "40px",
  },
 
  "@media (max-width: 1246px)": {
    maxWidth: "60vw",
  },
  "@media (max-width: 831px)": {
    maxWidth: "90vw",
  },
  "@media (max-width: 600px)": {
    marginTop: "0px",
    maxWidth: "100%",
    "& .upperSection": {
      borderRadius: "0px  0px 50px 50px",
      flexDirection: "column",
      height: "fit-content",
      justifyContent: "center",
      alignItems: "center",
      padding: "10px",
      "& .profileNameContainer": {
        top: 0,
        left: 0,
        position: "relative",
      },
      "& .roleName": {
        position: "relative",
        top: 0,
        left: 0,
      },
      "& .userName": {
        position: "relative",
        top: 0,
        left: 0,
      },
    },
    "& .profileFormContainer": {
      marginTop: "0px",
    },
  },
});

const webStyle = {
  profileNameLogo: {
    fontSize: "50px",
    fontWeight: 600,
    color: "#0074BF",
    textAlign: "center",
    height: "fit-content",
    fontFamily: "Montserrat",
  },
  formFieldLabel: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "16px",
    color: "#94A3B8",
  },
  profileContainer: {
    display: "flex",
    gap: "20px",
    alignItems: "center", // Ensures vertical centering
    paddingBottom: "8px",
    borderBottom: "1px solid #E0E0E0",
  },
  profileIcon: {
    backgroundColor: "#E0F2F8",
    borderRadius: "50%",
    height: "36px",
    width: "36px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    opacity: "50%",
  },
  profileTextContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center", // Ensures the text aligns well with the icon
  },
};
// Customizable Area End
