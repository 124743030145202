import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { toast } from "react-toastify";
import moment from "moment";
interface Availability {
  id: number;
  service_provider_id: number;
  start_time: string | null;
  end_time: string | null;
  unavailable_start_time: string | null;
  unavailable_end_time: string | null;
  availability_date: string;
  created_at: string;
  updated_at: string;
  timeslots: TimeSlot[];
  available_slots_count: number;
  time_zone: string;
  status: string;
  booked_by_applicant_id: number;
  stripe_price_id: string[];
  stripe_product_id: string[];
  booked_airport_id: number;
  booked_designation_instruction_id: number;
  type_of_practical_test: string | null;
  type_of_grade_of_certificate: string | null;
  aircraft_model: string | null;
  certificate_number: string | null;
  recommending_instructor: string | null;
  flight_instructor_certificate_number: string | null;
  name_of_facility: string | null;
  street_address_line_1: string | null;
  street_address_line_2: string | null;
  city: string | null;
  state: string | null;
  zip_code: string | null;
}

interface TimeSlot {
  from: string;
  to: string;
  booked_status: boolean;
}
interface Airport {
  id: number;
  service_subcategory_id: number;
  service_subcategory_name: string;
}

interface Designation {
  id: number;
  service_subcategory_id: number;
  service_subcategory_name: string;
}

interface Data {
  availability: Availability;
  airports: Airport[];
  designations: Designation[];
  dpe_username: string;
  chat_id: number;
}

interface AvailabilityResponse {
  success: boolean;
  message: string;
  data: Data;
  error: any;
}

interface RecordItem {
  title: string;
  question: string;
  answer: string[];
}
interface ErrorToken {
  token: string;
}

interface ErrorResponse {
  errors: ErrorToken[];
}
export interface Exam {
  account_id: number;
  username: string;
  availability_id: number;
  availability_date: string;
  time: string;
  when: string;
  airport: string | null;
  designation: string | null;
  checkride_outcome?: string | null; 
}

export interface ExamResponseData {
  upcoming_exams: Exam[];
  upcoming_exams_count: number;
  past_exams: Exam[];
  past_exams_count: number;
  total_items_count: number;
  checked_items_count: number;
}

export interface ExamApiResponse {
  success: boolean;
  message: string;
  data: ExamResponseData;
  error: string | null;
}

interface ChecklistResponse {
  success: boolean;
  message: string;
  data: {
    data: Task[];
  };
  error: string | null;
}

export interface Task {
  id: string;
  type: string;
  attributes: TaskAttributes;
}

interface TaskAttributes {
  id: number;
  account_id: number;
  title: string | null;
  description: string;
  is_checked: boolean;
  category_type: string | null;
  created_at: string; 
  updated_at: string; 
  assigned_to: string | null;
}
export interface ProfileResponseData {
  user_name: string;
  dms: string;
  full_phone_number: string;
  email: string;
  ftn : string;
}

interface CheckrideOutcomeResponse {
  success: boolean;
  message: string;
  data: CheckrideOutcome;
  error: string | null;
}

interface CheckrideOutcome {
  id: number;
  status: string;
  reason: string;
  availability_id: number;
  outcome_status_message: string;
  created_at: string;
  updated_at: string;
  applicant_account_id: number;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  records: Array<RecordItem>;
  statusModal: boolean;
  status: string[];
  reason: Array<string>;
  uploadFileSelected: File | null;
  selectStatus: boolean;
  CheckRideOutcome: boolean;
  checkRideGoModal: boolean;
  statusValue: string;
  reasionValue: string;
  outcomeStatusMessage: string;
  checkRideoutcomeStatus: string;
  isOptOutWorningMessage: boolean;
  examData: ExamResponseData;
  pastExamIndex: number;
  upcomingExamIndax: number;
  selectUpcoming: Exam;
  trainingChecklist: Task[];
  instructionsChecklist:Task [];
  flightChecklist: Task[];
  checklistRecord: Task[];
  userName: string;
  availabilityData:Data;
  isAccordionExpanded: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start  
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.NavigationPropsMessage),
    ];

    this.state = {
      records: [
        {
          title: "Training Records",
          question: "Please make sure your logbook contains the following ground and flight training",
          answer: ["Received and logged flight training from 61.87(d).",
            "Received and logged flight training from 61.93(e)."]
        }, {
          title: "Instructor Endorsements",
          question: "Please make sure your logbook contains the following ground and flight training",
          answer: ["Received and logged flight training from 61.87(d).",
            "Received and logged flight training from 61.93(e)."]
        },
        {
          title: "Flight Experience",
          question: "Please make sure your logbook contains the following ground and flight training",
          answer: ["Received and logged flight training from 61.87(d).",
            "Received and logged flight training from 61.93(e)."]
        }],
      statusModal: false,
      status: ["I passed!", "Re-check required", "Checkride discontinued", "Checkride did not occur"],
      reason: ["I had a conflict", "Inclement weather", "DPE canceled"],
      uploadFileSelected: null,
      selectStatus: false,
      CheckRideOutcome: false,
      checkRideGoModal: false,
      statusValue: "",
      reasionValue: "",
      outcomeStatusMessage : "",
      checkRideoutcomeStatus:"",
      isOptOutWorningMessage: false,
      examData: {
        upcoming_exams: [],
        upcoming_exams_count: 0,
        past_exams: [],
        past_exams_count: 0,
        total_items_count: 0,
        checked_items_count: 0,
      },
      pastExamIndex: 0,
      upcomingExamIndax: 0,
      selectUpcoming: {
        account_id: 0,
        username: "",
        availability_id: 0,
        availability_date: "",
        time: "",
        when: "",
        airport: null,
        designation: null
      },
      trainingChecklist: [],
      instructionsChecklist: [],
      flightChecklist: [],
      checklistRecord: [],
      userName: "",
      availabilityData:{
        availability: {
          id: 0,
          service_provider_id: 0,
          start_time: "",
          end_time: "",
          availability_date: "",
          timeslots: [],
          available_slots_count: 0,
          time_zone: "",
          status: "",
          booked_airport_id: 0,
          booked_designation_instruction_id: 0,
          type_of_practical_test: "",
          type_of_grade_of_certificate: "",
          aircraft_model: "",
          certificate_number: "",
          recommending_instructor: "",
          flight_instructor_certificate_number: "",
          unavailable_start_time: null,
          unavailable_end_time: null,
          created_at: "",
          updated_at: "",
          booked_by_applicant_id: 0,
          stripe_price_id: [],
          stripe_product_id: [],
          name_of_facility: null,
          street_address_line_1: null,
          street_address_line_2: null,
          city: null,
          state: null,
          zip_code: null
        },
        airports: [],
        designations: [],
        dpe_username: "",
        chat_id: 0
      },
      isAccordionExpanded: false
  };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        this.responseSucessCell(apiRequestCallId, responseJson)
      }
      else if (responseJson && responseJson.errors) {
        this.responseFailureCell(apiRequestCallId, responseJson)
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  upcomingAndPastExamApiCallId: string = "";
  getCheckListApiCallId: string = "";
  landingPageProfileApiCallId: string = "";
  availabilityApiCallId: string = "";
  createChatApiCallId: string= "";
  checkRideOutcomApiCallId: string = "";
  checkRideDetailApiCallId: string = "";
  getcheckRideOutcomApiCallId: string = "";
  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  async componentDidMount() { 
    this.setState({ checkRideGoModal: true })
    this.landingPageProfileApi()
    this.upcomongAndPastExamApi()
    this.getChecklistApi()
    if (localStorage.getItem("isAuthenticated") === "true") {
      window.history.pushState(null, "", window.location.href);
      window.addEventListener("popstate", this.handleBack);
    }
  }

  async componentWillUnmount() {
    window.removeEventListener("popstate", this.handleBack);
  }

  handleBack = () => {
    window.history.pushState(null, "", window.location.href);
  };
  
  handleStatusOpenModal = (upcomingData: Exam) => {
    this.setState({ statusModal: true, selectUpcoming: upcomingData })
  }

  handleStatusCloseModal = () => {
    this.setState({ statusModal: false })
  }

  handleUploadFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event?.target?.files?.[0];
    if (file) {

      this.setState({
        uploadFileSelected: file,
      })
    }
  };

  backStatusOption = () => {
    this.setState({ selectStatus: false })
  }

  chackRideOutcomeOpenModal = (status: string, availabilityId: number) => {
    this.setState({ statusValue: status, },()=>this.checkRideDetailsApi(availabilityId))
  }

  chackRideOutcomeCloseModal = () => {
    this.setState({ CheckRideOutcome: false, selectStatus: false })
  }

  checkRideGoCloseModal = () => {
    this.setState({ checkRideGoModal: false })
  }

  optOutWorningMessageOpenModal = () => {
    this.setState({ isOptOutWorningMessage: true, CheckRideOutcome: false })
  }

  optOutWorningMessageCloseModal = () => {
    this.setState({ isOptOutWorningMessage: false, selectStatus: false })
  }

  handleOption = (event: React.SyntheticEvent, statusValue: string) => {
    this.setState({ statusValue: statusValue })
  }
  handleReasion = (event: React.SyntheticEvent, reasion: string) =>{
    this.setState({reasionValue:reasion})
  }
  convertToUpperCase = (value: string) => {
    return value?.toUpperCase()
  };

  navigateScreens = (pathName: string) => {
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), pathName);
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msg);
  };

  landingPageApiCall = async (valueData: {
    contentType?: string;
    method?: string;
    endPoint?: string;
    body?: {};
    apiType?: string;
    type?: string;
  }) => {
    const token = await (localStorage.getItem('accessToken'))
    let { contentType, method, endPoint, body, apiType } = valueData;
    let header = {
      "Content-Type": contentType,
      Token: token
    };
    let landingPageRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    landingPageRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    landingPageRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    body &&
      landingPageRequestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        apiType === "" ? JSON.stringify(body) : body
      );
    landingPageRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(landingPageRequestMessage.id, landingPageRequestMessage);
    return landingPageRequestMessage.messageId;
  };

  responseSucessCell = async (apiRequestCallId: string, responseJson: ExamApiResponse & ChecklistResponse & ProfileResponseData 
    & AvailabilityResponse & CheckrideOutcomeResponse) => {
    if (apiRequestCallId === this.upcomingAndPastExamApiCallId) {
      this.upcomongExamSucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.getCheckListApiCallId) {
      this.getChecklistApiSucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.landingPageProfileApiCallId) {
      this.landingPageProfileSucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.availabilityApiCallId) {
      this.availabilitySucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.checkRideDetailApiCallId) {
      this.checkRideSucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.checkRideOutcomApiCallId) {
      this.checkRideOutcomSucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.getcheckRideOutcomApiCallId) {
      this.getCheckRideOutcomSucessCallBack(responseJson);
    }
  };

  responseFailureCell = async (apiRequestCallId: string, responseJson: ErrorResponse) => {
    if (apiRequestCallId === this.upcomingAndPastExamApiCallId) {
      this.upcomongExamFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.getCheckListApiCallId) {
      this.getChecklistApiFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.landingPageProfileApiCallId) {
      this.landingPageProfileFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.availabilityApiCallId) {
      this.availabilityFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.checkRideDetailApiCallId) {
      this.checkRideFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.checkRideOutcomApiCallId) {
      this.checkRideOutcomFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.getcheckRideOutcomApiCallId) {
      this.getCheckRideOutcomFailureCallBack(responseJson);
    }
  };

  getChecklistApi = async () => {
    this.getCheckListApiCallId = await this.landingPageApiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.checkRideListEndPoint,
    });
  };

  getChecklistApiSucessCallBack = (response: ChecklistResponse) => {
    if (response.success) {
      const checklistData = response?.data?.data || []
      this.setState({
        trainingChecklist: checklistData.slice(0, 9),
        instructionsChecklist: checklistData.slice(9, 18),
        flightChecklist: checklistData.slice(18, 28),
        checklistRecord: checklistData
      });
    }
  }

  getChecklistApiFailureCallBack = (response: ErrorResponse) => {
    toast.error(response.errors[0].token)
  }

  upcomongAndPastExamApi = async () => {
    this.upcomingAndPastExamApiCallId = await this.landingPageApiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.upcomingExamEndPoint,
    });
  };

  upcomongExamSucessCallBack = (response: ExamApiResponse) => {
    if (response) {
      const examDataResponse = response.data
      const examDataUpdate = {
        upcoming_exams: examDataResponse.upcoming_exams || [],
        upcoming_exams_count: examDataResponse.upcoming_exams_count,
        past_exams: examDataResponse.past_exams || [],
        past_exams_count: examDataResponse.past_exams_count,
        total_items_count: examDataResponse.total_items_count,
        checked_items_count: examDataResponse.checked_items_count
      }
      this.setState({ examData: examDataUpdate })
    }
  }

  upcomongExamFailureCallBack = (response: ErrorResponse) => {
    toast.error(response.errors[0].token)
  }

  landingPageProfileApi = async () => {
    this.landingPageProfileApiCallId = await this.landingPageApiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.landingProfileEndPoint
    })
  };

  landingPageProfileSucessCallBack = (response: ProfileResponseData) => {
   this.setState({userName: response.user_name})
  }

  landingPageProfileFailureCallBack = (response: ErrorResponse) => {
    toast.error(response.errors[0].token)
  };

  checkRideDetailsApi = async (availabilityid: number) => {
    this.checkRideDetailApiCallId = await this.landingPageApiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.availabilitiesEndPoint}/${availabilityid}`
    })
  };

  checkRideSucessCallBack = (response: AvailabilityResponse) => {
    if(response.success){
      this.setState({availabilityData:response.data, CheckRideOutcome: true})
    }
  }

  checkRideFailureCallBack = (response: ErrorResponse) => {
    toast.error(response.errors[0].token)
  };

  availabilityApi = async (availabilityid: number) => {
    this.availabilityApiCallId = await this.landingPageApiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.availabilitiesEndPoint}/${availabilityid}`
    })
  };

  availabilitySucessCallBack = (response: AvailabilityResponse) => {
    if(response.success){
      this.setState({availabilityData:response.data,statusModal:true})
    }
  }

  availabilityFailureCallBack = (response: ErrorResponse) => {
    toast.error(response.errors[0].token)
  };

  handleCheckLength = (checkListItem: Task[]) => {
    return checkListItem.filter(item => item.attributes.is_checked).length
  }

  handlePastNextExam = () => {
    this.setState((prevState) => ({
      pastExamIndex: (prevState.pastExamIndex + 1),

    }));
  };

  handlePastPreviousExam = () => {
    this.setState((prevState) => ({
      pastExamIndex:
        (prevState.pastExamIndex - 1),
    }));
  };

  handleUpcomNextExam = () => {
    this.setState((prevState) => ({
      upcomingExamIndax: (prevState.upcomingExamIndax + 1),
    }));
  };

  handleUpcomPreviousExam = () => {
    this.setState((prevState) => ({
      upcomingExamIndax:
        (prevState.upcomingExamIndax - 1),
    }));
  };

  handleProgress = (part: number, total: number) => {
    return Math.floor((part / total) * 100);
  }

  getFirstLetter = (userName: string) => {
    const words: string[] = userName.split(' ');
    if (words.length === 1) {
      return words[0].charAt(0).toUpperCase()
    }
    const firstInitial: string = words[0].charAt(0).toUpperCase();
    const lastInitial: string = words[words.length - 1].charAt(0).toUpperCase();
    let name = firstInitial + lastInitial;
   return name ;
  }

  handleScheduleExam = (navigation:string) => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      navigation
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
     
    });
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }

  bookedTextUppercase = (bookText: string) =>{
    return bookText.charAt(0).toUpperCase()+ bookText.slice(1)
  }

  dateFormate = (inputDate:string) =>{
    return moment(inputDate, "DD/MM/YYYY").format("dddd, MMMM D, YYYY")
  }

  createCheckRideOutcom = async (availablityId: number) => {
    let formdata = new FormData();
        formdata.append("checkride_outcome[status]", this.state.statusValue);
        formdata.append("checkride_outcome[reason]", this.state.reasionValue);
        formdata.append("checkride_outcome[availability_id]", String(availablityId));
    this.checkRideOutcomApiCallId = await this.landingPageApiCall({
        method: configJSON.exampleAPiMethod,
        endPoint: configJSON.createCheckRideOutCome,
        body: formdata,
        apiType: "formData"
    });
};

checkRideOutcomSucessCallBack = (response: CheckrideOutcomeResponse) => {
    this.setState({selectStatus: true, outcomeStatusMessage: response.data.outcome_status_message},()=>this.getCheckRideOutcomApi(response.data.availability_id))
};

checkRideOutcomFailureCallBack = (response: ErrorResponse) => {
  toast.error(response.errors[0].token)
};

getCheckRideOutcomApi = async (availablityId: number) => {
  this.getcheckRideOutcomApiCallId = await this.landingPageApiCall({
     contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.createCheckRideOutCome}/${availablityId}`
  });
};

getCheckRideOutcomSucessCallBack = (response: CheckrideOutcomeResponse) => {
this.setState({checkRideoutcomeStatus:  response.data.outcome_status_message})
};

getCheckRideOutcomFailureCallBack = (response: ErrorResponse) => {
toast.error(response.errors[0].token)
};

handleAccordionToggle = (event: React.SyntheticEvent, isExpanded: boolean) => {
  this.setState({ isAccordionExpanded: isExpanded });
};
  // Customizable Area End
}
