// Customizable Area Start
import React from "react";
import {
    Box,
    Typography,
    Grid,
} from "@mui/material";
import SchedulingController, { Props } from "../SchedulingController";
import { leftArrow, upArrow, downArrow, crossButton, query } from "../assets";

import { Link } from 'react-router-dom';
import {Image } from "react-native";

export default class ChecklList extends SchedulingController {
    constructor(props: Props) {
        super(props);
    }


    render() {
        console.log('trainingChecklisttrainingChecklist',this.state.trainingChecklist)

        return (
            <>
                <Box sx={webStyle.headerContainer} data-test-id="test-details-page">
                    <Box sx={webStyle.pageHeader}>
                        <Link to={"/calendar"}><img data-test-id="back-to-view-availability" onClick={() => this.handleBackToViewAvailability()} src={leftArrow} style={webStyle.leftArrow} /></Link>
                        <Typography sx={webStyle.headerText}>Checklist</Typography>
                    </Box>
                </Box>
                {this.state.showBox && ( 
                <Box style={{ display: 'flex', justifyContent: 'space-between', backgroundColor: '#E9EEF9', padding: '15px 30px' }}>
                    <Box style={{ display: 'flex', gap: '4px', marginLeft: '30px' ,alignItems:'center'}}>
                        <img style={{ width: '24px', height: '24px' }} src={query} />
                        <Typography style={{ fontSize: '12px', fontFamily: 'Montserrat', color: '#94A3B8' }}>
                            Review and complete each requirement below before any appointment. You can return anytime to track your progress!
                        </Typography>
                    </Box>
                    <img 
                        style={{ width: '24px', height: '24px', marginRight: '30px', cursor: 'pointer' }} 
                        src={crossButton} 
                        onClick={this.handleClose} 
                    />
                </Box>
            )}

                <Box style={{ padding: '50px 50px 50px 50px' }} >
                    <Box sx={webStyle.contentContainer} >
                        <Grid container spacing={2} >
                            <Grid  item xs={12} sm={12} lg={12} md={12} >
                                <Box sx={[webStyle.dataCardContainer, webStyle.dataCardContainer_border]} 
                                data-test-id='training'
                                onClick={() => this.toggleSection("training")}>

                                    <Box sx={webStyle.dataCardTextContainer}>
                                        <Box>
                                        <Typography 
                                        sx={{
                                            ...webStyle.dataCardTitle, 
                                            color: this.state.trainingChecklist.length > 0 &&
                                                Object.values(this.state.selectedItems).length === this.state.trainingChecklist.length &&
                                                Object.values(this.state.selectedItems).every(Boolean)  
                                                ? "#0074BF" 
                                                : "#94A3B8"
                                        }}
                                    >
                                        TRAINING RECORDS
                                    </Typography>

                                            <Typography sx={{...webStyle.dataCardTitle1,color: this.state.openSections.training ? "#0074BF" : "#64748B"}}>Please make sure your logbook contains the following ground and flight training:</Typography>

                                        </Box>
                                        <Box sx={{ cursor: "pointer" }} >
                                        <div
                                        style={{ 
                                            color: this.state.trainingChecklist.length > 0 &&
                                                Object.values(this.state.selectedItems).length === this.state.trainingChecklist.length &&
                                                Object.values(this.state.selectedItems).every(Boolean)  
                                                ? "#0074BF"  
                                                : "#94A3B8", 
                                            fontSize: "12px",
                                            marginRight: '6px' 
                                        }}
                                    >
                                        {Object.values(this.state.selectedItems).filter(Boolean).length}/{this.state.trainingChecklist.length} 
                                    </div>

                                            <Image

                                                style={{
                                                    height: 12,
                                                    width: 12,
                                                    resizeMode: "contain",
                                                    tintColor: this.state.openSections.training ? "#0074BF" : "#94A3B8",
                                                }}
                                                source={this.state.openSections.training ? upArrow : downArrow}
                                            />
                                        </Box>

                                    </Box>


                                </Box>
                               <Typography sx={webStyle.dataCardTitle2}>
                            {this.state.openSections.training && this.state.trainingChecklist.map((item:any) => (
                                <li key={item.id} style={{ display: 'flex', gap: '24px', marginTop: '10px' }}>
                                    <label style={{ display: 'flex', alignItems: 'center', gap: '10px', cursor: 'pointer' }}>
                                        <input
                                            type="checkbox"
                                            checked={!!this.state.selectedItems[item.attributes.id]}
                                            data-test-id='trainingData'
                                            onChange={() => this.toggleCheckbox(item.attributes.id)}
                                            style={{ cursor: 'pointer' ,borderRadius: '10px',}}
                                        />
                                        {item.attributes.description}
                                    </label>
                                </li>
                            ))}
                        </Typography>

                            </Grid>
                            <Grid  item xs={12} sm={12} lg={12} md={12} >
                                <Box sx={[webStyle.dataCardContainer, webStyle.dataCardContainer_border]}
                                data-test-id='instruction'
                                onClick={() => this.toggleSection("instruction")}>

                                    <Box sx={webStyle.dataCardTextContainer}>
                                        <Box>
                                        <Typography 
                                    sx={{
                                        ...webStyle.dataCardTitle, 
                                        color: this.state.instructionsChecklist.length > 0 &&
                                            Object.values(this.state.selectedItems1).length === this.state.instructionsChecklist.length &&
                                            Object.values(this.state.selectedItems1).every(Boolean)  
                                            ? "#0074BF" 
                                            : "#94A3B8"
                                    }}
                                >
                                    INSTRUCTOR ENDORSEMENTS
                                </Typography>                                           
                                 <Typography sx={{...webStyle.dataCardTitle1,color:this.state.openSections.instruction?"#0074BF" : "#64748B"}}>Please make sure your instructor has given you the following endorsements from AC 61.65:</Typography>
                                        </Box>

                                        <Box sx={{ cursor: "pointer" }}>
                                        <div
                                        style={{ 
                                            color: this.state.instructionsChecklist.length > 0 &&
                                                Object.values(this.state.selectedItems1).length === this.state.instructionsChecklist.length &&
                                                Object.values(this.state.selectedItems1).every(Boolean)  
                                                ? "#0074BF"  
                                                : "#94A3B8", 
                                            fontSize: "12px",
                                            marginRight: '6px' 
                                        }}
                                    >
                                        {Object.values(this.state.selectedItems1).filter(Boolean).length}/{this.state.instructionsChecklist.length} 
                                    </div>

                                            <Image

                                                style={{
                                                    height: 12,
                                                    width: 12,
                                                    resizeMode: "contain",
                                                    tintColor: this.state.openSections.instruction ? "#0074BF" : "#94A3B8",
                                                }}
                                                source={this.state.openSections.instruction ? upArrow : downArrow}
                                            />
                                        </Box>
                                    </Box>

                                </Box>
                                <Typography sx={webStyle.dataCardTitle2}>
                                {this.state.openSections.instruction && this.state.instructionsChecklist.map((item:any) => (
                                    <li key={item.id} style={{ display: 'flex', gap: '24px', marginTop: '10px' }}>
                                        <label style={{ display: 'flex', alignItems: 'center', gap: '10px', cursor: 'pointer' }}>
                                            <input
                                                type="checkbox"
                                                data-test-id="toggleCheckbox1"
                                                checked={!!this.state.selectedItems1[item.attributes.id]}
                                                onChange={() => this.toggleCheckbox1(item.attributes.id)}
                                                style={{ cursor: 'pointer' }}
                                            />
                                            {item.attributes.description}
                                        </label>
                                    </li>
                                ))}
                            </Typography>

                            </Grid>
                            <Grid  item xs={12} sm={12} lg={12} md={12} >
                                <Box sx={[webStyle.dataCardContainer, webStyle.dataCardContainer_border]}  
                                data-test-id='flight'
                                onClick={() => this.toggleSection("flight")}>

                                    <Box sx={webStyle.dataCardTextContainer}>
                                        <Box>
                                        <Typography 
                                    sx={{
                                        ...webStyle.dataCardTitle, 
                                        color: this.state.flightChecklist.length > 0 &&
                                            Object.values(this.state.selectedItems2).length === this.state.flightChecklist.length &&
                                            Object.values(this.state.selectedItems2).every(Boolean)  
                                            ? "#0074BF" 
                                            : "#94A3B8"
                                    }}
                                >
                                    FLIGHT EXPERIENCE
                                </Typography>                                           
                                    <Typography sx={{...webStyle.dataCardTitle1,color:this.state.openSections.flight?"#0074BF" : "#64748B"}}>Please make sure your logbook contains at least the following aeronautical experience FAR 61.109</Typography>
                                        </Box>

                                        <Box sx={{ cursor: "pointer" }}>
                                        <div
                                    style={{ 
                                        color: this.state.flightChecklist.length > 0 &&
                                            Object.values(this.state.selectedItems2).length === this.state.flightChecklist.length &&
                                            Object.values(this.state.selectedItems2).every(Boolean)  
                                            ? "#0074BF"  
                                            : "#94A3B8", 
                                        fontSize: "12px",
                                        marginRight: '6px' 
                                    }}
                                >
                                    {Object.values(this.state.selectedItems2).filter(Boolean).length}/{this.state.flightChecklist.length} 
                                </div>

                                            <Image

                                                style={{
                                                    height: 12,
                                                    width: 12,
                                                    resizeMode: "contain",
                                                    tintColor: this.state.openSections.flight ? "#0074BF" : "#94A3B8",
                                                }}
                                                source={this.state.openSections.flight ? upArrow : downArrow}
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                                <Typography sx={webStyle.dataCardTitle2}>
                                    {this.state.openSections.flight && this.state.flightChecklist.map((item:any) => (
                                        <li key={item.id} style={{ display: 'flex', gap: '24px', marginTop: '10px' }}>
                                            <label style={{ display: 'flex', alignItems: 'center', gap: '10px', cursor: 'pointer' }}>
                                                <input
                                                    type="checkbox"
                                                    data-test-id="toggleCheckbox2"
                                                    checked={!!this.state.selectedItems2[item.attributes.id]}
                                                    onChange={() => this.toggleCheckbox2(item.attributes.id)}
                                                    style={{ cursor: 'pointer' }}
                                                />
                                                {item.attributes.description}
                                            </label>
                                        </li>
                                    ))}
                                </Typography>

                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </>
        );
    }
}

const webStyle = {
    pageHeader: {
        display: 'flex',
        alignItems: 'center',
        gap: '20px'
    },


    icons: { color: "#94A3B8", cursor: "pointer" },

    dataCardTextContainer: {
        marginLeft: "15px",
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between'
    },
    dataCardTitle: {
        fontSize: "12px",
        fontWeight: "700",
        color: "#94A3B8",
        fontFamily: 'Montserrat'
    },
    dataCardTitle1: {
        fontSize: "14px",
        fontWeight: "400",
        color: "#64748B",
        fontFamily: 'Montserrat',
        cursor:'pointer'
    },

    dataCardTitle2: {
        fontSize: "15px",
        fontWeight: "400",
        color: "#0F172A",
        fontFamily: 'Montserrat',
        paddingLeft:'30px'
        // padding: '8px 20px 20px 20px'
    },

    dataCardContainer: {
        display: "flex",
        padding: "8px 20px 20px 20px",
        flexDirection: "row",
        alignItems: "center"
    },
    headerContainer: {
        display: "flex",
        height: "60px",
        padding: "10px 50px 10px 50px",
        alignItems: "center",
        background: "#ffffff",
        justifyContent: "space-between"
    },
    contentContainer: {
        alignItems: "center",
        background: "#ffffff",
        justifyContent: "space-between",
        borderRadius:'20px'
    },
    dataCardContainer_border: {
        borderBottom: "1px solid #E2E8F0",
    },
    headerText: {
        fontFamily: "Montserrat",
        fontWeight: 700,
        fontSize: "22px",
        color: "#0074BF",
    },
    leftArrow: {
        marginRight: "10px",
        height: '24px',
        width: '24px',
        color: "#0074BF"
    },

}
// Customizable Area End
