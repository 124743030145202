import React from "react";
// Customizable Area Start
import {
    Box,
    Typography,
    Button,
    Grid
} from "@mui/material";
import { loginLogo } from "../../email-account-login/src/assets";
import { successIcon, searchIcon1, logoImage } from "./assets";
import { Link } from 'react-router-dom';
import { bgImage } from "../../email-account-registration/src/assets";

import AccountStatusController, { Props } from "./AccountStatusController";
const configJSON = require("./config");
// Customizable Area End
export default class AccountStatus extends AccountStatusController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        return (
            <>
                <div className="Toastify"></div>
                <Grid container direction={'row'}  sx={{
                    width: '100%',
                    height: '100%',

                }}>
                    <Grid item xs={12}>
                        <Grid sx={{
                            height: "90px", width: "100%",
                            backgroundColor: '#daf8ff',
                        }}>
                            <Box sx={webStyle.logoContainer}>
                                <img src={logoImage} style={{ padding: '7px', height: '50px', width: '118', objectFit: "cover" }} alt="logo" />
                            </Box>
                        </Grid>
                        <Grid sx={
                            {
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100%',
                                backgroundColor: '#ffffff',
                                backgroundImage: `url(${bgImage})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                            }
                        }>
                            {!this.state.isActivated ?
                                <Box sx={webStyle.formWrapper} data-test-id='account-inprogress'>
                                    {this.state.checkStatus &&
                                        <Box sx={webStyle.userStatus}>
                                            <Typography sx={webStyle.textStyle}>{configJSON.userStatus}</Typography>
                                        </Box>
                                    }
                                    <Box sx={{
                                        margin: '7px 18px', display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center', height: '120px', width: '120px', borderRadius: "50%", backgroundColor: '#E0F2F8'
                                    }}>
                                        <img src={searchIcon1.default} style={{ height: '60px', width: '60px' }} alt="left-arrow" />
                                    </Box>
                                    <Box sx={webStyle.inputContainer}>
                                        <Typography variant="h6" sx={{
                                            fontFamily: 'Montserrat, sans-serif',
                                            fontWeight: 700, fontSize: "22px", color: "#001C55",
                                            textAlign: 'center'

                                        }}>
                                            Account under review
                                        </Typography>
                                        <Typography sx={webStyle.statusMesssage}>
                                            {configJSON.statusMessage}
                                        </Typography>
                                    </Box>
                                    <Button
                                        type="button"
                                        data-testId = "userStatusTestId"
                                        onClick={this.userStatusApi}
                                        sx={webStyle.btnStyle}
                                        data-test-id="LoginBtn"
                                        variant="contained"
                                    >
                                        Check status
                                    </Button>
                                </Box>
                                : <Box sx={webStyle.formWrapper}>
                                    <Box sx={{
                                        margin: '7px 18px', display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center', height: '120px', width: '120px', borderRadius: "50%", backgroundColor: '#E0F2F8'
                                    }}>
                                        <img src={successIcon.default} style={{ height: '60px', width: '60px' }} alt="left-arrow" />
                                    </Box>
                                    <Box sx={webStyle.inputContainer}>
                                        <Typography variant="h6" sx={{
                                            fontFamily: 'Montserrat, sans-serif',
                                            fontWeight: 700, fontSize: "22px", color: "#001C55",
                                            textAlign: 'center'

                                        }}>
                                            Welcome aboard!                                                   </Typography>
                                        <Typography variant="h6" sx={{
                                            margin: '15px 0px',
                                            fontFamily: 'Montserrat, sans-serif',
                                            fontWeight: 400, fontSize: "16px", color: "#0F172A",
                                            textAlign: 'center'


                                        }}>

                                            Your account has been successfully activated. You’re ready to start managing your availability and tests.
                                        </Typography>


                                    </Box>
                                    <Button
                                        type="button"
                                        onClick={(event: any) => this.handleGetStarted(event)}
                                        sx={webStyle.btnStyle}
                                        data-test-id="GetStarted"
                                        variant="contained"
                                    >
                                        Get Started
                                    </Button>
                                    <Typography variant="h6" align="center" sx={{
                                        fontFamily: 'Montserrat, sans-serif',
                                        fontWeight: 400, fontSize: "16px", color: "#0F172A",
                                        display: "inline"
                                    }}>

                                        <Typography data-testId = "handlelogoutBoard" onClick={this.handleLogoutBoard} style={{
                                            fontFamily: 'Montserrat, sans-serif', display: "inline",
                                            fontWeight: 700, fontSize: "16px", color: "#0074BF", textDecoration: "none",
                                            marginLeft: "7px",cursor:"pointer"
                                        }}>
                                            Log out
                                        </Typography >
                                    </Typography>
                                </Box>
                            }
                        </Grid>
                    </Grid>

                </Grid >
            </>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const webStyle = {
    inputContainer: {
        position: "relative",
        width: "100%",
        maxWidth: "360px",
        mb: 1
    },
    logoContainer: {
        height: '100%',
        width: '118',
        objectFit: "cover",
        marginLeft: "100px",
        display: "flex",
        alignItems: "center",
        "@media (max-width: 414px)": {
            marginLeft: "20px",
        }
    },
    btnStyle: {
        height: "50px",
        width: "360px",
        marginTop: "25px",
        marginBottom: "20px",
        border: "none",
        borderRadius: "20px",
        backgroundColor: "#0074BF",
        color: "white",
        cursor: "pointer",
        fontWeight: 700,
        textTransform: "none",
        "@media (max-width: 414px)": {
            width: "260px",
        }
    },
    formWrapper: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        borderRadius: '26px',
        border: '1px solid rgb(252, 250, 250)',
        backgroundColor: 'rgb(255, 255, 255)',
        padding: '30px',
        "@media (max-width: 414px)": {
            width: "100%",
            padding: '20px ,0px'
        }
    },
    userStatus: {
        backgroundColor: "#FEE2E2",
        borderRadius: "10px",
        padding: "10px 8px",
        display: "flex",
        alignItems: "center",
        maxWidth: "360px",
        fontSize: "14px",
        borderLeft: "4px solid red",
        "@media (max-width: 414px)": {
            maxWidth: "100%",
        }
    },
    textStyle: {
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: 400,
        fontSize: "12px",
        color: "#DC2626",
        lineHeight: '18px'
    },
    statusMesssage: {
        margin: '15px 0px',
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: 400,
        fontSize: "16px",
        color: "#001C55",
        textAlign: 'center',
        "@media (max-width: 414px)": {
            fontSize: "12px"
        }
    }
};
// Customizable Area End