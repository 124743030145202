// Customizable Area Start
import React from 'react';
import { Box, Button, DialogContent, Dialog, DialogTitle, IconButton, InputLabel, Typography, Grid, styled } from '@mui/material';
import MultipleSelectWithChips from "./MultipleSelectWithChips";
import { Link } from 'react-router-dom';
import MultipleSelectWithCheckBox from "./MultipleSelectWithCheckBox";
import { leftArrow, warning, airPlane, button_collapse, button_expand } from "../assets";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CloseIcon from '@mui/icons-material/Close';

interface AccountSetupStep2Props {
    formValues: any;
    onChangeItems: (name: string, value: string[]) => void;
    handleAccordionChange: any
    onSubmit: (event: React.MouseEvent<HTMLButtonElement>) => void; 
    deleteDesignationRequest: any
    handlePrevious: () => void
    deleteAircraftTest: (id: string) => void
    getlabels: any
    isMobile?: boolean;
    addAircraft? : (value: string) => void;
    removeDesignation : (parentId: number, childId: string, key: string) => void
}
interface AccountSetupStep2States {
    openTest: boolean,
    checked: boolean,
    expanded: boolean
    testDialog: boolean
    currentTest: string
    currentTestId: string | null,
    currentTestParentId: any,
    currentSelectedItems: any
}
class AccountSetupStep2 extends React.Component<AccountSetupStep2Props, AccountSetupStep2States> {
    constructor(props: AccountSetupStep2Props) {
        super(props);
        this.state = {
            openTest: false,
            checked: false,
            expanded: false,
            testDialog: false,
            currentTest: '',
            currentTestId: null,
            currentTestParentId: '',
            currentSelectedItems: null
        };
    }
    
    handleDeleteClick = (parentId: any, childId: string, test: string, key: string) => {
        this.setState((prevState) => ({
            ...prevState,
            openTest: true,
            currentTest: key,
            currentTestId: childId,
            currentTestParentId: parentId
        }));
    };
    handleCloseDialog = () => {
        this.setState((prevState) => ({
            ...prevState,
            openTest: false,
            currentTest: '',
            currentTestId: null,
            currentSelectedItems: null
        }));
    };

    handleConfirmDelete = () => {
        this.props.deleteDesignationRequest(this.state.currentTestId)
        this.props.deleteAircraftTest(this.state.currentTestId || '')
        this.props.removeDesignation(this.state.currentTestParentId, this.state.currentTestId as any, this.state.currentTest);
        this.handleCloseDialog()
    };

    handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ checked: event.target.checked });
    };

    handleDeleteItemPopup = (name: string, selectedItems: any) => {
            this.setState((prevState) => ({
                ...prevState,
                currentSelectedItems: {
                    name,
                    selectedItems
                }
            }),()=> this.handleConfirmDeleteItem());
      };
    handleConfirmDeleteItem = () => {
        if (this.state?.currentSelectedItems) {
            this.props.onChangeItems(this.state?.currentSelectedItems?.name, this.state?.currentSelectedItems.selectedItems);
        }
        this.handleCloseDialog()
    }

    screnenSize = () => {
        return this.props.formValues?.aircraftTypes.length > 0 && this.props.formValues.addedAirCraftTypes.length > 0 ? "800px" : "360px"
    }

    getButtonClassName = (formValues: any): string => {
        if (["airplanes", "rotorcraft", "glider", "aircraftTypes"].every(key => formValues?.[key]?.length <= 0)) {
            return "buttonStyle";
        }
    
        return ["airplanes", "rotorcraft", "glider"].some((key) => this.props.formValues?.[key].length > 0 && formValues?.["aircraftTypes"].length > 0)
            ? "buttonStyleDesignations"
            : "buttonStyleDesignationsDisabled";
    };
    

    isValidFormValue = (event: any) => {
        const isEnabled = ["airplanes", "rotorcraft", "glider"].some(
            (key) => this.props.formValues[key].length > 0 && this.props.formValues["aircraftTypes"].length > 0
          )
        if (isEnabled) {
          return this.props.onSubmit(event)
        }
      
        return null;
    };

    renderAirctaftTypeDropdown(){
        const { formValues, onChangeItems, addAircraft } = this.props
        if(formValues?.rotorcraft.length < 0 || formValues?.airplanes.length < 0) return
        return  <Box className="inputWrapper">
            <InputLabel className='label'>Aircraft type (required)</InputLabel>
            <MultipleSelectWithChips 
                popUpHandler={this.handleDeleteItemPopup} 
                options={formValues.aircraftTypeOptions} 
                name='aircraftTypes' 
                placeholder="Enter aircraft type" 
                selectedItems={formValues.aircraftTypes} 
                onItemsChange={onChangeItems} 
                addAircraft = {addAircraft}/>
        </Box>
    }

    renderFirstAccordian(){
        const { formValues, onChangeItems } = this.props
        if(!formValues?.firstAccordionExpanded) return
        return  <Box>
                    <Box className="inputWrapper">
                        <InputLabel className='label'>Airplane</InputLabel>
                        <MultipleSelectWithCheckBox name='airplanes'
                            selectedItems={formValues?.airplanes}
                            onItemsChange={onChangeItems}
                            options={formValues?.airplanesOptions}
                            placeholder="Select airplane tests"
                            popUpHandler={this.handleDeleteItemPopup}
                            addedAirCraftTypes= {formValues?.addedAirCraftTypes}
                        />
                    </Box>
                    <Box className="inputWrapper">
                        <InputLabel className='label'>Rotorcraft</InputLabel>
                        <MultipleSelectWithCheckBox name='rotorcraft' onItemsChange={onChangeItems} selectedItems={formValues.rotorcraft}
                            options={formValues?.rotorcraftOptions}
                            placeholder="Select rotorcraft tests"
                            popUpHandler={this.handleDeleteItemPopup}
                            addedAirCraftTypes= {formValues?.addedAirCraftTypes}
                        />
                    </Box>
                    <InputLabel className='label'>Glider</InputLabel>
                    <MultipleSelectWithCheckBox name='glider' selectedItems={formValues.glider} onItemsChange={onChangeItems}
                        options={formValues.gliderOptions}
                        placeholder="Select glider tests"
                        popUpHandler={this.handleDeleteItemPopup}
                        addedAirCraftTypes= {formValues?.addedAirCraftTypes}
                    />
                    {this.renderAirctaftTypeDropdown()}
                </Box>
    }

    renderSelectedOptions(){
        const {formValues, getlabels} = this.props;
        if(formValues?.firstAccordionExpanded) return
        return <Box>
            <Typography className={"selectedOptions"}>{getlabels(formValues?.airplanesOptions, formValues?.airplanes)}</Typography>
            <Typography className={"selectedOptions"}>{getlabels(formValues?.rotorcraftOptions, formValues?.rotorcraft)}</Typography>
            <Typography className={"selectedOptions"}>{getlabels(formValues?.gliderOptions, formValues?.glider)}</Typography>
            <Typography className={"selectedOptions"}>{getlabels(formValues?.aircraftTypeOptions, formValues?.aircraftTypes)}</Typography>
        </Box>
    }

    renderSecondClosedAccordian() {
        const { formValues, handleAccordionChange } = this.props;
        const { aircraftTypes, secondAccordionExpanded, addedAirCraftTypes } = formValues || {};
    
        if (aircraftTypes?.length > 0) return null;
    
        return (
            <Grid item xs={12} sm={12} md={6}>
                <Box className="mainAccordianContainerForTest">
                    <Box className="accordian" onClick={() => handleAccordionChange("secondAccordionExpanded", !secondAccordionExpanded)}>
                        <Box className="expandIcon">
                            <img src={secondAccordionExpanded ? button_collapse : button_expand} alt="Expand/Collapse" />
                        </Box>
                        <Typography className="testsTypo">Tests</Typography>
                    </Box>
                    <Typography className="aircraftLength">{addedAirCraftTypes?.length}</Typography>
                </Box>
    
                {secondAccordionExpanded &&
                    addedAirCraftTypes?.map((addedAirCraftType: any, index: number) => (
                        <Typography key={index} className="selectedOptions">
                            {addedAirCraftType.value}
                        </Typography>
                    ))}
            </Grid>
        );
    }
    

    renderDesignations(){
        const { formValues } = this.props
        if(!formValues?.secondAccordionExpanded || formValues.aircraftTypes.length <= 0) return
        return  <Box>
                    <Typography className='designations'>Designations</Typography>
                    <Box className="inputWrapper">
                        {formValues.addedAirCraftTypes.map((test: any, index: number) => {
                            return (
                                <Box key={test.id} className="designationContainer">
                                    <Box className="designationName">
                                        <Box className="indexContainer">
                                            <Typography className="aircraftIndex">{index + 1}</Typography>
                                        </Box>
    
                                        <Typography className='selectedTests'>{test.value}</Typography>
                                    </Box>
                                    <IconButton onClick={() => this.handleDeleteClick(test.parentId ,test.id, test.value, test.key)}>
                                        <DeleteOutlineIcon className='deleteIconColor' />
                                    </IconButton>
                                </Box>
                            )
                        })}
                    </Box>
                </Box>
    }

    renderTest() {
        const { formValues } = this.props;
        if (!formValues || !formValues.secondAccordionExpanded || formValues.aircraftTypes.length > 0) return null;
        
        return formValues.addedAirCraftTypes.map((addedAirCraftType: any, index: number) => (
            <Typography key={index} className={"selectedOptions"}>{addedAirCraftType.value}</Typography>
        ));
    }
    
    renderAircrafts() {
        const { formValues } = this.props;
        if (!formValues || formValues.secondAccordionExpanded) return null;
        
        return formValues.addedAirCraftTypes.map((addedAirCraftType: any, index: number) => (
            <Typography key={index} className={"selectedOptions"}>{addedAirCraftType.value}</Typography>
        ));
    }

    renderTestDesignationsView() {
        const { formValues, handleAccordionChange } = this.props;
        const { addedAirCraftTypes, aircraftTypes, secondAccordionExpanded } = formValues || {};
        if ([addedAirCraftTypes].some(arr => !arr?.length) || aircraftTypes.length <= 0) return null;
    
        return (
            <Grid item xs={12} sm={12} md={6} className="gridItem1">
                <Box className="mainAccordianContainerForTest">
                    <Box 
                        className="accordian" 
                        onClick={() => handleAccordionChange("secondAccordionExpanded", !secondAccordionExpanded)}
                    >
                        <Box className="expandIcon">
                            <img src={secondAccordionExpanded ? button_collapse : button_expand} alt="Expand/Collapse" />
                        </Box>
                        <Typography className="testsTypo">Tests</Typography>
                    </Box>
                    <Typography className="aircraftLength">{addedAirCraftTypes.length}</Typography>
                </Box>
                {this.renderDesignations()}
                {this.renderTest()}
                {this.renderAircrafts()}
            </Grid>
        );
    }
    

    render() {
        const { isMobile,formValues, handlePrevious,  handleAccordionChange } = this.props;
                return (
            <CustomWrapper>
                <Box className="inputWrapper">
                    <Typography variant="h6" className='title'>
                        <img src={leftArrow} className='leftArrow' onClick={() => handlePrevious()} />
                        On the right track!
                    </Typography>

                    <Typography variant="h6" className='subTitle'>
                        Let's pick the tests you'll be offering:
                    </Typography>
                    <Typography variant="h6" className='subTitle2'>
                        <img src={airPlane.default} style={{ marginRight: '8px' }} />
                        What types of tests do you do?
                    </Typography>
                </Box>
                <Grid container sx={{minWidth: isMobile ? "360px" : this.screnenSize(), maxWidth: isMobile ? "360px" : this.screnenSize()}}>
                    <Grid item xs={12} sm={12} md={6} className='gridItem2'>
                        <Box className="mainAccordianContainer">
                            <Box className="accordian" onClick={() => handleAccordionChange('firstAccordionExpanded', !formValues?.firstAccordionExpanded)}>
                                <Box className="expandIcon">
                                    <img src={formValues?.firstAccordionExpanded ? button_collapse : button_expand}/>
                                </Box>
                                <Typography className='testsTypo'>Test specifications</Typography>
                            </Box>
                            { this.renderFirstAccordian()}
                        </Box>
                        {this.renderSelectedOptions()}
                       {this.renderSecondClosedAccordian()}
                    </Grid>
                    {this.renderTestDesignationsView()}
                    {<Dialog PaperProps={{
                            sx: {...webStyle.paperItemProps},
                        }} open={this.state.openTest} onClose={this.handleCloseDialog} >
                    <CustomDeleteTitle>
                        <img className="deleteIcon" src={warning} alt="delete" />
                            Delete test?
                        <IconButton
                        data-test-id="close-icon-delete"
                        aria-label="close"
                        onClick={this.handleCloseDialog}
                        sx={{ position: 'absolute', right: 8, top: 10, }}
                        >
                        <CloseIcon sx={webStyle.iconColor} />
                        </IconButton>
                    </CustomDeleteTitle>
                        <DialogContent sx={webStyle.customDialogTitle}>
                            <Typography sx={webStyle.content}><span>Are you sure you want to remove </span>
                                {this.state.currentTest ? <span style={{ fontWeight: 480 }}>{this.state.currentTest}</span> : ''}
                                <span> test? This action cannot be undone.</span>
                            </Typography>
                            <Box sx={webStyle.border} />
                            <Box sx={webStyle.actionsContainer}>
                                <CustomButton
                                    type="button"
                                    onClick={this.handleCloseDialog}
                                    data-test-id="LoginBtn"
                                    variant="outlined"
                                    sx={webStyle.deleteCancelButton}
                                >
                                    Cancel
                                </CustomButton>
                                <CustomButton
                                    type="button"
                                    onClick={this.handleConfirmDelete}
                                    className='buttonStyle'
                                    data-test-id="LoginBtn"
                                    variant="outlined"
                                    sx={webStyle.deleteButton}
                                >
                                    Yes, delete test
                                </CustomButton>
                            </Box>
                        </DialogContent>
                    </Dialog>}
                </Grid>
                <Box sx={{ width: '100%', display: 'flex', justifyContent: formValues?.addedAirCraftTypes.length > 0 ? 'center' : 'start' }}>
                    <Button
                        type="button"
                        onClick={this.isValidFormValue}
                        className={this.getButtonClassName(formValues)}
                        data-testId="ContinueBtn"
                        variant="contained"
                    >
                        Continue
                    </Button>
                </Box>

                <Typography variant="h6" align="center" sx={{
                    fontFamily: 'Montserrat, sans-serif',
                    fontWeight: 400,
                    fontSize: "16px",
                    color: "#0F172A",
                    display: "inline"
                }}>
                    <Link to="/Profile" style={{
                        fontFamily: 'Montserrat, sans-serif',
                        display: "inline",
                        fontWeight: 700,
                        fontSize: "16px",
                        color: "#0074BF",
                        textDecoration: "none",
                        marginLeft: "7px"
                    }}>
                        I'll finish this later
                    </Link>
                </Typography>
            </CustomWrapper>
        );
    }
}

const CustomButton = styled(Button)({
    textTransform: "capitalize",
    fontWeight: 700,
    fontSize: 16,
    width: 258,
    borderRadius: 20
})

const CustomWrapper = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    borderRadius: '26px',
    border: '1px solid rgb(252, 250, 250)',
    backgroundColor: 'rgb(255, 255, 255)',
    padding: '60px',
    overFlowX:"hidden",
    "@media (max-width: 414px)": {
        width: "336px",
        padding: '20px ,0px',
    },
    "& .designationContainer" :{
        display: 'flex', 
        justifyContent: 'space-between',
        alignItems: 'center', 
        marginBottom: '15px',
        border: '1px solid #CBD5E1',
        borderRadius: '15px',
        padding: '10px 10px',
        width: '360px',
        "@media (max-width: 414px)": {
            width: "90%",
        }
    },
    "& .designationName":{ display: 'flex', alignItems: 'center'},
    "& .designations":{fontFamily: "Montserrat", fontSize:"16px", fontWeight:600,color:"#334155", margin: "0px 0px 10px 0px"},
    "& .inputWrapper": {
        position: "relative",
        width: "100%",
        maxWidth: "360px",
    },
    "& .title": {
        display: 'flex',
        alignItems: 'center',
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: 700,
        fontSize: "22px",
        color: "#001C55",
    },
    "& .subTitle2": {
        margin: '15px 0px',
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: 400,
        fontSize: "18px",
        color: "#5274B5",
    },
    "& .leftArrow": { paddingRight: '10px', height: '24px', width: '24px', cursor:"pointer" },
    "& .subTitle": {
        margin: '15px 0px',
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: 400,
        fontSize: "16px",
        color: "#0F172A",
    },
    "& .label": {
        fontSize: "16px",
        fontWeight: 600,
        marginTop: "3px",
        color: "#334155",
        marginBottom:"6px"
    },
    "& .buttonStyleDesignationsDisabled": {
        width:"360px",
        opacity:"50%",
        border: "none",
        height: "50px",
        color: "white",
        fontSize:"16px",
        cursor: "pointer",
        fontWeight: 700,
        marginTop: "25px",
        marginBottom: "20px",
        borderRadius: "20px",
        backgroundColor: "#0074BF",
        textTransform: "none",
        "@media (max-width: 414px)": {marginBottom: "5px", marginTop: "5px", },
    },
    "& .buttonStyleDesignations": {
        marginBottom: "20px", width:"360px",
        height: "50px",
        marginTop: "25px",
        border: "none",
        borderRadius: "20px",
        cursor: "pointer",
        fontSize:"16px",
        fontWeight: 700,
        backgroundColor: "#0074BF",
        color: "white",
        textTransform: "none",
        "@media (max-width: 414px)": { marginBottom: "5px",
            marginTop: "5px", },
    },
    "& .buttonStyle": {
        marginBottom: "20px",
        border: "none",
        width:"100%",
        opacity:"50%",
        height: "50px",
        marginTop: "25px",
        color: "white",
        cursor: "pointer",
        borderRadius: "20px",
        backgroundColor: "#0074BF",
        fontSize:"16px",
        fontWeight: 700,
        textTransform: "none",
        "@media (max-width: 414px)": { marginBottom: "5px",
            marginTop: "5px"},
    '&.Mui-disabled': {
        backgroundColor: '#B2D5EC',
        color: '#FFF'
    },
    },
    "& .cancelbuttonStyle": {
        height: "50px",
        marginTop: "25px",
        width: "270px",
        marginBottom: "20px",
        borderRadius: "20px",
        cursor: "pointer",
        fontWeight: 700,
        color: '#0074BF',
        backgroundColor: "white",
        border: "1px solid #0074BF",
        textTransform: "none",
        "@media (max-width: 414px)": {
            marginBottom: "20px",
            marginTop: "15px",
        },
        '&:hover': {
        backgroundColor: 'white',
        color: '#0074BF',
        }
    },
    "& .selectedOptions": {
        fontFamily: 'Montserrat, sans-serif',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '18px',
        textAlign: 'left',
        textUnderlinePosition: 'from-font',
        textDecorationSkipInk: 'none',
        color: '#94A3B8',
        margin: '7px 0px',
        minWidth:"362px"
    },
    "& .deleteIconColor":{ color: '#F87171' },
    "& .selectedTests": {
        fontFamily: 'Montserrat,sans-serif',
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
        textAlign: 'left',
        paddingLeft: '12px',
        color:"#64748B"
    },

    "& .testsTypo": {
        fontFamily: 'Montserrat,sans-serif',
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '24px',
        textAlign: 'left',
        color: '#5274B5',
        marginLeft: '20px'
    },
    "& .aircraftLength": {
        fontFamily: 'Montserrat,sans-serif',
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '24px',
        textAlign: 'left',
        color: '#FFFFFF',
        height: '25px',
        width: '25px',
        backgroundColor: '#5274B5',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        justifySelf: 'end !important',
        marginLeft: '8px',
    },
    "& .indexContainer":{ width: '30px', boxSizing: 'border-box' },
    "& .aircraftIndex": {
        fontFamily: 'Montserrat,sans-serif',
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '24px',
        textAlign: 'left',
        color: '#94A3B8',
        height: '24px',
        width: '24px',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        justifySelf: 'end !important',
        margin: '0px 0px 0px 0px',
        border: '2px solid #94A3B8'
    },
    "& .accordian":{
        display:"flex",
        alignItems:"center",
        cursor: "pointer",
        marginBottom:"20px"
    },
    "& .mainAccordianContainer": {
        minWidth:"362px"
    },
    "& .mainAccordianContainerForTest": {
        minWidth:"362px",
        display:"flex",
        alignItems:"center",
        justifyContent:"space-between",
    },
    "& .gridItem1": {borderLeft: "1px solid #E2E8F0", maxWidth:"360px", paddingLeft:"40px"},
    "& .gridItem2": {maxWidth:"360px", marginRight:"40px"},
    "& .expandIcon": {
        width: '26px',
        height: '26px',
        color: '#5274B5', backgroundColor: 'rgb(227, 231, 238);',
        borderRadius: '50%',
        display:"flex",
        alignItems:"center",
        justifyContent: 'center',
    }
})

const webStyle = {
    paperItemProps: {
        width: '600px',
        borderRadius: '20px',
    },
    actionsContainer:{
        display:"flex",
        justifyContent:"space-between",
        padding: "20px 25px 25px 35px"
      },
    dialogTitle: {
        fontFamily: 'Montserrat,sans-serif',
        fontSize: "20px",
        fontWeight: 700,
        textAlign: 'left',
        color: '#001C55',
        padding: '20px 35px',
        display: 'flex',
        alignItems: 'center',
        borderBottom: '0.1px solid #e8e8e8',
        margin:"0px 10px"
    },
    iconColor : { color: '#94A3B8' },
    title: { display: 'flex', alignItems: 'center' },
    deleteIcon: { backgroundColor: '#E0F2F8', marginRight: '20px' },
    closeButton: {
         position: 'absolute', right: 8, top: 18,
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)'
        }
    },
    closeIcon: { fontSize: '20px', color: '#94A3B8' },
    customDialogTitle : { padding: "0px",
        display: 'flex',
        flexDirection:"column",
    },
    content: {
        fontFamily: 'Montserrat,sans-serif',
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
        textAlign: 'inline',
        color: '#0F172A',
        padding: '20px 0px 20px 35px',
        alignContent: 'center',
        boxSizing: 'border-box'

    },
    deleteCancelButton: {
        padding: "0px",
        color:"#0074BF",
        height: "50px",
        fontFamily: "Montserrat",
        fontWeight: 700,
        textTransform: "none",
        fontSize: "16px",
        borderRadius: "20px",
        width:"265px",
        border:"2px solid #0074BF",
       '&:hover': {
            backgroundColor: 'white',
            color: '#0074BF',
        }
      },
    border:{
        borderBottom:"1px solid #E2E8F0",
        marginTop:"15px"
    },
    deleteButton: {
        height: "50px",
        fontSize: "16px",
        padding: "0px 15px",
        fontWeight: 700,
        fontFamily: "Montserrat",
        textTransform: "none",
        borderRadius: "20px",
        color: "#ffffff",
        background: "#0074BF",
        width:"265px",
        '&:hover': {
            backgroundColor: 'white',
            color: '#0074BF',
        }
    },
    dontShow:{
        fontFamily: 'Montserrat, sans-serif',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '24px',
        textAlign: 'left',
        color: '#0F172A',
    }
};

const CustomDeleteTitle = styled(DialogTitle)({
    border:"1px solid #E2E8F0",
    fontSize: 22,
    fontWeight: "700",
    textAlign: 'left',
    color: "#001C55",
    fontFamily: "Montserrat",
    display: "flex",
    alignItems: "center",
    margin:"0px",
    padding:"16px 40px",
    "& .deleteIcon":{
      marginRight:"20px"
    }
})

export default AccountSetupStep2;

// Customizable Area End
