import React from "react";
// Customizable Area Start
import {
    Box,
    Typography,
    InputLabel,
    TextField,
    Menu, MenuItem ,
    styled,
    ListItemIcon,
    Dialog,
    DialogContent,
    Button,
    DialogTitle,

} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";

import {  pdfIcon } from "../../../email-account-registration/src/assets";
import {deletered,oneEdit,renameCross,renameTick,group_left,editOne,preveiw,delete_icon} from "../assets"
import { MoreVert } from '@mui/icons-material';
import { Link } from 'react-router-dom';

import CustomisableUserProfilesController, { Props } from "../CustomisableUserProfilesController";
import UploadPdfButton from '../../../email-account-registration/src/components/UploadPdfButton'

import IconButton from '@mui/material/IconButton';
const configJSON = require("../config");
// Customizable Area End

export default class TestSetting extends CustomisableUserProfilesController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    async componentDidMount() {
      super.componentDidMount();
     const setId= localStorage.getItem('setId')

      this.getSettingInst(setId)
     
    }
    formatFileSize = (sizeInBytes: number): string => {
      return `${(sizeInBytes / 1024).toFixed(2)} KB`;
    };
    // Customizable Area End

    render() {
      const { designationRes } = this.state;
      const open = Boolean(this.state.anchorEl);

      return (
        <CustomSettingsAirportsContainer>
          <Box className="headerContainer" data-test-id="test-details-page">
            <Box className="pageHeader">
              <Typography 
                data-test-id="testSettingsTitle" 
                onClick={() => this.navigateToTest()} 
                className="testSettingsTitle"
              >
                Tests settings
              </Typography>

              <Box className="designationContainer">
                <img className="group_left" src={group_left} alt="group_left" />
                <Typography className="title">Test details</Typography>
              </Box>


             

            </Box>
            <Box 

          style={{
              backgroundColor: this.state.isEdited ? "#00AFEF" : "#b4ebff", // 🚀 Enable when edited
              padding: "6px 20px",
              borderRadius: "20px",
              cursor: this.state.isEdited ? "pointer" : "not-allowed", // 🚀 Prevent click when disabled
            }}
            data-test-id='isEdited'
             onClick={() =>{ if (this.state.isEdited) this.editTest(this.state.designationRes.id)} }>
                <Typography style={{fontSize:'14px',color:'white',fontFamily:'Montserrat',fontWeight:700}}>Save</Typography>
              </Box>
          </Box>
    
          <Box style={{ padding: '50px' }}>
            <Box sx={webStyle.formWrapper}>
              {/* Designation */}
              <Box sx={webStyle.inputWrapper}>
                <Typography variant="h6" sx={webStyle.timeInstructions}>Designation</Typography>
                <Typography variant="h6" sx={webStyle.timeInstructionText}>
                  <img src={oneEdit} style={{ width: '24px', height: '24px' }} />
                  {designationRes.designation || "Loading..."}
                </Typography>
              </Box>
    
              {/* Exam Cost */}
              <Box sx={webStyle.examCostContainer}>
                <Box sx={webStyle.examCost}>
                  <InputLabel style={webStyle.label}>Exam cost</InputLabel>
                  <TextField
                    data-test-id="exam-cost"
                    name="exam_cost"
                    value={designationRes.exam_cost || ""}
                    onChange={this.handleInputChange1}
                    size="medium"
                    fullWidth
                    variant="outlined"
                    type="text"
                    placeholder="e.g., $1,000.00"
                    sx={{
                      backgroundColor: "#ffffff",
                      color: "#94A3B8",
                      mb: "3px",
                    }}
                    InputProps={{
                      style: { borderRadius: '20px', fontWeight: 400 }
                    }}
                  />
                </Box>
    
                {/* Accepted Payment Methods */}
                <Box sx={webStyle.paymentMethod}>
                  <InputLabel style={webStyle.label}>Accepted payment methods</InputLabel>
                  <TextField
                    id="paymentMethods"
                    data-test-id="payment-methods"
                    name="accepted_payment_methods"
                    value={designationRes.accepted_payment_methods || ""}
                    onChange={this.handleInputChange1}
                    size="medium"
                    variant="outlined"
                    placeholder="e.g., Credit Card, PayPal, Cash"
                    fullWidth
                    type="text"
                    sx={{
                      backgroundColor: "#ffffff",
                      color: "#94A3B8",
                      mb: "3px",
                    }}
                    InputProps={{
                      style: { borderRadius: '20px', fontWeight: 400 }
                    }}
                  />
                </Box>
              </Box>
    
              {/* Instructions */}
              <Box sx={webStyle.inputWrapperInstruction}>
                <InputLabel style={webStyle.label}>Instructions</InputLabel>
                <TextField
                  data-test-id="instructions"
                  name="instructions"
                  value={designationRes.instructions || ""}
                  onChange={this.handleInputChange1}
                  rows={7}
                  multiline
                  size="medium"
                  fullWidth
                  variant="outlined"
                  type="text"
                  placeholder="Enter detailed instructions or guidelines for the designation here"
                  sx={{
                    backgroundColor: "#ffffff",
                    mb: "3px",
                    color: "#94A3B8",
                  }}
                  InputProps={{
                    style: { fontWeight: 400, borderRadius: '20px' }
                  }}
                />
              </Box>
              <Box sx={webStyle.uploadFileBox}>
              {this.state.step3Form.uploadedFiles.map((file, index) => (
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      padding: "10px",
      gap: "15px",
      borderRadius: "20px",
      border: this.state.isRenaming === file ? "none" : "1px solid #F1F5F9", // Remove border when renaming
    }}
    key={index}
  >
    <Box sx={{ marginRight: 1 }}>
      <img src={pdfIcon} width="16px" height="16px" alt="PDF Icon" />
    </Box>

    <Box sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
      {this.state.isRenaming === file ? (
        <>
          <TextField
            value={this.state.tempFileName}
            onChange={this.handleRenameChange}
            size="small"
            variant="standard"
            InputProps={{
              disableUnderline: true,
              style: { fontSize: "14px", width: "120px" },
            }}
          />
        </>
      ) : (
        <Box>
          <Typography
            variant="body1"
            sx={{ color: "#0F172A", fontSize: "14px", fontWeight: 400 }}
          >
            {file.name}
          </Typography>
          <Typography variant="body2" sx={{ color: "#94A3B8", fontSize: "12px" }}>
            {file.size}
          </Typography>
        </Box>
      )}
    </Box>

    {this.state.isRenaming === file ? (
      <Box sx={{ display: "flex", gap: "5px" }}>
        <IconButton onClick={this.handleRenameConfirm} size="small">
          <img src={renameTick} style={{width:'26px',height:'26px'}}/>
        </IconButton>
        <IconButton onClick={this.handleRenameCancel} size="small">
          <img src={renameCross}  style={{width:'26px',height:'26px'}}/>

        </IconButton>
      </Box>
    ) : (
      <IconButton
        size="small"
        onClick={(event) => this.handleMenuOpen(event, file)}
        data-test-id="handleMenuOpen"
      >
        <MoreVertIcon />
      </IconButton>
    )}
  </Box>
))}



  {/* Menu for file actions */}
  <Menu
    anchorEl={this.state.anchorEl}
    open={this.state.anchorEl !== null}
    onClose={this.handleMenuClose}
    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
    transformOrigin={{ vertical: "top", horizontal: "right" }}
    slotProps={{
      paper: {
        sx: {
          backgroundColor: "#DEDEE8",
          borderRadius: "12px",
          zIndex: 99999,
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)",
        },
      },
    }}
  >
    <MenuItem 
    sx={{ display: "flex", alignItems: "center", gap: "20px" }}
    onClick={this.handlePreviewFile}
    >
      <Typography variant="inherit">Preview</Typography>
      <ListItemIcon>
        <img src={preveiw} width="20px" height="20px" alt="Preview" />
      </ListItemIcon>
    </MenuItem>
    <MenuItem sx={{ display: "flex", alignItems: "center", gap: "20px" }} onClick={() => this.handleRenameFile(this.state.selectedFile)}>
      <Typography variant="inherit">Rename</Typography>
      <ListItemIcon>
        <img src={editOne} width="20px" height="20px" alt="Rename" />
      </ListItemIcon>
    </MenuItem>

    <MenuItem sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}onClick={this.handleDeleteFile}>
      <Typography variant="inherit">Delete</Typography>
      <ListItemIcon>
        <img src={deletered} width="15px" height="20px" alt="Delete" />
      </ListItemIcon>
    </MenuItem>
  </Menu>

  {/* Upload button */}
  <Box sx={webStyle.uploadBtnBox}>
    <UploadPdfButton data-test-id="upload-pdf-button" onFileSelect={(event) => this.handleFileSelect(event)} />
  </Box>
</Box>


              {/* Update Button */}
              <Box sx={webStyle.containerBox}>
                <BoxContainer>
                  <Box
                    style={{
                      border: '1px solid #E2E8F0',
                      padding: '6px 80px',
                      borderRadius: '20px',
                      alignItems:"center",
                      display: 'flex',
                      marginTop: '50px',
                      cursor: 'pointer',
                      gap:'10px'
                    }}
                    // onClick={this.handleUpdate}
                  >
                    <img src={deletered} style={{ width: '14px', height: '16px' }} />
                    <Typography style={{ color: '#F87171', fontSize: '14px', fontWeight: 700,fontFamily:'Montserrat' }}
                    onClick={() => this.setState({ deleteTestDialogOpen: true })}

                    >
                    Delete test
                    </Typography>
                  </Box>
                </BoxContainer>
              </Box>
            </Box>
          </Box>



          <Dialog open={this.state.deleteTestDialogOpen}
            data-test-id="deleteAirportDialogOpen7"
              onClose={() => { 
              this.setState({ deleteTestDialogOpen: false })
              }} maxWidth="sm" fullWidth PaperProps={{style: { borderRadius: 16, width: "600px", overlay:"none"}}} >
              <CustomDeleteTitle>
                <img className="deleteIcon" src={delete_icon} alt="delete" />
                Delete test?
                <IconButton
                  data-test-id="deleteAirportDialogOpen9"
                  aria-label="close"
                  onClick={() => this.setState({ deleteTestDialogOpen: false })}
                  sx={{ position: 'absolute', right: 8, top: 18, }}
                >
                  <CloseIcon sx={webStyle.icons} />
                </IconButton>
              </CustomDeleteTitle>
              <DialogContent sx={webStyle.deleteDialogContent} >
                <Typography sx={webStyle.deleteText}>
                  Are you sure you want to remove the <span style={webStyle.airportName}>{`${designationRes.instructions}`}</span> test? You can restore it anytime from the deleted section.
                </Typography>
                <Box sx={webStyle.border} />
                <Box sx={webStyle.actionsContainer}>
                    <CustomButton
                      data-test-id="deleteAirportDialogOpen6"
                      variant="outlined"
                      sx={webStyle.deleteCancelButton}
                      onClick={() => this.setState({deleteTestDialogOpen: false})}>
                        Cancel
                    </CustomButton>
                    <CustomButton
                      data-test-id="delete-aircraft"
                      variant="contained"
                      color="primary"
                      sx={webStyle.deleteButton}
                      onClick={() => this.handleRemoveTest(designationRes.id)}
                      >
              Yes, delete test
                    </CustomButton>
                </Box>
              </DialogContent>
             </Dialog>
        </CustomSettingsAirportsContainer>
      );
    }
    
    
}

// Customizable Area Start
const CustomSettingsAirportsContainer = styled(Box)({
    "& .airportsText":{
        fontFamily: "Montserrat",
        fontWeight: 600,
        fontSize: "16px",
        color: "#334155",
    },
    "& .headerContainer":{
        display: "flex",padding: "10px 50px 10px 50px",
        alignItems: "center",
        height: "60px",
        background: "#ffffff",justifyContent:"space-between"
    },
    "& .testSettingsTitle":{ 
        color:"#0074BF",
        fontFamily: "Montserrat",
        fontWeight: 500,
        fontSize: "20px",
        cursor:"pointer"
      },
      "& .designationContainer":{
        alignItems:"center",
        display:"flex",
        justifyContent:"center",
      },
      "& .title":{
        fontFamily: "Montserrat", fontSize: "22px",
        color:"#0074BF",
        fontWeight: 700,
       
    },
    "& .airportName":{
        fontFamily: "Montserrat",
        fontWeight: 500,
        fontSize: "16px",
        color: "#0F172A",
        marginLeft:"20px"
    },
    "& .airportsTitle":{
        fontFamily: "Montserrat",
        fontWeight: 600,
        fontSize: "14px",
        color: "#94A3B8",
        margin:"20px 0px 10px 25px",
        textTransform:"uppercase"
    },
    "& .airportIconContainer":{
      display:"flex",
      justifyContent:"space-between",
    },
    "& .airportContainer":{
      margin:"20px 25px 20px 25px",
      display:"flex",
      justifyContent:"space-between"
    },
    "& .border":{
      borderBottom:"1px solid #E2E8F0",
    },
    "& .pointer":{
      cursor:"pointer"
    },
    "& .group_left":{marginRight:"20px"},
    "& .pageHeader": {
      gap:'20px', display:'flex',
      alignItems:'center',
  },
})
const CustomDeleteTitle = styled(DialogTitle)({
  border:"1px solid #E2E8F0",
  fontSize: 22,
  fontWeight: "700",
  textAlign: 'left',
  color: "#001C55",
  fontFamily: "Montserrat",
  display: "flex",
  alignItems: "center",
  margin:"0px",
  padding:"16px 40px",
  "& .deleteIcon":{
    marginRight:"20px"
  }
})

const CustomButton = styled(Button)({
  textTransform: "capitalize",
  fontWeight: 700,
  fontSize: 16,
  width: 258,
  borderRadius: 20
})
const webStyle = {
  fileInfo: {flex: 1},

  deleteCancelButton: {
    padding: "0px",
    color:"#0074BF",
    height: "50px",
    fontFamily: "Montserrat",
    fontWeight: 700,
    textTransform: "none",
    fontSize: "16px",
    borderRadius: "20px",
    width:"265px",
    border:"2px solid #0074BF"
  },
  deleteButton: {
    height: "50px",
    fontSize: "16px",
    padding: "0px 15px",
    fontWeight: 700,
    fontFamily: "Montserrat",
    textTransform: "none",
    borderRadius: "20px",
    color: "#ffffff",
    background: "#0074BF",
    width:"265px",
  },
    actionsContainer:{
      display:"flex",
      justifyContent:"space-between",
      padding: "20px 25px 25px 25px"
    },
    border:{
      borderBottom:"1px solid #E2E8F0",
    },
    deleteText: {
      margin:"25px 45px",
      color:"#0F172A",
      fontFamily: "Montserrat",
      fontWeight: 400,
      fontSize: "16px",
    },
    deleteDialogContent:{ padding: "0px"},
    icons:{color:"#94A3B8", cursor: "pointer"},
    leftArrow:{ 
        marginRight: "10px", 
        height: '24px', 
        width: '24px', 
        color: "#0074BF" 
      },
      airportName:{
        fontFamily: "Montserrat",
        fontWeight: 500,
        fontSize: "16px",
        color: "#0F172A",
      },
      headerText: {
        fontFamily: "Montserrat",
        fontWeight: 700,
        fontSize: "22px",
        color: "#0074BF",
    },
    // headerContainer: {
    //     display: "flex",
    //     height: "60px",
    //     padding: "10px 50px 10px 50px",
    //     alignItems: "center",
    //     background: "#ffffff",
    //     justifyContent:"space-between"
    // },
    formWrapper: {
      justifyContent: 'flex-start',
      display: 'flex',
      border: '1px solid rgb(252, 250, 250)',
      backgroundColor: 'rgb(255, 255, 255)',
      flexDirection: 'column',
      borderRadius: '26px',
      padding: '60px',
  
      "@media (max-width: 660px)": {
        width: "96%",
        padding: '20px ,0px',
      },
      "@media (max-width: 600px)": {
        width: "90%",
        padding: '20px ,0px',
      },
    },
    timeInstructions:{
      fontFamily: 'Montserrat, sans-serif',display: 'flex',
      alignItems: 'center', fontSize: "22px", color: "#001C55",
      fontWeight: 700,
      "@media (max-width: 414px)": {
        fontSize: "18px",
      }
    },
    backIcon: { padding: '7px 7px 7px 0px', height: '21px', width: '24px', cursor:"pointer" },
    uploadIcon:{ color: '#94A3B8', fontSize: 20 },
    containerBox: { width: '100%', display: 'flex', justifyContent: 'center' },
    finishLaterText: {
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 400,
      fontSize: "16px",
      color: "#0F172A",
      display: "inline"
    },
    linkStyle: {
      fontFamily: 'Montserrat, sans-serif',
      display: "inline",
      fontWeight: 700,
      fontSize: "16px",
      color: "#0074BF",
      textDecoration: "none",
      marginLeft: "7px"
    },
    timeInstructionText: {
      margin: '15px 0px',
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 400,
      fontSize: "16px",
      color: "#0F172A",
      backgroundColor:'#F1F5F9',
      padding:'10px 15px 10px 15px',
      display:'flex',
      alsignItems:'center',
      gap:'8px',
      borderRadius:'20px',
      "@media (max-width: 414px)": {
        fontSize: "14px",
      }
    },
    backIconStyle :{ 
      padding: '7px', 
      height: '21px', 
      width: '24px' ,
      "@media (max-width: 414px)": {
        padding: '7px 7px 7px 0px',
      }
    },
    examCostContainer :{
      width: "100%",
      display: 'flex', 
      justifyContent: 'space-between',
      "@media (max-width: 600px)": {
       display: "block"
      }
    },
    examCost :{
      width: '300px',
      "& .Mui-error":{margin:"3px 0px"},
      "@media (max-width: 600px)": {
        width: "100%",
      }
    },
    paymentMethod :{
      width: '300px',
      marginLeft: '20px',
      "& .Mui-error":{margin:"3px 0px"},
      "@media (max-width: 600px)": {
        width: "100%",
        marginLeft: '0px',
      }
    },
    inputWrapper: {
      position: "relative",
      width: "100%",
      marginBottom:"10px",
      "& .Mui-error":{margin:"3px 0px"},
    },
    inputWrapperInstruction: {
      position: "relative",
      width: "100%",
      marginBottom:"10px",
      marginTop:"15px",
      "& .Mui-error":{margin:"3px 0px"},
    },
    label: {
      fontWeight: 600,
      marginTop: "3px",
      fontSize: "16px",
      color: "#334155",
    },
    container: {
      gap: 2, display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      width: '100%',
    },
    fileList: {
      width: '100%',
      gap: 1, display: 'flex',
      flexDirection: 'row',
      "@media (max-width: 414px)": { display: "block"}
    },
    fileList1: {
        gap: 1, display: 'flex',
        flexDirection: 'row',
        width: '100%',
        "@media (max-width: 414px)": { display: "block"}
      },
    fileItem: {
      borderRadius: '20px',
      backgroundColor: '#F8FAFC',
      flexDirection: 'row', display: 'flex',
      alignItems: 'center',
      border: '1px solid #F1F5F9',
      padding: '8px',
      width: '210px',
      "@media (max-width: 414px)": { width: "100%" }
    },
fileItem1: {
        alignItems: 'center',
        flexDirection: 'row', display: 'flex',
        backgroundColor: '#F8FAFC',
        border: '1px solid #F1F5F9',
        padding: '8px',
        borderRadius: '20px',
        width: '210px',
        "@media (max-width: 414px)": { width: "100%" }
      },
    fileIcon: {
      borderRadius: '8px',
      marginRight: '15px',
      width: '30px',
      backgroundColor: '#E0F2F8',
      height: '30px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
  
    },
    fileInfor: {flex: 1},
    fileName: {
      fontWeight: 500,fontSize: '14px',
      color: '#334155',
    },
    fileSize: {
      color: '#64748B',fontSize: '12px',
    },
    uploadButton: {
      backgroundColor: '#E0F2FE',
      color: '#0EA5E9',
      cursor: 'pointer',fontWeight: 500,
      padding: '8px 16px',borderRadius: '8px',
      fontSize: '14px',
  
      '&:hover': {
        backgroundColor: '#BAE6FD',
      },
    },
    uploadFileBox: {
      alignItems: 'center',
      display: 'flex',
      gap:'14px',
      position: "relative", overflow: "visible", zIndex: 1,
      "@media (max-width: 414px)": { display: "block"}
    },
    uploadBtnBox : { justifySelf: 'end',
      "@media (max-width: 414px)": {
        display: "flex",
        justifySelf: "center",
        width: "100%",
      } 
    }
  };
  
  const BoxContainer = styled(Box)({
     "& .activeButtonStyle": {
      border: "none",
      borderRadius: "20px",
      backgroundColor: "#0074BF",
      height: "50px",
      marginTop: "25px",
      marginBottom: "20px",
      color: "white",
      textTransform: "none",
      cursor: "pointer",
      fontWeight: 700,
      width: '360px',
      "@media (max-width: 414px)": { width: "300px",}
    },
     "& .buttonStyle": {
      height: "50px",
      fontWeight: 700,
      border: "none",
      borderRadius: "20px",
      width: '360px',
      textTransform: "none",
      color: "white",
      backgroundColor: "#B2D5EC",
      marginTop: "25px",
      marginBottom: "20px",
      "@media (max-width: 414px)": { width: "300px"}
    },
  })
// Customizable Area End