// Customizable Area Start
import React from "react";
import {
    Box,
    Typography,
    styled,
    DialogTitle,
    Button,
    InputLabel,
    Dialog,
    IconButton,
    DialogContent,
    FormControlLabel,
    Checkbox,
    BoxProps,
    Portal,
    Snackbar
  } from "@mui/material";
import CustomisableUserProfilesController, { Props } from "../CustomisableUserProfilesController";
import { back_button, add_icon, right_arrow, delete_tests, group_left, warning} from "../assets";
import { Link } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import MultipleSelectWithCheckBox from "./MultipleSelectWithCheckBox";

interface CustomBoxSnackbarProps extends BoxProps {
  alertType?: 'success' | 'error';
}

export default class SettingsTests extends CustomisableUserProfilesController {
  constructor(props: Props) {
    super(props);
  }


  renderDesignation() {
    const { testSettingsDesignations } = this.state;
    if (testSettingsDesignations.length <= 0) return null;
  
    return (
      <>
        {testSettingsDesignations.map((des, index) => (
          <Box 
            className="testDetailsContainer" 
            key={des.service_subcategory_id} 
            onClick={() => this.handleToNext(des.service_subcategory_id)} 
          >
            <Box className="designationContainer">
              <Box className="testsIndex">{index + 1}</Box>
              <Box className="designation">{des.service_subcategory_name}</Box>
            </Box>
            <img src={right_arrow} alt="right_arrow" />
          </Box>
        ))}
      </>
    );
  }
  

  render() {
    const { isEditTestsSettingsOpen, 
            testSettingsDesignations, 
            isTestSpecificationOpen, 
            gradeOfCirtificates, 
            gradeOptions, 
            addedAirCraftTypes, 
            category, 
            categoryOptions,
            classOptions,
            classes,
            openItem
           } = this.state
    return(
        <CustomSettingsTestsContainer>
            <Portal>
              <Snackbar  anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                onClose={this.oncloseAlert} open={this.state.isAlert}
                autoHideDuration={300000}
                data-test-id="alertTestId"
              ><CustomBoxSnackbar alertType={this.state.alertType as any}>
                    <img  src={this.getAlertIcon()}  alt={"icon"} className="snackbarImage"/>{this.state.alertMsg}
                </CustomBoxSnackbar>
              </Snackbar>
            </Portal>
            <Box className="headerContainer" data-test-id = "test-details-page">
                <Box className="pageHeader">
                   {isEditTestsSettingsOpen && 
                      <Link to={"/calendar"}> 
                        <img data-test-id = "back-to-create-availability" 
                            onClick={() => this.handleBackToCreateAvailability()} 
                            src={back_button} 
                            className="leftArrow" />
                      </Link>}
                   <Typography data-test-id="testSettingsTitle" onClick={() => this.handleClickTitle()} className={isTestSpecificationOpen ? "testSettingsTitle":"headerText"} >Tests settings</Typography>
                   {isTestSpecificationOpen && <Box className="designationContainer">
                       <img className="group_left" src={group_left} alt="group_left"/>
                      <Typography className="title">Test specifications</Typography>
                    </Box>
                   }
                </Box>
                {!isTestSpecificationOpen 
                    ? <img data-test-id="add_icon" 
                        className="add_icon" 
                        onClick={() => this.setState({isTestSpecificationOpen : !isTestSpecificationOpen})} 
                        src={add_icon} alt="add_icon"/> 
                    : 
                    <Button 
                      data-test-id="save-test-settigs"
                      className={this.state.isSaveTestSettingsEnabled ? "setDetailsButton" : "saveButton"}
                      onClick={() => this.handleSaveTestSettings()}
                      >Save</Button>
                }
            </Box>

            {!isTestSpecificationOpen ? (<Box className="outerContainer">
                <Box className="contentContainer">
                    <Box className="designationContainer">
                    <Typography className="testsText">Tests</Typography>
                      <Box className="testsCount">{testSettingsDesignations.length}</Box>
                    </Box>
                    {this.renderDesignation()}
                    <Box data-test-id="deletedTestTitle" className="deletedTestTitle" onClick={() => this.navigateToDeletedTests()}>
                    <Box className="designationContainer">
                        <img className="deleteIcon" src={delete_tests} alt="delete_tests"/>
                        <Typography className="testsText">Deleted tests</Typography>
                      </Box>
                      <img src={right_arrow} alt="right_arrow" />
                    </Box>
                </Box>
            </Box>): (
              <Box className="outerContainer">
                <Box className="contentContainer">
                  <Box className="designationContainer">
                    <Box className="inputWrapper">
                      <InputLabel className="label">Grade of certificate</InputLabel>
                      <MultipleSelectWithCheckBox name='gradeOfCirtificates'
                          selectedItems={gradeOfCirtificates}
                          onItemsChange={this.onChangeItems}
                          options={gradeOptions}
                          placeholder="Select a grade of certificate"
                          popUpHandler={this.handleDeleteItemPopup}
                          addedAirCraftTypes= {addedAirCraftTypes}
                      />
                    </Box>
                  </Box>
                  <Box className="designationContainer">
                    <Box className="inputWrapper">
                        <InputLabel className="label">Category</InputLabel>
                        <MultipleSelectWithCheckBox name='category' onItemsChange={this.onChangeItems} selectedItems={category}
                            options={categoryOptions}
                            placeholder="Select category"
                            popUpHandler={this.handleDeleteItemPopup}
                            addedAirCraftTypes= {addedAirCraftTypes}
                        />
                    </Box>
                  </Box>
                  <Box className="designationContainer">
                    <Box className="inputWrapper">
                        <InputLabel className="label">Class</InputLabel>
                        <MultipleSelectWithCheckBox name='classes' 
                            selectedItems={classes} 
                            onItemsChange={this.onChangeItems}
                            options={classOptions}
                            placeholder="Select class"
                            popUpHandler={this.handleDeleteItemPopup}
                            addedAirCraftTypes= {addedAirCraftTypes}
                        />
                    </Box>
                  </Box>
                </Box>
              </Box>
            )}

          <CustomDialog open={openItem}
               onClick={this.handleCloseDialog} maxWidth="sm" fullWidth PaperProps={{style: { borderRadius: 16, width: "600px", overlay:"none"}}} >
              <CustomDeleteTitle>
                <img className="deleteIcon" src={warning} alt="delete" />
                   Remove item?
                <IconButton
                  data-test-id="close-icon-delete"
                  aria-label="close"
                  onClick={this.handleCloseDialog}
                  sx={{ position: 'absolute', right: 8, top: 18, }}
                >
                  <CloseIcon className="icons" />
                </IconButton>
              </CustomDeleteTitle>
              <DialogContent className="deleteDialogContent" >
                <Typography className="deleteText">
                  This action will remove the item and all linked tests. 
                  Any details filled in for these tests cannot be recovered. 
                  However, any tests that are already booked will remain unaffected. 
                  You can re-add the item later without any of your previous details.
                </Typography>

                <FormControlLabel className="checkBox"
                    control={
                        <Checkbox
                            checked={this.state.checked}
                            onChange={this.handleCheckedChange}
                            name="myCheckbox"
                            data-test-id="myCheckbox"
                            color="primary"
                        />
                    }
                    label={
                        <Typography  className="dontShow">
                            Don't show this again
                        </Typography>
                    }
                />

                <Box className="border" />
                <Box className="actionsContainer">
                    <CustomButton
                      data-test-id="cancel-delete"
                      variant="outlined"
                      className="deleteCancelButton"
                      onClick={() => this.handleCloseDialog()}>
                        Cancel
                    </CustomButton>
                    <CustomButton
                      data-test-id="delete-airport"
                      variant="contained"
                      color="primary"
                      className="deleteButton"
                      onClick={() => this.handleConfirmDeleteItem()}
                      >
                        Yes, remove item
                    </CustomButton>
                </Box>
              </DialogContent>
             </CustomDialog>
        </CustomSettingsTestsContainer>
    )
  }
}

const CustomButton = styled(Button)({
  textTransform: "capitalize",
  fontWeight: 700,
  fontSize: 16,
  width: 258,
  borderRadius: 20
})

const CustomDialog = styled(Dialog)({
  "& .deleteDialogContent":{ padding: "0px"},
  "&. icons":{color:"#94A3B8", cursor: "pointer"},
  "& .deleteIcon":{
    marginRight:"20px"
  },
  "& .actionsContainer":{
    display:"flex",
    justifyContent:"space-between",
    padding: "20px 25px 25px 25px"
  },
  "& .checkBox":{
    padding: '0px 0px 10px 40px',
  },
  "& .border":{
      borderBottom:"1px solid #E2E8F0",
  },
  "& .dontShow":{
    fontFamily: 'Montserrat, sans-serif',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    textAlign: 'left',
    color: '#0F172A',
  },
  "& .deleteText": {
    margin:"25px 45px 10px 45px",
    color:"#0F172A",
    fontFamily: "Montserrat",
    fontWeight: 400,
    fontSize: "16px",
  },
  "& .deleteCancelButton": {
    padding: "0px",
    color:"#0074BF",
    height: "50px",
    fontFamily: "Montserrat",
    fontWeight: 700,
    textTransform: "none",
    fontSize: "16px",
    borderRadius: "20px",
    width:"265px",
    border:"2px solid #0074BF",
  },
  "& .deleteButton": {
    height: "50px",
    fontSize: "16px",
    padding: "0px 15px",
    fontWeight: 700,
    fontFamily: "Montserrat",
    textTransform: "none",
    borderRadius: "20px",
    color: "#ffffff",
    background: "#0074BF",
    width:"265px",
  },
})

const CustomDeleteTitle = styled(DialogTitle)({
  border:"1px solid #E2E8F0",
  fontSize: 22,
  fontWeight: "700",
  textAlign: 'left',
  color: "#001C55",
  fontFamily: "Montserrat",
  display: "flex",
  alignItems: "center",
  margin:"0px",
  padding:"16px 40px",
  "& .deleteIcon":{
    marginRight:"20px"
  }
})

const CustomBoxSnackbar = styled(Box)<CustomBoxSnackbarProps>(({ alertType }) => ({
  backgroundColor: "#fff",
  color: alertType === "success" ? "#0F172A" : "red",
  padding: "8px 16px 8px 8px",
  fontFamily: 'Montserrat, sans-serif',
  borderRadius: "20px",
  fontSize:"16px",
  boxShadow: "0px 6px 15px -3px #00000026", 
  "& .snackbarImage":{
    width: "20px", 
    height: "20px",
    marginRight: "8px", 
    verticalAlign: "middle",
    objectFit:'contain'
  },
}));

const CustomSettingsTestsContainer = styled(Box)({
    "& .add_icon":{
      cursor:"pointer"
    },
    "& .testsText":{
        fontFamily: "Montserrat",
        fontWeight: 500,
        fontSize: "16px",
        color: "#5274B5",
    },
    "& .label": {
      fontSize: "16px",
      fontWeight: 600,
      marginTop: "3px",
      color: "#334155",
      marginBottom:"6px"
    },
    "& .inputWrapper": {
      position: "relative",
      width: "100%",
      marginBottom: "10px",
    },
    "& .testSettingsTitle":{ 
      color:"#0074BF",
      fontFamily: "Montserrat",
      fontWeight: 500,
      fontSize: "20px",
      cursor:"pointer"
    },
    "& .outerContainer":{padding:'50px 50px 50px 50px'},
    "& .title":{
        fontFamily: "Montserrat",
        fontWeight: 700,
        fontSize: "22px",
        color:"#0074BF",
    },
    "& .designation":{
      fontFamily: "Montserrat",
        fontWeight: 400,
        fontSize: "16px",
        color: "#0F172A",
        marginLeft:"15px"
    },
    "& .testsCount":{
        width:"24px",
        height:"24px",
        backgroundColor:"#5274B5",
        opacity:"50%",
        borderRadius:"50%",
        alignItems:"center",
        justifyContent:"center",
        display:"flex",
        color:"#fff"
    },
    "& .testsIndex":{
        width:"20px",
        height:"20px",
        border:"2px solid #94A3B8",
        borderRadius:"50%",
        alignItems:"center",
        justifyContent:"center",
        display:"flex",
        color:"#94A3B8"
    },
    "& .testDetailsContainer":{
      border:"1px solid #CBD5E1",
      borderRadius:"20px",
      display: "flex",
      justifyContent:"space-between",
      height:"44px",
      alignItems:"center",
      padding:"0px 15px",
      margin:"20px 0px",
      cursor:"pointer"
    },
    "& .deletedTestTitle":{
      borderTop:"1px solid #E2E8F0",
      borderBottom:"1px solid #E2E8F0",
      display: "flex",
      justifyContent:"space-between",
      height:"58px",
      alignItems:"center",
      padding:"0px 15px",
      marginTop:"30px",
      cursor:"pointer"
    },
    "& .deleteIcon":{ marginRight:"20px", width:"26px", height:"26px"},
    "& .headerContainer":{
        display: "flex",
        height: "60px",
        padding: "10px 50px 10px 50px",
        alignItems: "center",
        background: "#ffffff",
        justifyContent:"space-between"
    },
    "& .designationContainer":{
      display:"flex",
      justifyContent:"space-between",
      alignItems:"center",
    },
    "& .group_left":{
      marginRight:"20px"
    },
    "& .pageHeader": {
      display:'flex',
      alignItems:'center',
      gap:'20px'
  },
  "& .headerText": {
      fontFamily: "Montserrat",
      fontWeight: 700,
      fontSize: "22px",
      color: "#0074BF",
  },
  "& .contentContainer": {
      padding: "50px 50px 50px 50px",
      alignItems: "center",
      background: "#ffffff",
  },
  "& .saveButton": {
      height: "40px",
      fontSize: "16px",
      padding: "0px",
      fontWeight: 700,
      fontFamily: "Montserrat",
      textTransform: "none",
      opacity: "30%",
      borderRadius: "20px",
      color: "#ffffff",
      background: "#00AFEF",
      width:"80px",
      "&:hover":{
        border:"2px solid #00AFEF",
        color:"#00AFEF",
        backgroundColor:"#fff"
      }
    },
  "& .setDetailsButton": {
      height: "40px",
      fontSize: "16px",
      padding: "0px",
      fontWeight: 700,
      fontFamily: "Montserrat",
      textTransform: "none",
      borderRadius: "20px",
      color: "#ffffff",
      width:"80px",
      background: "#00AFEF",
      "&:hover":{
        border:"2px solid #00AFEF",
        color:"#00AFEF",
        backgroundColor:"#fff"
      }
    },
})
// Customizable Area End
