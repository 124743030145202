Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";

exports.btnExampleTitle = "CLICK ME";
exports.upcomingExamEndPoint = "bx_block_appointment_management/availabilities/get_availabilities_for_landing_page";
exports.checkRideListEndPoint ="bx_block_tasks/tasks";
exports.landingProfileEndPoint = "account_block/accounts/details";
exports.createCheckRideOutCome= "bx_block_appointment_management/checkride_outcomes";
exports.availabilitiesEndPoint =  "bx_block_appointment_management/availabilities";
// Customizable Area End