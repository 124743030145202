// Customizable Area Start
import React from "react";
import {
    Box,
    Typography,
    styled,
  } from "@mui/material";
import Settings2Controller, { Props } from "../Settings2Controller";
import { email } from "../assets";

export default class SettingsContactUs extends Settings2Controller {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return(
        <CustomSettingsSettingsContactUsContainer>
            <Box className="headerContainer" data-test-id = "test-details-page">
                <Box className="pageHeader">
                   <Typography data-test-id="testSettingsTitle" className={"headerText"} >Contact us</Typography>
                </Box>
            </Box>
            <Box className="contactUsContentContainer">
              <Box className="contactUsInnerContentContainer">
                <Typography className="title">For support, you can reach us at:</Typography>
                <Box className="emailContainer">
                  <img className="emailIcon" src={email} alt="email" />
                  <Typography data-test-id="contact-us-email" className="email">support@checkridepro.app</Typography>
                </Box>
              </Box>
            </Box>
        </CustomSettingsSettingsContactUsContainer>
    )
  }
}

const CustomSettingsSettingsContactUsContainer = styled(Box)({
    "& .headerContainer":{
        display: "flex",
        height: "60px",
        padding: "10px 50px 10px 50px",
        alignItems: "center",
        background: "#ffffff",
        justifyContent:"space-between"
    },
    "& .pageHeader": {
        display:'flex',
        alignItems:'center',
        gap:'20px'
    },
    "& .headerText": {
        fontFamily: "Montserrat",
        fontWeight: 700,
        fontSize: "22px",
        color: "#0074BF",
    },
    "& .contactUsContentContainer": {
        padding:'50px 50px 50px 50px'
    },
    "& .emailContainer":{
        display:'flex',
        alignItems:'center',
    },
    "& .contactUsInnerContentContainer": {
      padding: "50px 50px 50px 50px",
      alignItems: "center",
      background: "#ffffff",
    },
    "& .emailIcon":{
      width:"40px",
      height:"40px"
    },
    "& .title":{
      color: "#0F172A",
      fontSize:"16px",
      fontWeight:"400",
      marginBottom:"15px"
    },
    "& .email":{
      color: "#001C55",
      fontSize:"16px",
      fontWeight:"500",
      fontFamily: "Montserrat",
      marginLeft:"20px"
    }
})
// Customizable Area End
