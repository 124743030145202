Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.httpPutMethod = "PUT";
exports.httpDeleteMethod = "DELETE";
exports.httpPatchMethod = "PATCH";
exports.searchApiContentType = "application/json";
exports.getSearchApiEndPoint = "search/users";
exports.errorTitle = "Error";
exports.getCustomizableProfileEndPoint = "bx_block_profile/profile/current_user_profile";
exports.updateCustomizableProfileEndPoint = "bx_block_profile/profile/update_user_profile";
exports.getCustomizableProfileFieldsEndPoint =
  "bx_block_profile/profile/custom_user_profile_fields";
exports.editProfileEndPoint = "account_block/accounts/details";
exports.getuserProfileEndPoint = "account_block/accounts/details";
exports.getAirportsEndPoint = "bx_block_categories/dpe_airport_settings";
exports.getAllAirportsEndPoint = "/bx_block_automatedprioritisation/service_sub_categories_for_category/Airport";
exports.getAllAircraftsEndPoint = "/bx_block_automatedprioritisation/service_sub_categories_for_category/Aircraft";
exports.postAircraftsEndPoint = "bx_block_categories/aircraft_type_settings";
exports.getAircrafshowList ="bx_block_categories/aircraft_type_settings"
exports.gradeOfCertificateEndPoint = "bx_block_automatedprioritisation/service_sub_categories_for_category/Grade of certificate";
exports.categoryEndPoint = "bx_block_automatedprioritisation/service_sub_categories_for_category/Category";
exports.airPortClassEndPoint = "bx_block_automatedprioritisation/service_sub_categories_for_category/Class";
exports.postTestSettingsEndPoint = "/bx_block_automatedprioritisation/store_grade_of_certificate_category_class_and_create_designations";
exports.getDesignationUrl='bx_block_categories/designation_instructions/designation_instructions_for_dpe';
exports.getTestSpecificationsUrl='/bx_block_automatedprioritisation/get_test_specifications';
exports.dpeOutstandingMessage = "Please confirm that you have no open appointments before proceeding with your account deletion request.";
exports.dpeOutstandingTitle = " Outstanding appointments?";
exports.deliteTitle= "Delete account?";
exports.deleteMessage = "Once deleted, you will no longer be able to log into your account.";
exports.deleteEndPoint = "account_block/accounts/delete_account";
exports.termAndConditionEndPoint = "bx_block_terms_and_conditions/terms_and_conditions"
exports.getDeletedDesignationUrl = "/bx_block_categories/designation_instructions/get_deleted_designation_instructions";
exports.restoreDeletedDesignationUrl = "/bx_block_categories/designation_instructions/restore_designation_instruction";
// Customizable Area End
