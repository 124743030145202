import React from "react";

// Customizable Area Start
import {
  Modal,
  Container,
  Box,
  Button,
  Typography,
  Input,
  styled,
  Paper,
  List,
  ListItem,
  Avatar,
  TextField,
  IconButton,
  Divider
} from "@mui/material";
import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";
import SendIcon from "@mui/icons-material/Send";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import { messageIcon,deleteIcon,muteIcon,mailIcon,reportIcon,ftnIcon,mobileIcon,emptyIcon ,checkMark} from "./assets";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {ChatList, ChatMessage} from "./ChatController"
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

// Customizable Area End

import ChatController, { configJSON, IChat, Props } from "./ChatController";

export default class Chat extends ChatController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderEmptyMessage = () => {
    return (
      <>
      <Box className = "messageEmptyContainer">
          <Typography className="messageText">Messages</Typography>
        </Box>
      <Box className = "emptyMessage">
        <img src= {messageIcon} style= {{width:"120px", height:"120px"}}/>
          <Typography className="emptyMessageText">Looks like you haven't received any messages.</Typography>
        </Box>
      </>
    )
  };

  renderUserHistory = () => {
    return(
      <>
        <Box className="userDetailsContainer">
          <Box className="iconContainer">
            <img src={ftnIcon} />
          </Box>
          <Box>
            <Typography className="userTitle">FTN</Typography>
            <Typography className="userValue">A5467896</Typography>
          </Box>
        </Box>
          <Divider/>
        <Box className="userDetailsContainer2">
          <Box className="iconContainer">
            <img src={mobileIcon} />
          </Box>
          <Box>
            <Typography className="userTitle">Phone number</Typography>
            <Typography className="userValue">555-123-4567</Typography>
          </Box>
        </Box>
          <Divider/>
        <Box className="userDetailsContainer2">
          <Box className="iconContainer">
            <img src={mailIcon} />
          </Box>
          <Box>
            <Typography className="userTitle">Email</Typography>
            <Typography className="userValue">oliviaj@example.com</Typography>
          </Box>
        </Box>
          <Divider/>
        <Box className="userDetailsContainer2">
          <Box className="iconContainer">
            <img src={muteIcon} />
          </Box>
          <Box>
            <Typography className="userTitle">Mute</Typography>
          </Box>
        </Box>
          <Divider/>
        <Box className="userDetailsContainer2">
          <Box className="iconContainer">
            <img src={reportIcon} />
          </Box>
          <Box>
            <Typography className="userTitleDanger">Report Olivia Johnson</Typography>
          </Box>
        </Box>
          <Divider/>
        <Box className="userDetailsContainer2">
          <Box className="iconContainer">
            <img src={deleteIcon} />
          </Box>
          <Box>
            <Typography className="userTitleDanger">Delete chat</Typography>
          </Box>
        </Box>
      </>
    )
  };

  chatUserDetails = () => {
    return (
      <Box flex={1} style={{ backgroundColor: "#ffffff", margin: "20px", borderRadius: "20px" }}>
          <Box className= "topProfile">
            <Box className= "profileContainer">
            <Typography className="sortNameText">JD</Typography>
          </Box>
        </Box>
        <Box style={{ marginLeft: "187px", marginTop: "7px" }}>
          <Typography className="userNameText">Olivia Johnson</Typography>
          <Typography className="userRoleText">DPE</Typography>
        </Box>
        {this.renderUserHistory()}
      </Box>
    )
  };

  chatHistoryMessage = (message:ChatMessage) =>{
    return ( <>
      <Box
        px={2}
        py={1}
        borderRadius={2}
        maxWidth="75%"
        key={message.id}
        bgcolor={message.attributes.type === "sent" ? "#d4edda" : "#ffffff"}
        alignSelf={message.attributes.type === "sent" ? "flex-end" : "flex-start"}
        sx={{
          borderTopLeftRadius: message.attributes.type === "received" ? 0 : 16,
          boxShadow: 1,
          borderTopRightRadius: message.attributes.type === "sent" ? 0 : 16,
        }}
      >
        <Typography className="messageDetailsText">{message.attributes.message}</Typography>
        <Typography className="messageTime" align="right" display="block" mt={1}>
          {this.formatTimestamp(message.attributes.created_at)}{message.attributes.type === "sent" ? message.attributes.is_mark_read && <img src={checkMark} style={{ width: "15px", height: "8px", paddingLeft: "5px" }} /> : null}
        </Typography>
      </Box>
    </>)
  }

  chatMessageDetails = () => {
    return (
      <>
        {/* Chat Messages */}
        <Box flex={1} p={2} overflow="auto" display="flex" flexDirection="column" gap={1} justifyContent={this.state.messages.length === 0 ? "flex-end" : "flex-start"}>
          {this.state.messageHistory.length > 0 ? this.state.messageHistory.map((message: ChatMessage) => (
            <>
              <Typography style={{ textAlign: "center", color: "#94A3B8", fontFamily: "Montserrat", fontWeight: 500, fontSize: "14px", }}>{this.formatMessageDate(message.attributes.created_at)}</Typography>
              {this.chatHistoryMessage(message)}
            </>
          )) :
            <Box style={{ display: "flex", width: "100%", justifyContent: "center" }}>
              <Box style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                <img src={emptyIcon} />
                <Typography style={{ color: "#00AFEF", fontFamily: "Montserrat", fontWeight: 500, fontSize: "12px", lineHeight: "18px" }}>Your chat is now open. Get started!</Typography>
              </Box>
            </Box>
          }
        </Box>

        {/* Chat Input */}
        <Box className="sendButtonContainer">
          <TextField multiline fullWidth placeholder="Type a message..."
            variant="standard"
            InputProps={{ disableUnderline: true }}
            style={{
              backgroundColor: "#F8FAFC",
              padding: "10px"
            }}
            data-test-id="changeTextMessage"
            onChange={this.changeMessageInput}
            value={this.state.messageInput}
          />
          <IconButton color="primary" sx={{ ml: 1 }} data-test-id="sendMessageApiCall" onClick={this.sendMessageApi}>
            <SendIcon />
          </IconButton>
        </Box>
      </>
    )
  }

  chatMessageList = () => {
    return (
      <Box flex={1} display="flex" flexDirection="column">
        {/* Chat Header */}
        <Box display="flex" alignItems="center" p={2} bgcolor="#ffffff" data-test-id="userDetailsTestId" onClick={this.handleUserDetails}>
          {this.state.isUserDetails && <ChevronLeftIcon color="primary" sx={{ fontSize: "35px" }} data-test-id="backToChatTestId" onClick={this.handleBackToChat} />}
          <Avatar src="https://randomuser.me/api/portraits/women/44.jpg" sx={{ mr: 2 }} />
          <Box>
            <Typography className="userNameText">{this.state.chatUserName}</Typography>
            <Typography className="userRoleTextTitle">{this.state.chatUserRole}</Typography>
          </Box>
        </Box>
        {this.state.isUserDetails ? this.chatUserDetails() : this.chatMessageDetails()}
      </Box>
    )
  }

  chatMessage = () => {
    return (
      <>
        <Box display="flex" height="100vh" bgcolor="#f4f6f8">
          {/* Sidebar */}
          <Paper sx={{ width: 360, height: "100%", overflowY: "auto", }} elevation={0}>
            <Typography data-test-id="messagesTestId" className="chatMessageText">Messages</Typography>
            <List>
              {this.state.chatUserList.map((user: ChatList) => (
                <>
                  <ListItem data-test-id="chatDetailsTestId" onClick={() => this.handleChatDetaile(Number(user.id), user.attributes.name, user.attributes.chat_user_role)} style={{ padding: "16px", margin: "0px" }} key={user.id}>
                    <Box style={{ width: "100%", gap: 10, display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                      <Box className="profileNameContainer">
                        <Typography className="profileNameLogo"> {this.getFirstLetter(user.attributes.name)} </Typography>
                      </Box>
                      <Box style={{ width: "100%" }}>
                        <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                          <Typography className="userNameText">{user.attributes.name}  . <span className="userRoleTextTitle">{user.attributes.chat_user_role}</span></Typography>
                          <Typography className="timeText">{user.attributes.unread_time}</Typography>
                        </Box>
                        <Box style={{ display: "flex", justifyContent: "space-between" }}>
                          <Typography className="currentMessage">{this.handleLimitCharacter(user.attributes.messages?.attributes.message)}</Typography>
                          <Typography className="timeText">{user.attributes.unread_count}</Typography>
                        </Box>
                      </Box>
                    </Box>
                  </ListItem>
                  <Divider />
                  <List>
                  </List>
                </>
              ))}
            </List>
          </Paper>
          {/* Chat Window */}
          {this.chatMessageList()}
        </Box>
      </>
    )
  };

  mobileChatMessageDetails = () => {
    return (
      <>
        <Box flex={1} display="flex" flexDirection="column" >
          {/* Chat Header */}
          <Box display="flex" alignItems="center" p={2} bgcolor="#ffffff"  >
            <ChevronLeftIcon className="mobileUserTitle" data-test-id="backToChatTestId" onClick={this.handleMobileBackToChat} />
            <Box display="flex" alignItems="center" data-test-id="chatuserDetailsTestId" onClick={this.handleChatUserDetails}>
              <Avatar src="https://randomuser.me/api/portraits/women/44.jpg" sx={{ mr: 2 }} />
              <Box>
                <Typography className="userNameText">Olivia Johnson</Typography>
                <Typography className="userRoleTextTitle">APPLICANT</Typography>
              </Box>
            </Box>
          </Box>
          {/* Chat Messages */}
          <Box flex={1} p={2} overflow="auto" display="flex" flexDirection="column" gap={1} justifyContent={this.state.messages.length === 0 ? "flex-end" : "flex-start"}>
            {this.state.messageHistory.map((message: ChatMessage) => (
              <Box
                key={message.id}
                alignSelf={message.attributes.type === "sent" ? "flex-end" : "flex-start"}
                bgcolor={message.attributes.type === "sent" ? "#d4edda" : "#ffffff"}
                px={2}
                py={1}
                borderRadius={2}
                maxWidth="75%"
                sx={{
                  boxShadow: 1,
                  borderTopLeftRadius: message.attributes.type === "received" ? 0 : 16,
                  borderTopRightRadius: message.attributes.type === "sent" ? 0 : 16,
                }}
              >
                <Typography className="mobileMessageText">{message.attributes.message}</Typography>
                <Typography className="mobileMessageTime" align="right" display="block" mt={1}>
                  {this.formatTimestamp(message.attributes.created_at)} {message.attributes.is_mark_read && <img src={checkMark} style={{ width: "15px", height: "8px" }} />}
                </Typography>
              </Box>
            ))}
          </Box>
          {/* Chat Input */}
          <Box className="mobileButtonContainer" >
            <TextField onChange={this.changeMessageInput} value={this.state.messageInput} multiline fullWidth placeholder="Type a message..." variant="standard" InputProps={{ disableUnderline: true }} style={{ backgroundColor: "#F8FAFC" }} />
            <IconButton color="primary" sx={{ ml: 1 }} onClick={this.sendMessageApi}>
              <SendIcon />
            </IconButton>
          </Box>
        </Box>
      </>
    )
  }

  mobileChatUserListScreen = () => {
    return (
      <Paper className="mobilePaperContainer" elevation={0}>
        <Typography data-test-id="mobilemessagesTestId" className="mobileChatMessageText">Messages</Typography>
        <List>
          {this.state.chatUserList.map((user: ChatList) => (
            <>
              <ListItem className="mobileListContainer" key={user.id} data-test-id="mobileChatDetailTestId" onClick={() => this.chatDetailsScreen(Number(user.id))}>
                <Box className="mobileUserListContainer" >
                  <Avatar src="https://randomuser.me/api/portraits/women/44.jpg" />
                  <Box style={{ width: "100%" }}>
                    <Box className="mobileUserName">
                      <Typography className="userNameText">{user.attributes.name}  . <span className="userRoleTextTitle">{user.attributes.chat_user_role}</span></Typography>
                      <Typography className="timeText">{user.attributes.unread_time}</Typography>
                    </Box>
                    <Typography className="currentMessage">{this.handleLimitCharacter(user.attributes.messages?.attributes.message)}</Typography>
                  </Box>
                </Box>
              </ListItem>
              <Divider />
              <List>
              </List>
            </>
          ))}
        </List>
      </Paper>
    )
  }

  mobileChatUserDetails = () => {
    return (
        <Box style={{ backgroundColor: "#ffffff", height:"100%",overflow: "auto"}}>
          <Box className= "userContactHeader">
             <ChevronLeftIcon  className= "mobileUserTitle" data-test-id="backToChatTestId" onClick={this.backChatUserDetails} />
          <Typography className="userNameText">Contact info</Typography>
        </Box>
          <Box className= "profileTopheader">
            <Box className= "profileHeaderContainer">
            <Typography className="sortNameText">JD</Typography>
          </Box>
          <Typography className="userNameText">Olivia Johnson</Typography>
          <Typography className="userRoleText">DPE</Typography>
        </Box>
        {this.renderUserHistory()}
      </Box>
    )
  };

  screenSize = () => {
    const { screenWidth,isChatUserList,isChatMessage,isChatUserDetails} = this.state;
    return screenWidth > 900 ? (
      this.chatMessage()
    ) : (
      <>
        {isChatUserList && this.mobileChatUserListScreen()}
        {isChatMessage && this.mobileChatMessageDetails()}
        {isChatUserDetails && this.mobileChatUserDetails()}
      </>
    );
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <ChatContainer>
        {this.state.chatUserList.length == 0 ? (
          this.renderEmptyMessage()
        ) : this.screenSize()}
      </ChatContainer>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyles = {
  createButtonView: {
    display: "flex",
    justifyContent: "center",
    padding: "10px 0px",
    marginBottom: 20,
  },
  roomListContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  listItemContainer: {
    width: 300,
    border: "2px solid #ccc",
    borderRadius: 10,
    padding: 15,
    marginBottom: 20,
  },
  modalContainer: {
    display: "flex",
    flexDirection: "row",
    position: "absolute" as "absolute",
    top: "20%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 300,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  },
  modalButtonView: {
    marginLeft: 10,
  },
  mutedChat: {
    backgroundColor: "lightgray",
  },
};

const ChatContainer = styled(Box)({
  "& .messageEmptyContainer": {
    display: "flex",
    height: "60px",
    padding: "10px 50px 10px 50px",
    alignItems: "center",
    background: "#ffffff",
    justifyContent: "space-between"
  },
  "& .messageText": {
    fontFamily: "Montserrat",
    fontWeight: 700,
    fontSize: "22px",
    lineHeight: "28px",
    color: "#0074BF",
  },
  "& .chatMessageText": {
    fontFamily: "Montserrat",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "28px",
    color: "#0074BF",
    marginLeft: "14px",
    padding: "16px"
  },
  "& .emptyMessage": {
    display: "flex",
    alignItems: "center",
    background: "#ffffff",
    justifyContent: "center",
    flexDirection: "column",
    height: "calc(100vh - 140px)",
    margin: "30px",
    borderRadius: "20px",
    gap: 8
  },
  "& .emptyMessageText": {
    fontFamily: "Montserrat",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "19.5px",
    color: "#94A3B8",
    marginTop: "15px"
  },
  "& .userNameText": {
    fontFamily: "Montserrat",
    fontWeight: 700,
    fontSize: "14px",
    color: "#0074BF"
  },
  "& .userRoleText": {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "12px",
    color: "#0074BF"
  },
  "& .userRoleTextTitle": {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "9px",
    color: "#0074BF"
  },
  "& .timeText": {
    fontFamily: "Montserrat",
    fontWeight: 400,
    fontSize: "14px",
    color: "#64748B"
  },
  "& .currentMessage": {
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "20px",
    color: "#0F172A"

  },
  "& .iconContainer": {
    width: "36px",
    height: "36px",
    borderRadius: "50%",
    backgroundColor: "#E0F2F8",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  "& .userDetailsContainer": {
    display: "flex",
    alignItems: "center",
    marginTop: "25px",
    gap: 20,
    padding: "12px 35px"
  },
  "& .userDetailsContainer2": {
    display: "flex",
    alignItems: "center",
    gap: 20,
    padding: "12px 35px"
  },
  "& .userTitle": {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "24px",
    color: "#94A3B8"
  },
  "& .userValue": {
    fontFamily: "Montserrat",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "22px",
    color: "#001C55"

  },
  "& .userTitleDanger": {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "24px",
    color: "#F87171"

  },
  "& .sortNameText": {
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: "50px",
    lineHeight: "48px",
    textAlign: "center",
    color: "#0074BF"
  },
  "& .profileContainer": {
    width: "120px",
    height: "120px",
    position: "absolute",
    borderRadius: "100%",
    border: "2px solid #E0F2F8",
    backgroundColor: "#ffffff",
    top: "55px",
    left: "50px",
    display: 'flex',
    justifyContent: "center",
    alignItems: "center"
  },
  "& .topProfile": {
    backgroundColor: "#E0F2F8",
    borderBottomRightRadius: "50px",
    borderTopLeftRadius: "20px",
    borderTopRightRadius: "20px",
    height: "110px",
    position: "relative"
  },
  "& .messageDetailsText": {
    fontSize: "16px",
    lineHeight: "24px",
    fontFamily: "Montserrat",
    fontWeight: 400,

  },
  "& .messageTime": {
    fontSize: "12px",
    lineHeight: "14.63px",
    fontFamily: "Montserrat",
    fontWeight: 400,
    color: "#94A3B8"
  },
  "& .sendButtonContainer": {
    margin: "0px 25px",
    boxSizing: "border-box",
    padding: "9px 16px",
    display: "flex",
    alignItems: "center",
    background: "#ffffff",
    height: "auto",
    borderRadius: "20px"
  },
  "& .profileNameContainer": {
    display: "flex",
    height: "40px",
    width: "46px",
    borderRadius: "50%",
    border: "4px solid #E0F2F8",
    justifyContent: "center",
    alignItems: "center",
    background: "#ffffff",
  },
  "& .profileNameLogo": {
    fontSize: "18px",
    fontWeight: 600,
    color: "#0074BF",
    textAlign: "center",
    height: "fit-content",
    fontFamily: "Montserrat",
  },
  //mobile 
  "& .mobileChatMessageText": {
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "28px",
    color: "#0074BF",
    fontFamily: "Montserrat",
    marginLeft: "14px",
    padding: "16px",
    textAlign: "center"
  },
  "& .mobilePaperContainer": {
    height: "100%",
    overflowY: "auto",
  },
  "& .mobileListContainer": {
    padding: "16px",
    margin: "0px",
    width: "100%"
  },
  "& .mobileUserListContainer": {
    gap: 10,
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  "& .mobileUserName": {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center"
  },
  "& .mobileButtonContainer": {
    margin: "0px 25px",
    boxSizing: "border-box",
    padding: "13px 16px",
    display: "flex",
    alignItems: "center",
    background: "#ffffff",
    height: "auto",
    borderRadius: "20px"
  },
  "& .mobileUserTitle": {
    color: "#0074BF",
    fontSize: "35px",
    margin: "0px 10px",
    "@media screen and (min-width: 414px) and (max-width: 700px)": {
      margin: "0px 30px",
    }
  },
  "& .mobileMessageText": {
    fontFamily: "Montserrat",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
  },
  "& .mobileMessageTime": {
    fontFamily: "Montserrat",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "14.63px",
    color: "#94A3B8"
  },
  "& .profileTopheader": {
    backgroundColor: "#E0F2F8",
    borderBottomRightRadius: "50px",
    borderBottomLeftRadius: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: "20px 0px"
  },
  "& .profileHeaderContainer": {
    border: "2px solid #E0F2F8",
    backgroundColor: "#ffffff",
    width: "120px",
    height: "120px",
    borderRadius: "100%",
    display: 'flex',
    justifyContent: "center",
    alignItems: "center"
  },
  "& .userContactHeader": {
    display: "flex",
    alignItems: "center",
    padding: "20px 10px",
    bgcolor: "#ffffff"
  }
})
// Customizable Area End
